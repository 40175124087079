import { Component, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  activeLink: any;
  @Input() isNavImageBlack = false;
  constructor (public router: Router) {}

  ngOnInit (): void {
    this.router.events.subscribe((routerEvent) => {
      if(routerEvent instanceof NavigationEnd) {
          // Get your url
          this.activeLink = routerEvent.url;
      }
  });
  }
  

  // <!-- ----------------------------------------------------------------------------------------------->
  navItemDropDownMouseEnter (id: any) {
    document.getElementById(id)?.click()
  }

  navItemDropDownMouseLeave () {
    // document.getElementById('mouse-leave-tab')?.click()
    // if(type == 'tech-consul-dropdown'){
    //    //@ts-ignore
    //   document.getElementById('home').classList.remove('active')
    //    //@ts-ignore
    //   document.getElementById('home').classList.remove('show')
    // }
  }

  leavingNavbar () {
    document.getElementById('mouse-leave-tab')?.click()
  }
  // <!-- ----------------------------------------------------------------------------------------------->
  getSelectedRoute(activeLink: string){
    let url = window.location.href.split('/');
    return url[url.length - 1].includes(activeLink) ? 'nav-item active' : 'nav-item';
  }

  getNavParentClass(linksArray: Array<string>){
    let url = window.location.href.split('/');
    return linksArray.includes(url[url.length - 1]) ? 'nav-parent-active' : ''
  }

  getNavImageOrange(linksArray: Array<string>){
    let url = window.location.href.split('/');
    return linksArray.includes(url[url.length - 1]) ? true : false
  }

  getImageClass(linksArray: Array<string>){
    let url = window.location.href.split('/');
    return linksArray.includes(url[url.length - 1]) ? 'arrow-active' : 'downward-triangle1'
  }
}
