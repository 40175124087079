import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-domain-solutions',
  templateUrl: './domain-solutions.component.html',
  styleUrls: ['./domain-solutions.component.scss']
})
export class DomainSolutionsComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(!this.route.snapshot.fragment){
      this.scrollToTop()
    }else {
      this.scrollToElement()
    }
  }

  scrollToTop(){
    const element = document.querySelector('.domain-solution-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }

  scrollToElement(){
    const element = document.querySelector(`#${this.route.snapshot.fragment}`)
      if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      }
  }
  onLinkShipping(){
    const element = document.querySelector('.content-wrapper-1')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  onLinkEducations(){
    const element = document.querySelector('.content-wrapper-2')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  onLinkCMS(){
    const element = document.querySelector('.content-wrapper-3')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  onLinkCommerce(){
    const element = document.querySelector('.content-wrapper-4')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  onLinkAI(){
    const element = document.querySelector('.content-wrapper-5')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
