import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  current_year = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void { this.scrollToTop()
  }
  scrollToTop(){
    const element = document.querySelector('.main-privacy-policy');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
