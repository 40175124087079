import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traveho',
  templateUrl: './traveho.component.html',
  styleUrls: ['./traveho.component.scss']
})
export class TravehoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
