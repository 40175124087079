import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  current_year = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
    this.scrollToTop();
  }
  scrollToTop(){
    const element = document.querySelector('.main-disclaimer');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
