<div class="tech-stack-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>
    <div class="hero-wrapper">
        <!-- <section class="navbar_wrapper" id="navbar_wrapper_section">
            <nav class="navbar navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <img id="ency_logo" alt="ency logo" src="assets/ency-logo-no-bg.webp" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link active f-18 fw-500" aria-current="page" href="#">About us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Resourcing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Domain</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Engagement model</a>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <button class="btn btn-success contact_us_button" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </nav>
        </section> -->
        <app-navbar [isNavImageBlack]="true" class="desktop-nav"></app-navbar>
        <img id="scroll-down-anim" src="assets/tech-stack/scroll-down-anim.gif" />
    </div>
    <!-- <div class="heading-wrapper">
        <div class="main-heading-wrapper">
            <div class="inner-wrapper">
                <p id="main-head-1">Data science and </p>
                <p id="main-head-2">Machine Learning</p>
            </div>
            <img id="heading-animation" src="assets/tech-stack/heading-anim.gif" />
        </div>
     
        <div class="sub-heading-wrapper">
            <p id="sub-head">Our technical experts are dedicated to staying up-to-date with the latest trends and
                advancements in their respective fields. We continuously invest in training and development to ensure
                that our team members are equipped with the
                latest tools and technologies.</p>
        </div>
    </div> -->
    <div class="tech-stack-image-wrapper">
        <img  class="tech-stack"src="assets/tech-stack.png"/>
    </div>
    <!-- <div class="client-side-wrapper">
        <img id="heading-right-anim-1" src="assets/tech-stack/heading-right-animation.gif" />
        <img id="heading-right-anim-2" src="assets/tech-stack/heading-right-animation.gif" />
        <div class="heading-wrapper">
            <img id="heading-img" src="assets/tech-stack/web-heading-animation.gif" />
            <p id="heading-text">Client Side</p>
        </div>
        <div class="upper-content-wrapper">
            <img id="anim1" src="assets/tech-stack/client-side-anim-1.gif" />
            <p id="text1">Web</p>
            <img id="img1" src="assets/tech-stack/client-side-img-1.png" />
        </div>
        <br /><br /><br />
        <div class="lower-content-wrapper">
            <img id="img2" src="assets/tech-stack/client-side-img-2.png" />
            <p id="text2">Mobile</p>
            <img id="anim2" src="assets/tech-stack/client-side-anim-2.gif" />
        </div>

    </div>
    <div class="database-wrapper">
        <div class="heading-wrapper">
            <img id="heading-animation" src="assets/tech-stack/heading-animation.gif" />
            <p id="heading-text">Databases</p>
            <img id="heading-animation" src="assets/tech-stack/heading-animation.gif" />
        </div>
        <img id="database-img" src="assets/tech-stack/database-img.png" />
    </div> -->
    <div class="data-science-wrapper">
        <img id="deco-img-1" src="assets/tech-stack/deco-img-1.png" />
        <img id="deco-img-2" src="assets/tech-stack/deco-img-2.png" />
        <!-- <img id="text-img" src="assets/tech-stack/data-sc-text.png" /> -->
        <div class="heading-wrapper">
            <div class="main-heading-wrapper">
                <div class="inner-wrapper">
                    <p id="main-head-1">Data science and </p>
                    <p id="main-head-2">Machine Learning</p>
                </div>
                <img id="heading-animation" src="assets/tech-stack/heading-anim.gif" />
            </div>
         
            <div class="sub-heading-wrapper">
                <p id="sub-head">Our technical experts are dedicated to staying up-to-date with the latest trends and
                    advancements in their respective fields. We continuously invest in training and development to ensure
                    that our team members are equipped with the
                    latest tools and technologies.</p>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="left-wrapper">
                <img id="anim1" src="assets/tech-stack/data-sc-anim-1.gif" />
                <img id="cylinder" src="assets/tech-stack/data-sc-cylinder-img.png" />
            </div>
            <div class="right-wrapper">
                <img id="anim2" src="assets/tech-stack/data-sc-anim-2.gif" />
                <img id="img" src="assets/tech-stack/data-sc-main-img.png" />
            </div>
        </div>
    </div>



</div>