<div class="contact-us-main-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>    
    <div class="contact-us-banner-wrapper">
        <app-navbar [isNavImageBlack]="true" class="desktop-nav"></app-navbar>
        <div class="contact-us-left-content">
            <!-- <div class="contact-us-text-container">
               
                <div class="contact-us-text">Contact us</div>
            </div> -->
            <img class="contact-us-rectangle" src="assets/contact-us/contact-us-image.png">
            <div class="contact-us-paragraph">
                <div class="content-line-one">Connect with us...</div>
                <div class="content-line-two">and let’s create something</div>
                <div class="amazing-text">amazing!</div>
            </div>

        </div>
        <img class="animation-inside-contact-us" src="assets/contact-us/animation-inside-contact-us-banner.gif" />
    </div>
    <div class="contact-us-our-offices-wrapper">
        <div class="overview-title-wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
            </div><span id="overview-text">Our Offices</span>
        </div>
        <div class="our-offices-wrapper">
            <div class="vertical-line-ellipse-outside-wrapper">
                <div class="vertical-line-ellipse-inside-wrapper">
                    <img class="vertical-line" src="assets/vertical-line.png">
                    <div class="ellipse-inside-ellipse-animation-wrapper">
                        <img class="ellipse-inside-ellipse" src="assets/ellipse-inside-ellipse.png">
                        <img class="ellipse-inside-ellipse-animation" src="assets/ellipse-inside-ellipse-animation.gif">
                    </div>

                    <img class="vertical-line" src="assets/vertical-line.png">
                </div>
            </div>
            <div class="Canada-office-wrapper">
                <img class="office-location-image" src="assets/contact-us/canada-location-image.png" />
                <div class="address-wrapper">
                    <img class="location-gif" src="assets/contact-us/location.gif" />
                    <div class="canada-address-wrapper">
                        <div class="india-address">
                            ency Corporation Inc,
                        </div>
                        <div class="india-address">
                            25 Sheppard Ave W #300
                        </div>
                        <div class="india-address">
                            North York, ON  M2N 6S6
                        </div>
                    </div>
                </div>
                <div class="contact-wrapper">
                    <img class="contact-us-gif" src="assets/contact-us/call.gif" />
                    <div class="contact-number">+1(647) 391-0700
                    </div>
                </div>
                <div class="mail-wrapper">
                    <img class="mail" src="assets/contact-us/mail.gif" />
                    <div class="company-mail"><a class="mail" href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a></div>
                </div>
            </div>
            <div class="india-office-wrapper">
                <img class="office-location-image" src="assets/contact-us/india-location-image.png" />
                <div class="address-wrapper">
                    <img class="location-gif" src="assets/contact-us/location.gif" />
                   
                   <div class="india-address"><div class="india-address1">
                    ency Consulting LLP, 
                    </div>
                    <div class="india-address">
                    75, 1st Main Road, Vasugi Nagar, 
                    </div>
                    <div class="india-address2">
                        Chennai , Tamil Nadu 600118 
                    </div>
                </div> 
                  
                </div>
               
                <div class="contact-wrapper">
                    <img class="contact-us-gif" src="assets/contact-us/call.gif" />
                    <div class="contact-number">+91 (892) 523-7226</div>  
                </div>
                <div class="mail-wrapper">
                    <img class="mail" src="assets/contact-us/mail.gif" />
                    <div class="company-mail"><a class="mail" href="mailto:info@encycg.com">info@encycg.com</a></div>
                </div>
            </div>
         
            <div class="vertical-line-ellipse-outside-wrapper">
                <div class="vertical-line-ellipse-inside-wrapper">
                    <img class="vertical-line" src="assets/vertical-line.png">
                    <div class="ellipse-inside-ellipse-animation-wrapper">
                        <img class="ellipse-inside-ellipse" src="assets/ellipse-inside-ellipse.png">
                        <img class="ellipse-inside-ellipse-animation" src="assets/ellipse-inside-ellipse-animation.gif">
                    </div>

                    <img class="vertical-line" src="assets/vertical-line.png">
                </div>
            </div>
        </div>
    </div>
    <div class="we-thrilled-to-hear-wrapper">
        <div class="we-thrilled-to-hear-heading-wrapper">
            <div class="we-thrilled-to-hear-heading">We're thrilled to hear from you!</div>
            <img class="dotted-animation" src="assets/dotted_circle_animation.gif" />
        </div>
        <div class="we-thrilled-to-hear-paragraph">Whether you have a question about our services, need assistance with
            a project, or just want to <span class="span-element-paragraph">say hello</span>, we're always here to help.
            Please fill out the contact form below, and our team will get back to you as soon as possible.
            Alternatively, you can reach us directly by <span class="span-element-paragraph">phone</span> or <span
                class="span-element-paragraph">email</span>, which you can find above
        </div>
    </div>
    <div id="form-wrapper" class="mail-form-map-wrapper">
        <div class="mail-form-wrapper">
            <img class="big-mail-image" src="assets/contact-us/big-mail-image.gif" />
            <div class="form-container">
                <img class="form-heading" src="assets/contact-us/form-heading.png" />
                <form [formGroup]="contactUs" (ngSubmit)="onSubmit()">
                    <div class="form-label-input-wrapper">
                        <div class="form-group">
                            <label class="label-text" for="yourname">Your Name<span class="astrik">*</span></label><br>
                            <input type="text" formControlName="yourname" class="form-control"  maxlength="100">
                        </div>
                        <pre></pre>

                        <div class="company-and-phone-number-wrapper">
                            <div class="form-group company-name">
                                <label for="yourcompanyname">Your Company Name</label><br>
                                <input type="text" formControlName="yourcompanyname" class="form-control" maxlength="100">
                            </div>
                            <div class="form-group phone-number">
                                <label for="phonenumber">Phone Number</label><br>
                                <input type="number" maxlength="15" formControlName="phonenumber" class="form-control" onkeydown="return ( event.ctrlKey || event.altKey 
                                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                                || (95<event.keyCode && event.keyCode<106)
                                || (event.keyCode==8) || (event.keyCode==9) 
                                || (event.keyCode>34 && event.keyCode<40) 
                                || (event.keyCode==46) )"  >
                                <!-- <div *ngIf="contactUs.controls['phonenumber'].hasError('range')">error</div> -->
                            </div>
                        </div>
                        <pre></pre>
                        <div class="form-group">
                            <label for="email">Email<span class="astrik">*</span></label><br>
                            <input type="text" formControlName="email" class="form-control" maxlength="100">
                        </div>
                        <pre></pre>
                        <div class="form-group">
                            <label for="subject">Subject<span class="astrik">*</span></label><br>
                            <input type="text" formControlName="subject" class="form-control" maxlength="100">
                        </div>
                        <pre></pre>
                        <div class="form-group">
                            <label for="message">Message<span class="astrik">*</span></label><br>
                            <!-- <input type="text" formControlName="message" class="form-control" maxlength="100"> -->
                            <textarea type="text"  formControlName="message" class="form-control" maxlength="255" cols="40" rows="5" Style="color:white;resize: none;"></textarea>
                            <!-- <textarea name="Text1" cols="40" rows="5"></textarea> -->
                        </div>
                        <pre class="pree_last"></pre>
                    </div>

                    <button *ngIf="loading ==  false" class="btn btn-primary submit-button" type="submit" [disabled]="!contactUs.valid"><span>SEND</span> <img src="../../../assets/contact-us/vector_next.png"/></button>
                  
                    <div *ngIf="loading ==  true" class="loader-wrapper">
                        <div  class="loader"></div>
                    </div>
                </form>
            </div>
            <iframe class="map-of-canada"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.5754016584515!2d-79.41467362420805!3d43.76091327109721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2de4619268e1%3A0x41c79b6320971e73!2sency%20Consulting%20Inc!5e0!3m2!1sen!2sin!4v1683286563724!5m2!1sen!2sin"
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

    </div>

  
     <!-- Footer -->

<a id="whatsapp_icon_wrapper" href="https://wa.me/16473910700" target="_blank">
    <div class="whatsapp-icon-text-wrapper">
        Get in touch with us by WhatsApp
    </div>
    <img id="whatsapp_icon" alt="icon_whatsapp" src="assets/whatsapp_icon.webp" />
</a>
<!-- Footer -->
</div>