
<div class="domain-solution-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true"  class="black-links mobile-nav"></app-navbar>
    <div class="hero-wrapper">
        <!-- <section class="navbar_wrapper" id="navbar_wrapper_section">
            <nav class="navbar navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <img id="ency_logo" alt="ency logo" src="assets/ency-logo-no-bg.webp" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link active f-18 fw-500" aria-current="page" href="#">About us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Resourcing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Domain</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Engagement model</a>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <button class="btn btn-success contact_us_button" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </nav>
        </section> -->
        <app-navbar [isNavImageBlack]="true"  class="desktop-nav"></app-navbar>
        <p id="heading1">Solutions</p>
        <p id="heading2">Maximizing Your Business Potential with Our Solutions</p>
        <p id="heading3">Business Solutions for Growth and Efficiency Empower your business with ency's AI and ML
            solutions.
            Customized automation and data analysis for better-informed decisions, staying ahead with cutting-edge
            techniques and algorithms.</p>
    </div>
    <div class="heading-wrapper">
        <div class="sub-heading-wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
            </div>
            <p id="sub-heading">Business Solutions </p>
        </div>
        <!-- <p id="main-heading"> For Growth and Efficiency</p> -->
        <p id="content">Our Solutions and Services powers digital transformation through innovative and sustainable
            business software solutions.</p>
        <br />
        <div class="labels-wrapper">
            <div class="label" (click)="onLinkShipping()">Shipping and Logistics</div>
            <div class="label" (click)="onLinkEducations()">Education and Learning</div>
            <div class="label" (click)="onLinkCMS()">CMS</div>
            <div class="label" (click)="onLinkCommerce()" >E- Commerce</div>
            <div class="label" (click)="onLinkAI()">AI/ML</div>
        </div>

    </div>

    <div class="content-wrapper-1" >
        <div class="left-content-wrapper">
            <div class="content-heading-wrapper">
                <div>
                    <div class="inner-heading-wrapper" >
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line" id="shipping"></div>
                        </div>
                        <p id="head-text-1">Shipping</p>
                    </div>
                    <p id="head-text-2">and logistics</p>
                </div>
                <img id="heading-anim" src="assets/domain-solutions/heading-anim.gif" />
            </div>
            <br />
            <p id="content-text">Our integrated rate management system gives you total visibility into your global
                supply chain historical and current rates for all modes of transport across all contracts managed
                worldwide

            </p>
            <br />
            <img src="assets/domain-solutions/content-anim-1.gif" id="content-anim-1" />
        </div>
        <img id="img" src="assets/domain-solutions/content-img-1.png" />
    </div>


    <div class="content-wrapper-2">
        <img id="img" src="assets/domain-solutions/content-img-2.png" />
        <div class="left-content-wrapper">
            <div class="content-heading-wrapper">
                <div>
                    <div class="inner-heading-wrapper" >
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line"  id="education"></div>
                        </div>
                        <p id="head-text-1">Education </p>
                    </div>
                    <p id="head-text-2">and learning</p>
                </div>
                <img id="heading-anim" src="assets/domain-solutions/heading-anim.gif" />
            </div>
            <br />
            <p id="content-text">Distance learning and remote work have never been easier for administrators, teachers,
                professors, and students. Let us help you deploy solutions that meet your unique needs and keep you
                connected, productive, and successful.

            </p>
            <br />
            <img src="assets/domain-solutions/content-anim-2.gif" id="content-anim-1" />
        </div>

    </div>

    <div class="content-wrapper-3" >
        <div class="left-content-wrapper">
            <div class="content-heading-wrapper">
                <div>
                    <div class="inner-heading-wrapper"  >
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line" id="cms"></div>
                        </div>
                        <p id="head-text-1">CMS</p>
                    </div>
                    <!-- <p id="head-text-2">and logistics</p> -->
                </div>
                <img id="heading-anim" src="assets/domain-solutions/heading-anim.gif" />
            </div>
            <br />
            <p id="content-text">We empower your business with the latest Content Management Systems. Our solutions
                facilitate easy creation and publication of website content, no matter the size or geographic dispersion
                of your organization.
            </p>
            <br />
            <img src="assets/domain-solutions/content-anim-3.gif" id="content-anim-3" />
        </div>
        <img id="img" src="assets/domain-solutions/content-img-3.png" />
    </div>


    <div class="content-wrapper-4">
        <img id="img" src="assets/domain-solutions/content-img-4.png" />
        <div class="left-content-wrapper">
            <div class="content-heading-wrapper">
                <div>
                    <div class="inner-heading-wrapper">
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line" id="ecommerce"></div>
                        </div>
                        <p id="head-text-1">E-Commerce</p>
                    </div>
                    <!-- <p id="head-text-2">and logistics</p> -->
                </div>
                <img id="heading-anim" src="assets/domain-solutions/heading-anim.gif" />
            </div>
            <br />
            <p id="content-text">Our e-commerce solution extends to provide a professional e-commerce online store
                targeting local and international customers with upselling and cross-selling with secure multiple online
                payment gateways
            </p>
            <br />
            <img src="assets/domain-solutions/content-anim-4.gif" id="content-anim-3" />
        </div>

    </div>




    <div class="content-wrapper-5">
        <div class="left-content-wrapper">
            <div class="content-heading-wrapper">
                <div>
                    <div class="inner-heading-wrapper">
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle" id="ai"></div>
                            </div>
                            <div class="line"></div>
                        </div>
                        <p id="head-text-1">AI/ML</p>
                    </div>
                    <!-- <p id="head-text-2">and logistics</p> -->
                </div>
                <img id="heading-anim" src="assets/domain-solutions/heading-anim.gif" />
            </div>
            <br />
            <p id="content-text">At ency, we offer state-of-the-art AI and ML solutions that can help transform your
                business. Our team of experienced AI and ML experts will work with you to understand your specific
                business needs and design customized solutions that are tailored to your unique requirements.
            </p>
            <br />
            <img src="assets/domain-solutions/content-anim-5.gif" id="content-anim-2" />
        </div>
        <img id="img" src="assets/domain-solutions/content-img-5.png" />
    </div>

    <br />
    <br />

</div>