<div class="ezdesk_main_container" id="container">
    <app-navbar class="black-links mobile-nav"></app-navbar>
    <div class="ezdesk_hero_wrapper">
        <app-navbar class=" black-links desktop-nav"></app-navbar>
        <div class="home-banner-area">
            <div class="hero_line1">Customer Support</div>
            <div class="hero_line2">Management</div>
            <div class="hero_para">ezdesk solution is a comprehensive software platform that enables businesses to
                provide exceptional
                customer support and effectively manage customer inquiries, issues, and requests. This tool is essential
                for businesses of all sizes to enhance customer satisfaction, streamline internal processes, and
                maintain a positive brand image</div>
        </div>
    </div>
    <div class="benefits_main_wrapper">
        <img class="white_star" src="assets/ezdesk/white_star.png"/>
        <img class="rounded_circle1" src="assets/ezdesk/rounded_circle2.png"/>
        <img class="rounded_circle2" src="assets/ezdesk/rounded_circle2.png"/>
        <div class="benefits_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="benefits_key">Benefits</div>
            </div>


        </div>
        <div class="benefits_content_wrapper">
            <img class="benefits_content_image" src="assets/ezdesk/middle_circle.png" />
            <!-- <div class="feature1_wrapper">
                <div class="heading">Enhanced Customer Satisfaction</div>
                <div class="content">Prompt responses, personalized support, and efficient issue resolution lead to happier customers</div>
            </div> -->
            <div class="row1">
                <div class="row1_inner_wrapper">
                    <div class="feature1_wrapper features">
                        <div class="feature1_inner_wrapper">
                            <div class="heading">Enhanced Customer Satisfaction</div>
                            <div class="content">Prompt responses, personalized support, and efficient issue resolution
                                lead to happier customers</div>
                        </div>
                    </div>
                    <div class="feature2_wrapper features">
                        <div class="feature2_inner_wrapper">
                            <div class="heading">Consistency</div>
                            <div class="content">All customer interactions are logged and tracked, providing a consistent and unified approach to customer support</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row2 ">
                <div class="row2_inner_wrapper">
                    <div class="feature3_wrapper features">
                        <div class="feature1_inner_wrapper">
                            <div class="heading">Efficient Issue Resolution</div>
                            <div class="content">The streamlined ticket management process ensures that issues are addressed promptly and systematically</div>
                        </div>
                    </div>
                    <div class="feature4_wrapper features">
                        <div class="feature2_inner_wrapper">
                            <div class="heading">Improved Collaboration</div>
                            <div class="content">Agents can collaborate effectively, sharing insights and knowledge to solve problems faster</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row3">
                <div class="row3_inner_wrapper">
                    <div class="feature5_wrapper features feature_5" >
                        <div class="feature1_inner_wrapper">
                            <div class="heading">Time and Resource Savings</div>
                            <div class="content">Automation and self-service options reduce the workload on support teams, allowing them to focus on more complex tasks</div>
                        </div>
                    </div>
                    <div class="feature6_wrapper features">
                        <div class="feature2_inner_wrapper">
                            <div class="heading">Scalability</div>
                            <div class="content"> As your business grows, the help desk can scale to accommodate increased support demands</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row4">
                <div class="row4_inner_wrapper">
                    <div class="feature7_wrapper features">
                        <div class="feature1_inner_wrapper">
                            <div class="heading">Data-Driven Decisions</div>
                            <div class="content">Analyzing metrics helps you identify trends, bottlenecks, and areas for improvement in your support processes</div>
                        </div>
                    </div>
                    <div class="feature8_wrapper features">
                        <div class="feature2_inner_wrapper">
                            <div class="heading">Brand Image</div>
                            <div class="content">Efficient and effective support contributes to a positive brand reputation and customer loyalty</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="ai_analytics_features_wrapper">
        <div class="ai_analytics_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="ai_analytics_key">AI & Analytics Feature</div>
            </div>


        </div>
        <div class="ai_analytics_features_outer">
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper">
                    <div class="heading_part1">Automated</div>
                    <div class="heading_part2">Ticket Routing</div>
                </div>
                <div class="line1"></div>
                <div class="ai_para">
                    AI algorithms can analyze the content of incoming tickets and intelligently route them to the most
                    appropriate department or agent based on the nature of the issue
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/ai_feat_image1.png" />
                </div>
            </div>
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper ai_analytics_inner_heading_wrapper2">
                    <div class="heading_part1">Predictive</div>
                    <div class="heading_part2">Analytics</div>
                </div>
                <div class="line1"></div>
                <div class="ai_para ai_para2">
                    By analyzing historical data, AI can predict periods of high-ticket volume, allowing support teams
                    to proactively allocate resources and manage staffing levels
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/feat_copy2.png" />
                </div>
            </div>
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper">
                    <div class="heading_part1">Smart tagging</div>
                    <div class="heading_part2"> & Categorization</div>
                </div>
                <div class="line1"></div>
                <div class="ai_para ">
                    AI algorithms can automatically tag and categorize incoming tickets based on their content, enabling
                    better organization and prioritization
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/feat_copy3.png" />
                </div>
            </div>
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper ai_analytics_inner_heading_wrapper4">
                    <div class="heading_part1">Automated</div>
                    <div class="heading_part2">Ticket Resolution</div>
                </div> 
                <div class="line1"></div>
                <div class="ai_para ai_para4">
                    AI-powered systems can provide agents with suggested solutions or knowledge base articles based on the ticket's content. This expedites problem-solving and reduces resolution times
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/feat_copy4.png" />
                </div>
            </div>
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper">
                    <div class="heading_part1">Continuous learning </div>
                    <div class="heading_part2">from interactions</div>
                </div>
                <div class="line1"></div>
                <div class="ai_para ai_para">
                    AI systems can learn from past interactions to improve their accuracy over time. As they process more tickets, they become better at understanding context and providing relevant response
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/feat_copy5.png" />
                </div>
            </div>
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper ai_analytics_inner_heading_wrapper6">
                    <div class="heading_part1">Insightful</div>
                    <div class="heading_part2">Dashboards</div>
                </div>
                <div class="line1"></div>
                <div class="ai_para ai_para6">
                    Informed decision-making is the hallmark of effective support management. Empower your decision-making with data-driven insights.
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/feat_copy6.png" />
                </div>
            </div>
            <div class="ai_analytics_features_inner">
                <div class="ai_analytics_inner_heading_wrapper">
                    <div style="visibility: hidden;" class="heading_part1">Reports</div>
                    <div class="heading_part2">Reports</div>
                </div>
                <div class="line1"></div>
                <div class="ai_para">
                    Our Reports feature generates in-depth analytical reports based on historical data collected within the platform
                </div>
                <div class="ai_feat_image_wrapper">
                    <img class="ai_feat_image" src="assets/ezdesk/feat_copy7.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="tickets_features_wrapper">
       <img class="blue_star" src="assets/ezdesk/blue_star.png"/>
        <div class="tickets_features_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="tickets_key">Ticket <span id="tickets_featues_text">Features</span></div>
            </div>


        </div>
        <div class="ticket_features_wrapper">
            <div class="ticket_feature">
                <img class="ticket_feature_image" src="assets/ezdesk/search_tickets.png"/>
                <div class="ticket_feature_content">Dynamic Ticket Status</div>
                <div class="ticket_feature_content" style="padding-top: 0;"> Tracking </div>
                <!-- <div class="ticket_feature_content3"> Overview</div> -->
            </div>
            <div class="ticket_feature">
                <img class="ticket_feature_image" src="assets/ezdesk/edit_tickets.png"/>
                <div class="ticket_feature_content">Intuitive Ticket Tags</div>
              
            </div>
            <div class="ticket_feature">
                <img class="ticket_feature_image" src="assets/ezdesk/files.png"/>
                <div class="ticket_feature_content">Diverse Ticket Types,</div>
                <div  class="ticket_feature_content" style="padding-top: 0;"> Priorities, and SLAs</div>
            </div>
            <div class="ticket_feature">
                <img class="ticket_feature_image" src="assets/ezdesk/ticket_history.png"/>
                <div class="ticket_feature_content">Real-Time Ticket</div>
                <div  class="ticket_feature_content" style="padding-top: 0;"> Updates </div>
                <!-- <div  class="ticket_feature_content3"></div> -->
            </div>
        </div>
        
    </div>
    <div class="la_student_key_features_wrapper">
        <img class="plus1" src="assets/la/plus_icon.png"/>
        <img class="plus2" src="assets/la/plus_icon.png"/>
        <div class="la_student_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="la_student_key">Key <span id="la_student_text"> Features</span> </div>
            </div>


        </div>
        <div class="la_student_key_features_content">
          
            <img class="students_left_image" src="assets/ezdesk/key_features.png"/>
            <div class="student_right_section_wrapper">
            
                <div class="student_right_section_wrapper">
                 
                    <div class="students_features_in_detail_wrapper">
    
                        <div class="students-row1" style="display: flex;">
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">With real-time chat functionality: </span> Embedded directly within our system, you can engage with customers instantly, resolving their queries and issues in the moment they arise</div>
                            </div>
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Public and Private Environments: </span>Our system offers both public and private interfaces, enabling your team to collaborate internally while providing customers with clear updates on their support requests</div>
                            </div>
                        </div>
                        
                      
                    </div>
                    <div class="students_features_in_detail_wrapper">
    
                        <div class="students-row1" style="display: flex;">
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Tailored Email Templates: </span>Elevate Your Communication Incorporating company branding elements, personalized messaging, and hyperlinks to pertinent resources, these email templates ensure consistency and professionalism across all your company communication</div>
                            </div>
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Fine-Tuned Permissions: </span>Security and Role-Based Access Security and access control are paramount. Our feature-rich permission settings allow you to define granular access based on roles</div>
                            </div>
                        </div>
                        
                      
                    </div>
                    <div class="students_features_in_detail_wrapper">
    
                        <div class="students-row1" style="display: flex;">
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Effortless Mailer Configuration:</span> Connecting with your customers should be seamless. Our mailer configuration feature simplifies the process of sending and receiving emails</div>
                            </div>
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Robust Data Backup and Restoration:</span> Data Security Ensured Data is your organization's lifeline. Our automated data backup system safeguards your valuable information, while a robust restoration process guarantees operational continuity in the face of unforeseen event</div>
                            </div>
                        </div>
                        
                      
                    </div>
                    <div class="students_features_in_detail_wrapper">
    
                        <div class="students-row1" style="display: flex;">
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Setting and managing Service Level Agreements (SLAs): </span>is a breeze with our system. This feature will help empowers organizations to define and enforce specific commitments governing response times, resolution durations, and overall customer support quality</div>
                            </div>
                            <div class="student_feature_with_icon">
                                <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                                <div class="student_feature"><span class="stud_feature_inner">Privacy Policy Crafted with Care:</span> Data Privacy and Trust Transparency builds trust. Our meticulously crafted privacy policy outlines how customer data is collected, stored, and safeguarded in accordance with industry regulations</div>
                            </div>
                        </div>
                        
                      
                    </div>
                   
                </div>
              
            </div>
        </div>
    </div>
    <div class="ezdesk_last_section">
        <img class="last_section_star1" src="assets/ezdesk/white_star.png"/>
        <img class="last_section_star2" src="assets/ezdesk/white_star.png"/>
        <div class="ezdesk_last_section_inner">
            <div class="first_image_container">
                <div  [routerLink]="['/contact-us']" class="redirection4"></div>
                <img  [routerLink]="['/contact-us']" class="first_image" src="assets/ezdesk/ezdesk_last_section_left.png"/>
            </div>
         
            <img class="middle_image" src="assets/ezdesk/ezdesk_last_section_pc.png"/>
            <div class="last_image">
                <img class="last_image1" src="assets/ezdesk/googlepay.png"/>
                <img class="last_image2" src="assets/ezdesk/appstore.png"/>
            </div>
            
        </div>
    </div>
</div>