import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  current_year = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
    this.scrollToTop()
  }
  scrollToTop(){
    const element = document.querySelector('.terms-and-conditions-main');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }

}
