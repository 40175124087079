import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-business-consulting',
  templateUrl: './business-consulting.component.html',
  styleUrls: ['./business-consulting.component.scss']
})
export class BusinessConsultingComponent implements OnInit {
  current_year = new Date().getFullYear();
  documentRef = document;

  constructor (private route: ActivatedRoute) {}

  ngOnInit (): void {
  
    if(!this.route.snapshot.fragment){
      this.scrollToTop()
    }else {
      this.scrollToElement()
    }
  }

  scrollToElement(){
    const element = this.documentRef.querySelector(`#${this.route.snapshot.fragment}`)
      if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      }
  }

  scrollToTop(){
    const element = document.querySelector('.business-consulting-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  onLinkBusiness() {
    const element = document.querySelector('.Business')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onLinkP3M() {
    const element = document.querySelector('.P2M')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onLinkAgile(){
    const element = document.querySelector('.Agile')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onLinkDigital(){
    const element = document.querySelector('.Digital')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
}
