import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-productpage',
  templateUrl: './main-productpage.component.html',
  styleUrls: ['./main-productpage.component.scss']
})
export class MainProductpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  

}
