<div class="resourcing-main-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true"  class="black-links mobile-nav"></app-navbar>
    <div class="resourcing-banner-wrapper">
        <app-navbar [isNavImageBlack]="true"  class="desktop-nav"></app-navbar>
        <div class="resourcing-content-wrapper">
            <div class="resourcing-heading"  >Resourcing</div>
            <div class="line"></div>
            <div class="resourcing-subcontent-wrapper">
                <div class="subcontent" >Managed Captive</div>
                <div class="subcontent-line"></div>
                <div class="subcontent" >Delivery Center</div>
                <div class="subcontent-line"></div>
                <div class="subcontent" >Hiring Contract</div>
            </div>
        </div>
    </div>
    <div class="build-your-dream-wrapper">

        <div class="our-offering-title-wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
            </div><span id="our-offering-text">Building Your Dream</span>
        </div>
        <div class="our-offering-subheading">We take over hiring and recruitment related risks to build your team.</div>

        <div class="our-offering-paragraph">With our global eco-system, we can enable the right experts, at the right
            moment and at the required location for you.

            We support your strategy with top-notch talent, be it short term or on-going, hourly, or full time, onsite
            or off site, permanent hire, or contract to hire. With flexible staffing strategy we enable you to extend
            your team with the missing and needed skills.
            </div>
            <!-- <ul class="our-offering-list">
                <li>Quickly Scale up or down on demand</li>
                <li>Top-notch talent</li>
                <li>Quick onboarding & Knowledge transfer</li>
                <li>Highly Cost-effective</li>
                <li>Wide range of skill/roles</li>
                <li>Team addition or entire team</li>
                <li>Optimize employment-related risks and cost</li>
                <li>Global reach</li>
                <li>Maintain complete operational control</li>
            </ul> -->
            <div class="bullet-point-wrapper">
                <div class="inner-bullet">
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Quickly Scale up or down on demand</div>
                    </div>
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Top-notch talent</div>
                    </div>
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Quick onboarding & Knowledge transfer</div>
                    </div>
                </div>
                <div class="inner-bullet">
                   
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Highly Cost-effective</div>
                    </div>
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Wide range of skill/roles</div>
                    </div>
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Team addition or entire team</div>
                    </div>
                </div>
               
                <div class="inner-bullet">
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Optimize employment-related risks and cost</div>
                    </div>
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Global reach</div>
                    </div>
                    <div class="bullet-points">
                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                        <div class="bullet-points-text">Maintain complete operational control</div>
                    </div>
                </div>
               
               

            </div>
        <div class="labels-wrapper">

            <div class="assist-label-wrapper"(click)=" onLinkManaged()">
                <p class="assist-label">Managed Captive</p>
            </div>
            <div class="assist-label-wrapper"  (click)="onLinkDelivery()">
                <p class="assist-label">Delivery center</p>
            </div>
            <div class="assist-label-wrapper"   (click)=" onLinkHiring() ">
                <p class="assist-label" >Hiring contract</p>
            </div>


        </div>
    </div>
    <div class="timeline-content-main-wrapper">
        <img class="three-dotted-square" src="assets/resourcing/three-dotted-square.png" />
        <img class="two-dotted-square" src="assets/resourcing/two-dotted-square.png" />
        <div class="middle-line"></div>

        <div class="big-orange-circle-wrapper">

            <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
        </div>

        <div class="square-animation-container">
            <div class="square-animation-container-1">
                <img class="square-animation-one" src="assets/resourcing/small-square-animation.gif" />
            </div>
            <div class="square-animation-container-2">
                <img class="square-animation-two" src="assets/resourcing/small-square-animation.gif" />
            </div>
        </div>

        <div class="timeline-content-inner-wrapper-one">
            <div class="yellow-circle-wrapper">
                <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
            </div>

            <img class="managed-captive-image" src="assets/resourcing/managed-captive-image.png">
            <div class="managed-captive-wrapper">

                <div class="managed-captive-heading-title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div id="managed-captive-heading">Managed Captive</div>
                    <div></div>
                    <div class="managed-captive-paragraph">Experience captive offshoring like never before with ency
                        Consulting! ency consulting team specializes in the formation and management
                        of managed captives. It means that we help the organization make strategic decisions to create
                        its presence with lower cost, high productivity, and quality. They also bring in better customer
                        experience and consumer insights that aid product managers in making better decisions</div>
                </div>

            </div>
        </div>

        <div class="layered-animation-container">
            <div class="layered-animation-container-1">
                <img class="layered-animation-one" src="assets/resourcing/layered-animation.gif" />
            </div>
            <div class="layered-animation-container-2">
                <img class="layered-animation-two" src="assets/resourcing/layered-animation.gif" />
            </div>
        </div>

        <div class="timeline-content-inner-wrapper-two">
            <div class="blue-circle-wrapper">
                <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
            </div>
            <div class="delivery-center-wrapper">

                <div class="delivery-center-heading-title-wrapper">
                    <div class="heading-container">
                        <div id="delivery-center-heading">Delivery Center</div>
                        <div class="prefix_style_circle_wrapper">
                            <div class="line"></div>
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                        </div>
                    </div>

                    <div lang="en" class="delivery-center-paragraph">Unleash innovation and seamless delivery with our Global
                        Delivery expertise! With top-notch technical skills, robust methodologies, and a global
                        structure, we ensure flawless IT-enabled services for your business. Let our Delivery Centers
                        take care of the end-to-end process while you focus on growth and success!</div>
                </div>

            </div>
            <img class="delivery-center-image" src="assets/resourcing/delivery-center-image.png">
        </div>
        <div class="layered-animation-container-two">
            <div class="layered-animation-container-1">
                <img class="layered-animation-one" src="assets/resourcing/layered-animation.gif" />
            </div>
            <div class="layered-animation-container-2">
                <img class="layered-animation-two" src="assets/resourcing/layered-animation.gif" />
            </div>
        </div>

        <div class="timeline-content-inner-wrapper-three">
            <div class="yellow-circle-wrapper">
                <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
            </div>
            <img class="managed-captive-image" src="assets/resourcing/hiring-contract-image.png">
            <div class="managed-captive-wrapper">

                <div class="managed-captive-heading-title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div id="managed-captive-heading">Hiring Contract</div>
                    <div></div>
                    <div lang="en" class="managed-captive-paragraph">Boost your business success with a healthy workforce both
                        permanent and specialized!
                        At our businesses, we understand the importance of a healthy workforce in achieving business
                        goals. That's why we help business owners hire specialized talent for short-term needs. Let us
                        support your team's health and productivity while you focus on achieving your business
                        objectives</div>
                </div>

            </div>
        </div>
        <div class="layered-animation-container-three">

            <div class="big-blue-circle-wrapper">

                <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
            </div>

            <div class="layered-animation-container-1">
                <img class="layered-animation-one" src="assets/resourcing/small-square-animation.gif" />
            </div>
            <div class="layered-animation-container-2">
                <img class="layered-animation-two" src="assets/resourcing/small-square-animation.gif" />
            </div>
        </div>
    </div>
    <div class="timeline-content-for-mobile-main-wrapper">
        <div class="timeline-wrapper">
            <div class="middle-line"></div>
            <div class="big-orange-circle-wrapper">

                <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
            </div>
            <div class="big-blue-circle-wrapper">

                <img class="orange-circle-animation" src="assets/resourcing/orange-circle-animation.gif" />
            </div>
        </div>
        <div class="content-main-wrapper">
            <div class="managed-captive-wrapper">
                <div class="managed-captive-heading-title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div id="managed-captive-heading">Managed Captive</div>
                    <div></div>
                    <div lang="en" class="managed-captive-paragraph">"Experience captive offshoring like never before with ency
                        Consulting! ency consulting team specializes in the formation and management
                        of managed captives. It means that we help the organization make strategic decisions to create
                        its presence with lower cost, high productivity, and quality. They also bring in better customer
                        experience and consumer insights that aid product managers in making better decisions</div>
                </div>

            </div>
            <div class="managed-captive-wrapper">
                <div class="managed-captive-heading-title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div id="managed-captive-heading">Delivery center</div>
                    <div></div>
                    <div lang="en" class="managed-captive-paragraph">Unleash innovation and seamless delivery with our Global
                        Delivery expertise! With top-notch technical skills, robust methodologies, and a global
                        structure, we ensure flawless IT-enabled services for your business. Let our Delivery Centers
                        take care of the end-to-end process while you focus on growth and success!</div>
                </div>

            </div>
            <div class="managed-captive-wrapper">
                <div class="managed-captive-heading-title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div id="managed-captive-heading">Hiring Contract</div>
                    <div></div>
                    <div lang="en" class="managed-captive-paragraph">Boost your business success with a healthy workforce both
                        permanent and specialized!
                        At our businesses, we understand the importance of a healthy workforce in achieving business
                        goals. That's why we help business owners hire specialized talent for short-term needs. Let us
                        support your team's health and productivity while you focus on achieving your business
                        objectives</div>
                </div>

            </div>
        </div>
    </div>

</div>