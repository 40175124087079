<div class="main-homepage" id="container">
    <app-navbar class="black-links"></app-navbar>
    <section class="hero_section" id="container">
        <div class="left_content_wrapper">
            <span>
                <p class="f-41" id="heading1">
                    <span id="orange-rectangle"></span>
                    <span id="orange-rectangle"></span>
                    <span id="orange-rectangle"></span>
                    <!-- <span id="orange-rectangle"></span> -->
                    Helping Global Organizations
                </p>
                <div class="gap_animation">
                    <p class="f-88 fw-700" id="heading2">Bridge the <span id="hero_gap_text">Gap</span></p>
                    <!-- <div class="animation_container">
                    <div class="f-88 fw-700 animation_typewriter">Gap</div>
                </div> -->
                </div>
                <div id="heading3_wrapper">
                    <p class="f-66 fw-700" id="heading3">Between
                    <div class='animation_wrapper'>
                        <div class='animation_text_wrapper'>
                            <p class='animation_text f-66 fw-700' id="text_1">Strategy</p>
                            <p class='animation_text f-66 fw-700' id="text_2">Idea</p>
                            <p class='animation_text f-66 fw-700' id="text_3">Value</p>
                        </div>
                    </div>
                    <!-- </p> -->
                </div>
                <!-- </p> -->
            </span>
            <span>
                <p class="get_in_touch_inner f-22 fw-600" routerLink="/contact-us">
                    <img id="right_arrow" src="assets/right_arrow_orange.png" alt="ency Right arrow" />
                    Get in touch
                </p>
            </span>
            <span class="follow_us_wrapper">
                <a aria-label="facebook" target="_blank" href="https://www.facebook.com/encyConsulting"> <img alt="fb"
                        src="assets/hero_fb_icon.png" class="social_media_btn" /></a>
                <a aria-label="linkedin" target="_blank" href=" https://www.linkedin.com/company/encycg"> <img
                        alt="insta" src="assets/linkedin.png" class="social_media_btn" /></a>
                <a aria-label="twitter" target="_blank" href="https://twitter.com/encyconsulting?lang=en"><img
                        alt="twitter" src="assets/hero_twitter_icon.png" class="social_media_btn" /></a>
                <span id="divider-line"></span>
                <p class="f-24 fw-500 follow_us_text">Follow Us</p>
            </span>

        </div>
        <div class="right_content_wrapper">
            <img style="width: 523px; position: absolute;" alt="globe_animation" src="assets/globe_rotating.webp" />
            <img style="width: 523px; position: relative; z-index: 1; opacity: 0.7;" alt="globe"
                src="assets/hero_globe.webp" />
            <a id="whatsapp_icon_wrapper" href="https://wa.me/16473910700" target="_blank">
                <div class="whatsapp-icon-text-wrapper">
                    Get in touch with us by WhatsApp
                </div>
                <img id="whatsapp_icon" alt="icon_whatsapp" src="assets/whatsapp_icon.webp" />
            </a>
        </div>
    </section>


    <section class="how_do_we_work_wrapper">
        <div class="inner_wrapper">
            <div class="left_content">
                <span>
                    <div id="heading1" class="f-36">

                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line"></div>
                        </div>
                        How do we
                    </div>
                    <p id="heading2" class="f-64 fw-700">work to help cli<span id="e_wrapper">e<img
                                id="animation_behind_e" alt="animation_work_to_help_client"
                                src="assets/work_to_help_client_animation.webp" /></span>nts
                    </p>
                </span>
                <span class="our_team_box_list_wrapper">
                    <!-- 1st -->
                    <p (click)="help_clients_set(0, 'our_team')" *ngIf="help_clients_active != 0"
                        class="f-28 fw-400 pointers"><img id="image1" src="assets/homepage-helpclients/1.webp" />
                        <span>Our
                            Team</span>
                    </p>
                    <div *ngIf="help_clients_active == 0" class="our_team_box">
                        <span class="inner">
                            <img class="image1_people" alt="people_image" id="people_icon"
                                src="assets/homepage-helpclients/1.webp" />
                            <span>
                                <p class="f-20 fw-700">Our Team</p>
                                <p class="f-16 fw-500">A group of solvers combining human ingenuity, experience, and
                                    technology innovation.</p>
                            </span>
                        </span>
                    </div>
                    <!-- 2nd -->
                    <p (click)="help_clients_set(1, 'client_engagement')" *ngIf="help_clients_active != 1"
                        class="f-28 fw-400 pointers"><img id="image1" alt="image_client"
                            src="assets/homepage-helpclients/2.webp" /> <span>Client
                            engagement</span></p>
                    <div *ngIf="help_clients_active == 1" class="our_team_box">
                        <span class="inner">
                            <img id="people_icon" alt="icon_people" src="assets/homepage-helpclients/2.webp" />
                            <span>
                                <p class="f-20 fw-700">Client engagement</p>
                                <p class="f-16 fw-500">We advocate your needs and work in your best interest. Our unique
                                    approach combines innovation, creativity, and collaboration to solve toughest
                                    problems
                                    and drive success. </p>
                            </span>
                        </span>
                    </div>
                    <!-- 3rd -->
                    <p (click)="help_clients_set(2, 'real_world')" *ngIf="help_clients_active != 2"
                        class="f-28 fw-400 pointers"><img id="image2" alt="real_world"
                            src="assets/homepage-helpclients/3.webp" /> <span>Real world
                            Impact</span></p>
                    <div *ngIf="help_clients_active == 2" class="our_team_box">
                        <span class="inner">
                            <img id="people_icon" alt="icon_real_world" src="assets/homepage-helpclients/3.webp" />
                            <span>
                                <p class="f-20 fw-700">Real world impact</p>
                                <p class="f-16 fw-500">We solve real-world complex business problems enabling
                                    operational
                                    enhancement by using technology and tools combining extensive consulting and
                                    technology
                                    expertise with pragmatic approach.</p>
                            </span>
                        </span>
                    </div>
                    <!-- 4th -->
                    <p (click)="help_clients_set(3, 'rigors_standard')" *ngIf="help_clients_active != 3"
                        class="f-28 fw-400 pointers"><img id="image3" alt="rigors_standards"
                            src="assets/homepage-helpclients/4.webp" /> <span>Rigors
                            Standards</span></p>
                    <div *ngIf="help_clients_active == 3" class="our_team_box">
                        <span class="inner">
                            <img id="people_icon" alt="rigors_standards_icon"
                                src="assets/homepage-helpclients/4.webp" />
                            <span>
                                <p class="f-20 fw-700">Rigors standards</p>
                                <p class="f-16 fw-500">We continually adapt to evolving industry best practices and
                                    standards for technology consulting and software development governed by rigid Data
                                    and
                                    privacy policies.</p>
                            </span>
                        </span>
                    </div>
                    <!-- 5th -->
                    <p (click)="help_clients_set(4, 'result_oriented')" *ngIf="help_clients_active != 4"
                        class="f-28 fw-400 pointers"><img id="image4" alt="result_oriented"
                            src="assets/homepage-helpclients/5.webp" /> <span>Results
                            oriented</span></p>
                    <div *ngIf="help_clients_active == 4" class="our_team_box">
                        <span class="inner">
                            <img id="people_icon" alt="result_oriented_icon" src="assets/homepage-helpclients/5.webp" />
                            <span>
                                <p class="f-20 fw-700">Results oriented</p>
                                <p class="f-16 fw-500">We are accountable for our work so our focus is on delivering
                                    business value. Solutions are feasible, grounded in reality and tied to the business
                                    goals of your company.</p>
                            </span>
                        </span>
                    </div>
                    <!-- 6th -->
                    <p (click)="help_clients_set(5, 'success_partner')" *ngIf="help_clients_active != 5"
                        class="f-28 fw-400 pointers"><img id="image5" alt="success_partner"
                            src="assets/homepage-helpclients/6.webp" /> <span>Success
                            Partners</span></p>
                    <div *ngIf="help_clients_active == 5" class="our_team_box">
                        <span class="inner">
                            <img id="people_icon" alt="success_partner_icon" src="assets/homepage-helpclients/6.webp" />
                            <span>
                                <p class="f-20 fw-700">Success partners</p>
                                <p class="f-16 fw-500">At our core, we're all about you. We join your team and are
                                    equally
                                    invested in your success. Knowledgeable, highly available and pro-active. A trusted
                                    partner with strategic approach. </p>
                            </span>
                        </span>
                    </div>
                    <!-- 7th -->
                    <p (click)="help_clients_set(6, 'expert_team')" *ngIf="help_clients_active != 6"
                        class="f-28 fw-400 pointers"><img id="image6" alt="expert_teams"
                            src="assets/homepage-helpclients/7.webp" alt="Expert
                            Teams" /> <span>Expert
                            Teams</span></p>
                    <div *ngIf="help_clients_active == 6" class="our_team_box">
                        <span class="inner">
                            <img id="people_icon" alt="expert_teams" src="assets/homepage-helpclients/7.webp" />
                            <span>
                                <p class="f-20 fw-700">Expert teams</p>
                                <p class="f-16 fw-500">Consultants, Designers, data visualizers and technical experts
                                    meet
                                    to give you appealing, business driven, scalable solution for the future aligned to
                                    your
                                    business strategy.</p>
                            </span>
                        </span>
                    </div>
                </span>
            </div>
            <div class="right_content">
                <div class="arrows_wrapper">
                    <span class="arrow_btn" (click)="decrease_clients_set()">
                        < </span>
                            <span class="arrow_btn" (click)="increase_clients_set()">></span>
                </div>
                <div class="right_image_content">
                    <img class="helpClientImageOne" alt="How do we help clients" [src]="helpClientImageSrc" />
                    <!-- <img class="helpClientImageTwo" alt="How do we help clients" [src]="helpClientImageSrc" /> -->
                </div>
            </div>

        </div>

    </section>

    <!-- TODO  -->
    <section class="who_we_are_timeline">
        <p class="f-85 fw-700 text-center">WHO WE ARE</p>
        <div class="content_wrapper">
            <div class="left">
                <div class="left_inner_1">
                    <p class="f-24">Partners for strategic</p>
                    <p class="f-32 fw-700 lh-40">
                        Business<br>Transformation </p>
                    <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="left_inner_2">
                    <img class="stack_animation" alt="stack_animation" alt="who_we_are_stack_animation" src="assets/who_we_are_stack_animation.webp" />
                </div>
                <div class="left_inner_3">
                    <div>
                        <p class="f-24">Partners for
                        </p>
                        <p class="f-32 fw-700 lh-40">
                            Your Success</p>
                        <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle">
                <div class="circle">
                    <p class="f-36 fw-700 text-white">idea....</p>
                    <p class="f-36 fw-700 text-white value_text">value</p>
                </div>

                <div class="background_line"></div>

                <div class="circle_animation_wrapper">
                    <img id="who_we_are_circle_animation" alt="who_we_are_circle_animation"
                        src="assets/who_we_are_circle_animation.webp" />
                </div>
            </div>
            <div class="right">
                <div class="right_inner_1">
                    <div>
                        <p class="f-24">Partners who work</p>
                        <p class="f-32 fw-700 lh-40">
                            for lasting Business<br> value
                        </p>
                        <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <div class="right_inner_2">
                    <img class="stack_animation" alt="stack_animation" src="assets/who_we_are_stack_animation.webp" />
                </div>
                <div class="right_inner_3">
                    <p class="f-24">Partners to solve
                    </p>
                    <p class="f-32 fw-700 lh-40">
                        Business Problems</p>
                    <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="who_we_are_timeline_mobile">
        <div class="content_wrapper">
            <!-- <div class="left_content_wrapper">
            <p></p>
            <p><img src="assets/who_we_are_stack_animation.gif"
                id="stack_animation" /></p>
            <p><img src="assets/who_we_are_stack_animation.gif"
                id="stack_animation" /></p>
        </div> -->
            <div class="middle_content_wrapper">
                <div class="circle">
                    <p class="f-23 fw-600 text-white">idea....</p>
                    <p class="f-23 fw-600 text-white value_text">value</p>
                    <p class="f-32 fw-600" id="mobile_heading">WHO WE ARE</p>
                </div>

                <div class="background_line"></div>

            </div>
            <div class="right_content_wrapper">
                <div class="right_content_inner">
                    <div class="flex_wrapper">
                        <div>
                            <p class="f-20">Partners for
                            </p>
                            <p class="f-24 fw-700">your Succcess</p>
                            <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                            <div class="prefix_style_circle_wrapper">
                                <div class="circle">
                                    <div class="inner_circle"></div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <img alt="ency tech stack" src="assets/who_we_are_stack_animation.webp"
                            id="stack_animation_r" />
                    </div>

                    <!-- <div class="flex_wrapper flex_wrapper_end">
                    <img src="assets/who_we_are_stack_animation.gif" id="stack_animation" />
                </div> -->

                    <div class="flex_wrapper">
                        <div>
                            <p class="f-20">Partners to solve

                            </p>
                            <p class="f-24 fw-700">Business Problems</p>
                            <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                            <div class="prefix_style_circle_wrapper">
                                <div class="circle">
                                    <div class="inner_circle"></div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <img src="assets/who_we_are_stack_animation.webp" alt="ency tech stack"
                            id="stack_animation_l" />
                    </div>
                    <div class="flex_wrapper">
                        <div>
                            <p class="f-20">Partners for

                            </p>
                            <p class="f-24 fw-700">Strategic Business
                                Transformation</p>
                            <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                            <div class="prefix_style_circle_wrapper">
                                <div class="circle">
                                    <div class="inner_circle"></div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <img alt="ency tech stack" src="assets/who_we_are_stack_animation.webp"
                            id="stack_animation_r" />
                    </div>

                    <div class="flex_wrapper">
                        <div>
                            <p class="f-20">Partners who
                            </p>
                            <p class="f-24 fw-700">work for long lasting
                                value </p>
                            <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
                            <div class="prefix_style_circle_wrapper">
                                <div class="circle">
                                    <div class="inner_circle"></div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <img src="assets/who_we_are_stack_animation.webp" alt="ency tech stack"
                            id="stack_animation_l" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="our_offerings">
        <p class="f-64 fw-700 text-white">Our Offerings</p>
        <div class="inner_wrapper">
            <div class="left_content">
                <div class="left_content_inner">

                    <a *ngFor="let item of our_offerings_list[our_offerings_active].labels;" [routerLink]=[item.link]
                        [fragment]="item.section" class="label_card text-black fw-700"
                        style="cursor:pointer;text-decoration: none;">{{item.label}}</a>

                </div>
                <!-- <div class="left_content_inner">

            </div> -->
                <div class="text_image">
                    <span id="text_image_left">
                        <p class="text-white f-20 fw-700" id="we_at_ency">
                            {{our_offerings_list[our_offerings_active].content}}

                        </p>
                        <ul>
                            <li class="text-white f-20 fw-700"
                                *ngFor="let item of our_offerings_list[our_offerings_active].bullet;">{{item}}</li>
                        </ul>
                        <p id="explore_more" [routerLink]="our_offerings_list[our_offerings_active].link"> <img
                                src="assets/next_icon.png" alt="next_icon" id="next_icon" /> <span
                                class="explore_more_text text-white f-22 fw-600">Explore
                                More</span> </p>
                    </span>
                    <img src={{our_offerings_list[our_offerings_active].image}} alt="business_people_hands"
                        id="business_people_hands" />

                </div>
            </div>

            <div class="right_content">
                <!-- <p id="active" class="text-white f-24 fw-500"> <img id="prev_icon" src="assets/previous_icon_orange.png" /> Business
                Consulting</p>
            <p class="text-white f-24 fw-500">Technical Consulting</p>
            <p class="text-white f-24 fw-500">Resourcing</p>
            <p class="text-white f-24 fw-500">Products</p> -->
                <div id="our-offerings-clickable-tab" *ngFor="let topic of our_offerings_list; index as i;"
                    (click)="our_offerings_set(i)">
                    <p [id]="i == our_offerings_active ? 'active' : 'inactive'"
                        [class]="i == our_offerings_active ? 'fw-600' : 'fw-500'"
                        class="f-24 style_cursor_pointer right_menu_items">
                        <img id="prev_icon" alt="prev_icon" src="assets/previous_icon_orange.png" />{{topic.heading}}
                    </p>
                </div>
            </div>

        </div>
    </section>


    <section class="technologies_wrapper">
        <div id="heading_wrapper">
            <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" /> -->
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
            </div>
            <p class="f-64 fw-700">Technologies</p>
        </div>
        <div class="content_wrapper">
            <div class="left_content">
                <div class="labels_wrapper">
                    <span *ngFor="let item of technologies_list[technologies_active].labels;"
                        class="label_card text-black fw-700">{{item}}</span>
                </div>
                <p class="f-24 fw-500">{{technologies_list[technologies_active].content}}
                </p>
                <div routerLink="/tech-stack" class="explore_more_wrapper">
                    <img src="assets/next_icon_orange.png" alt="next_icon" id="next_icon" />
                    <p class="f-22 fw-600">Explore More</p>
                </div>
            </div>
            <div class="middle_content">
                <div class="middle_content_inner">

                </div>
                <img src="assets/work_to_help_client_animation.webp" id="animation_circle" alt="animation_circle" />
            </div>
            <div class="right_content">
                <!-- <div id="pointer1">
                <img src="assets/previous_icon.png" id="prev_icon" />
                <p class="f-24 fw-600">Cloud</p>
            </div>
            <div id="pointer1">
                <img src="assets/previous_icon.png" id="prev_icon" />
                <p class="f-24 fw-600">Automation </p>
            </div>
            <div id="pointer1">
                <img src="assets/previous_icon.png" id="prev_icon" />
                <p class="f-24 fw-600">Data AI</p>
            </div> -->
                <div *ngFor="let item of technologies_list; index as i;" (click)="technologies_set(i)"
                    [id]="i == technologies_active ? 'pointer1' : 'inactive'" class="right_menu_items  ">
                    <img src="assets/previous_icon.png" id="prev_icon" alt="prev_icon" />
                    <p class="f-24 fw-600">{{item.title}}</p>
                </div>

                <!-- <div *ngFor="let topic of software_services_list; index as i;" (click)="software_services_set(i)">
                <p [id]="i == software_services_active ? 'pointer1' : 'inactive'"
                    [class]="i == software_services_active ? 'fw-600' : 'fw-500'" class="f-24 style_cursor_pointer">
                    {{topic.heading}}<img id="next_icon" src="assets/next_icon_orange.png" />
                </p>
            </div> -->
            </div>
        </div>
    </section>


    <section class="software_services_wrapper">
        <p class="f-64 fw-600 text-center" style="margin-bottom: 1vw;">Software Services</p>
        <p class="f-20 fw-500" style="text-align: justify">We have rich global experience in formulating, executing, and
            delivering
            multi-disciplinary global heterogeneous technology Programs / Projects across North America, Europe &
            Asia-Pacific. With seasoned engineers and the dedicated team approach, ency can deliver technology solutions
            with quickly assembled teams ready to work on your assignment.
            We offer full-cycle software development services and can build custom solutions from scratch or work on
            existing applications or products to make them effective for you. We bring a wide range of technology
            services spread across Languages, Frameworks, Backends, Frontends, Mobile along with BI, Data Science, AI/ML
            spreading to Cloud and Devops services
        </p>
        <br>
        <ul class="f-20 fw-500" style="text-align: justify">
            <li>Leverage our technology expertise and extensive knowledge to implement technology aligned to your
                business strategy.</li>
            <li>Our experts will help you build custom software/apps, products, leverage legacy systems, and optimize
                your portfolio while introducing you to latest technologies.</li>
            <li>Designers, data visualizers and technical experts meet to give you appealing, business driven, scalable
                application for the future.</li>
            <li>If you have a business need / pain point / technology silo / technology challenge, we have a solution
                for
                you</li>
        </ul>
        <div class="inner_wrapper">
            <div class="left_content">
                <!-- <p class="f-24 fw-600" id="pointer1">Bespoke software development <img id="next_icon"
                    src="assets/next_icon_orange.png" /></p> -->
                <!-- <p class="f-24 fw-500">Web development<img id="next_icon" src="assets/next_icon_orange.png" /></p>
            <p class="f-24 fw-500">Mobile App development<img id="next_icon" src="assets/next_icon_orange.png" /></p>
            <p class="f-24 fw-500">Cloud based solutions<img id="next_icon" src="assets/next_icon_orange.png" /></p>
            <p class="f-24 fw-500">E-commerce Solutions<img id="next_icon" src="assets/next_icon_orange.png" /></p>
            <p class="f-24 fw-500">Business Intelligence<img id="next_icon" src="assets/next_icon_orange.png" /></p>
            <p class="f-24 fw-500">Software Integration<img id="next_icon" src="assets/next_icon_orange.png" /></p>
            <p class="f-24 fw-500">Quality Assurance and Testing<img id="next_icon" src="assets/next_icon_orange.png" />
            </p> -->
                <div class="software_services_list_wrapper" *ngFor="let topic of software_services_list; index as i;"
                    (click)="software_services_set(i)">
                    <p [id]="i == software_services_active ? 'pointer1' : 'inactive'"
                        [class]="i == software_services_active ? 'fw-600' : 'fw-500'" class="f-24 style_cursor_pointer">
                        {{topic.heading}}<img id="next_icon" alt="next_icon" src="assets/next_icon_orange.png" />
                    </p>
                </div>
            </div>
            <div class="right_content">
                <div class="text_wrapper">
                    <!-- <span id="circle_pointer"></span> -->
                    <!-- <p class="f-20 fw-600 text-left content" >{{software_services_list[software_services_active].c}}
                </p> -->
                    <!-- <div class="software_services_bullet_list_outer">
                        <ul class="f-20 fw-500 software_services_bullet_list">
                            <li >&#8227;*ngFor="let item of software_services_list[software_services_active].bullet;">{{item}}
                            </li>
                        </ul>
                    </div> -->
                    <div style="display: flex;justify-content: space-between;">
                        <div style="display: flex;">
                            <div style="display: flex;flex-direction: column;gap:1.5vw">
                                <div *ngFor="let item of software_services_list[software_services_active].bullet1;"
                                    style="display: flex;
                        gap: 0.3vw;"> <span> <img style="width: 0.5vw;
    padding-bottom: 3px;" alt="bullet_icon" src="assets/bullet_icon_black.png" /></span>{{item}}</div>
                            </div>



                        </div>
                        <div style="display: flex;">
                            <div style="display: flex;flex-direction: column;gap:1.5vw">
                                <div *ngFor="let item of software_services_list[software_services_active].bullet2;"
                                    style="display: flex;
                                gap: 0.3vw;">
                                    <span> <img src="assets/bullet_icon_black.png" alt="bullet_icon" style="width: 0.5vw;
                                        padding-bottom: 3px;" /></span>{{item}}
                                </div>
                            </div>


                        </div>

                   
                    </div>


                
                </div>
                <div [ngClass]="getClass(software_services_list[software_services_active].sno)">
                <div routerLink="/software-services"
                    fragment="{{software_services_list[software_services_active].fragment}}"
                    class="explore_more_wrapper">
                    <img src="assets/next_icon_orange.png" alt="next_icon" id="next_icon" />
                    <p class="f-22 fw-600">Explore More</p>
                </div>
            </div>
                <img src={{software_services_list[software_services_active].image}} alt="top_view_img"
                    id="top_view_img" />
            </div>
        </div>
    </section>

    <section class="multiple_levels_wrapper">
        <div class="heading_wrapper">
            <div id="heading1" class="f-36">
                <!-- <img id="text_prefix_style" src="assets/how_do_we_title_style.JPG" />  -->
                <div class="prefix_style_circle_wrapper">
                    <div class="circle">
                        <div class="inner_circle"></div>
                    </div>
                    <div class="line"></div>
                </div>
                We connect at
            </div>
            <p id="heading2" class="f-64 fw-700">
                Multiple levels </p>
        </div>
        <div class="content_wrapper">
            <div class="left_content">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="orange_box f-18">
                            <span><img src="assets/orange_tick.png" alt="orange_tick" /></span>Consultation
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="orange_box f-18">
                            <span><img src="assets/orange_tick.png" alt="orange_tick" /></span>Project
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="orange_box f-18">
                            <span><img src="assets/orange_tick.png" alt="orange_tick" /></span>Onsite/Offshore
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="orange_box f-18">
                            <span><img src="assets/orange_tick.png" alt="orange_tick" /></span>Offshore delivery
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="orange_box f-18">
                            <span><img src="assets/orange_tick.png" alt="orange_tick" /></span>Captive
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="orange_box f-18">
                            <span><img src="assets/orange_tick.png" alt="orange_tick" /></span>Demand
                        </div>
                    </div>
                </div>
            </div>
            <div class="right_content">
                <!-- <img id="image1" src="assets/multiple_level_back_image_1.jpeg" />
            <img id="image2" src="assets/multiple_level_front_image.jpeg" />
            <img id="image3" src="assets/multiple_level_back_image_2.jpeg" /> -->
                <img id="combined_image" alt="combined_image" [src]="levelImageSrc" />
                <!-- <img id="combined_image_two" [src]="levelImageSrc"/> -->
            </div>
        </div>
    </section>


    <section class="our_current_works">
        <div class="fw-600 f-64 our_current_work_heading">
            <div class="navigator_wrapper_hidden">
                <span class="arrows" (click)="nextItemInTimeline(-1)">
                    < </span>
                        <span class="arrows" (click)="nextItemInTimeline(1)">
                            > </span>
            </div>
            Our Current Works
            <div class="navigator_wrapper">
                <span class="arrows" (click)="nextItemInTimeline(-1)">
                    < </span>
                        <span class="arrows" id="our_current_works_next_arrow" (click)="nextItemInTimeline(1)">
                            > </span>
            </div>
        </div>

        <div class="timeline-container">
            <ul class="vertical-scrollable-timeline" id="vertical-scrollable-timeline">
                <div class="list-progress">
                    <div class="inner">
                    </div>
                </div>
                <li class="first_li">
                    <span>
                        <p class="f-20">{{our_current_works_list[our_current_works_active].breadcrumb}}</p>
                        <p class="fw-600 f-28">{{our_current_works_list[our_current_works_active].title}}</p>
                        <!-- <p class="fw-600 f-28"></p> -->
                        <p class="f-16 pt-22">{{our_current_works_list[our_current_works_active].content1}}
                        </p>
                        <p class="f-16">{{our_current_works_list[our_current_works_active].content2}}</p>
                    </span>

                    <div class="icon-holder" (click)="our_current_works_set(0)"
                        [class]="our_current_works_active == 0 ? 'primary-li' : 'secondary-li'">
                        <i class="fa-solid fa-house-chimney"></i>
                    </div>
                </li>
                <li>

                    <div class="icon-holder" (click)="our_current_works_set(1)"
                        [class]="our_current_works_active == 1 ? 'primary-li' : 'secondary-li'">
                        <i class="fa-solid fa-circle-info"></i>
                    </div>
                </li>
                <li style="padding: 0px;">

                    <div class="icon-holder" (click)="our_current_works_set(2)"
                        [class]="our_current_works_active == 2 ? 'primary-li' : 'secondary-li'">
                        <i class="fa-solid fa-phone"></i>
                    </div>
                </li>


            </ul>
            <img id="phone_with_map" alt="phone with map"
                src={{our_current_works_list[our_current_works_active].image}} />
        </div>
    </section>

    <section class="schedule_a_call_wrapper">
        <div class="content_wrapper">
            <div class="left_content">
                <span class="left_content_inner">
                    <!-- <p class="f-34">
                    Custom Demo
                </p> -->
                    <p class="f-45 fw-600 lh-50">Schedule a call</p>
                    <img id="ency_call_button" src="assets/call_button_animation.webp" alt="ency Call Button" />
                </span>
                <span>
                    <!-- <p class="f-45 fw-600 lh-50">Schedule a call</p> -->
                    <p class="f-40 lh-50">with our consultants</p>
                </span>
            </div>
            <div class="right_content">
                <span class="right_content_inner">
                    <a routerLink="/contact-us" id="start_a_convo_anchor" href="contact.html">
                        <p class="f-32 fw-700 start_a_conversation_text">Start a conversation</p>
                    </a>
                    <img id="ency_right_arrow" src="assets/right_arrow.png" alt="ency right arrow" />
                </span>
            </div>
        </div>
    </section>

</div>