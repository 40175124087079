<footer class="text-lg-start text-muted footer_wrapper">
    <!-- Section: Links  -->
    <section class="footer_internal_top_wrapper">
        <div class=" text-md-start ">
            <!-- Grid row -->
            <div class="content_wrapper ">
                <!-- Grid column -->
                <div class="mb-4 left_content">
                    <!-- Content -->
                    <h6 class="text-uppercase fw-bold">
                        <img id="ency_logo" alt="ency logo" src="assets/aboutus/footer-ency-logo.png" />
                    </h6>
                    <div class="contact_info_wrapper">
                        <div class="internal_rows">
                            <!-- style="width: 28vw;
                            text-align:justify;"" -->
                          
                            <p class="ency_desc f-16 text-white ">ency is a Technology Consulting and Engineering Group specializing in transforming strategic priorities into continued business benefit by maximizing technical opportunities and improving your digital capabilities.<br>An ISO/IEC 27001:2022 certified company.</p>
                         
                       
                        </div>
                        <div class="internal_rows">
                            <img src="assets/aboutus/footer-white-mail.png" alt="mail" id="pre_icons" />
                            <div>
                                <p class="f-16 text-white fw-bold">Email</p>
                                <p class="f-15  text-white">info@encyconsulting.ca</p>
                            </div>
                        </div>
                        <div class="internal_rows">
                            <img src="assets/aboutus/footer-white-phone.png" alt="phone" id="pre_icons" />
                            <div>
                                <p class="f-16 text-white fw-bold">Phone Number</p>
                                <p class="f-15 text-white">+1 (647) 391-0700</p>
                            </div>
                        </div>
                        <div class="internal_rows">
                            <img src="assets/aboutus/footer-white-location.png" alt="location" id="pre_icons" />
                            <div>
                                <p class="f-16 text-white fw-bold">Address</p>
                                <p class="f-15 text-white">25 Sheppard Ave W #300,
                                    North York,<br /> ON M2N 6S6, Canada</p>
                                <!-- <p>+1 (647) 391-0700</p> -->
                            </div>
                        </div>

                    </div>

                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="mid_section_outer_wrapper">
                    <div class="mb-4 mid_sections_wrapper">
                        <!-- Links -->
                        <h6 class="fw-bold f-24 fw-600 text-white">
                            About Us
                        </h6>
                        <p class="fw-500 ">
                            <a  class="f-16" [routerLink]="['/about-us']" class="no_underline text-white ">About ency</a>
                        </p>
                        <p class="fw-500">
                            <a   class="f-16"[routerLink]="['/meet-our-team']" class="no_underline text-white">Meet Our Team</a>
                        </p>
                        <p class="fw-500">
                            <a  class="f-16" [routerLink]="['/career']" class="no_underline text-white">Careers</a>
                        </p>
                        <p class="fw-500">
                            <link>
                            <a class="f-16" [routerLink]="['/ency-advantage']" class="no_underline text-white">ency advantage</a>
                        </p>
                       
                       
                     
                        
                        <!-- <p class="fw-500">
                            <link>
                            <a class="f-16" [routerLink]="['/software-services']" class="text-reset no_underline text-black-important">Services</a>
                        </p>
                        <p class="fw-500">
                            <a  class="f-16" [routerLink]="['/about-us']" class="text-reset no_underline text-black-important">About Us</a>
                        </p>
                        <p class="fw-500">
                            <a   class="f-16"[routerLink]="['/meet-our-team']" class="text-reset no_underline text-black-important">Leadership Team</a>
                        </p>
                        <p class="fw-500">
                            <a  class="f-16" [routerLink]="['/resourcing']" class="text-reset no_underline text-black-important">Resourcing</a>
                        </p>
                        <p class="fw-500">
                            <a  class="f-16" [routerLink]="['/career']" class="text-reset no_underline text-black-important">Careers</a>
                        </p> -->
                        <p class="fw-500">
                            <a  [routerLink]="['/product']"  class=" f16 no_underline text-white">Products</a>
                        </p>
                      
                        
                      
                     
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="mb-4 mid_sections_wrapper text-white">
                     
                        <h6 class="fw-bold f-24 fw-600 ">
                            Resources
                        </h6>
                        <p class="fw-500 ">
                            <a   target="_blank" href="https://blog.encyconsulting.ca/"  class="text-reset no_underline ">Blogs</a>
                        </p>
                    </div>

                    <!-- <div class="mb-4 mid_sections_wrapper">
                      
                        <h6 class="fw-bold f-24 fw-600 text-black-important">
                            Customers
                        </h6>
                        <p>
                            <a href="#!" class="text-reset text-black-important no_underline">Testimonials</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset text-black-important no_underline">Case Studies</a>
                        </p>
                    </div> -->
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="mb-md-0 mb-4 right_section">
                    <img src="assets/aboutus/footer-decoration-image.webp" alt="footer-decoration-image" id="design_image" />
                    <div class="back_to_top_wrapper" (click)="scrollToTop()">
                        <div  >
                            <img class="up_icon" alt="back_to_top_icon" src="assets/back_to_top_icon.png" />
                            <p class="f-18 fw-500 text-white">
                                Back To Top
                            </p>
                            <img class="up_icon" alt="back_to_top_icon" src="assets/back_to_top_icon.png" />
                        </div>
                    </div>
                    <!-- Links -->
                    <!-- <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                <p><i class="fas fa-home me-3"></i> New York, NY 10012, US</p>
                <p>
                    <i class="fas fa-envelope me-3"></i>
                    info@example.com
                </p>
                <p><i class="fas fa-phone me-3"></i> + 01 234 567 88</p>
                <p><i class="fas fa-print me-3"></i> + 01 234 567 89</p> -->

                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->

            <span class="follow_us_wrapper mx-auto">
                <a  target="_blank" href="https://www.facebook.com/encyConsulting"> <img src="assets/aboutus/footer-white-fb.png" alt="fb" class="social_media_btn" /></a>
                <a   target="_blank"href=" https://www.linkedin.com/company/encycg"> <img src="assets/aboutus/footer-white-insta.png" alt="insta" class="social_media_btn" /></a>
                <a  target="_blank" href="https://twitter.com/encyconsulting?lang=en"><img src="assets/aboutus/footer-white-twitter.png" alt="twitter" class="social_media_btn" /></a>
                <span id="divider-line"></span>
                <p class="f-24 fw-500 text-white follow_us_text">Follow Us</p>
            </span>
        </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center p-4 second_part_footer_wrapper f-14 fw-500 text-black">
        Copyright ©
        {{current_year}} ency Consulting | All Rights Reserved | <a style="color:blue;cursor: pointer; text-decoration: none;" target="_blank" [routerLink]="['/cookie-policy']" >Cookie Policy</a> |
       <span style="color:blue;cursor: pointer;"> <a target="_blank" [routerLink]="['/privacy-policy']" class="text-reset no_underline ">Privacy Policy</a> </span> |   <span style="color:blue;cursor: pointer"><a  target="_blank" [routerLink]="['/terms-conditions']" class="text-reset no_underline ">Terms & Conditions</a></span>
   | <a style="color:blue;cursor: pointer; text-decoration: none;" target="_blank" [routerLink]="['/disclaimer']" >Disclaimer</a>
    </div>
    <!-- Copyright -->
</footer>