import { NgModule } from '@angular/core'

import { HomepageComponent } from './pages/homepage/homepage.component'
import { TravehoComponent } from './pages/traveho/traveho.component'
import { LearnanywhereComponent } from './pages/learnanywhere/learnanywhere.component'
import { AboutUsComponent } from './pages/about-us/about-us.component'
import { CareerpageComponent } from './pages/careerpage/careerpage.component'
import { MeetOurTeamComponent } from './pages/meet-our-team/meet-our-team.component'
import { EncyAdvantageComponent } from './pages/ency-advantage/ency-advantage.component'
import { BusinessConsultingComponent } from './pages/business-consulting/business-consulting.component'
import { TechnologyConsultingComponent } from './pages/technology-consulting/technology-consulting.component'
import { ContactUsComponent } from './pages/contact-us/contact-us.component'
import { SoftwareServicesComponent } from 'src/app/pages/software-services/software-services.component'
import { TechStackComponent } from 'src/app/pages/tech-stack/tech-stack.component'
import { DomainSolutionsComponent } from 'src/app/pages/domain-solutions/domain-solutions.component'
import { EngagementModelComponent } from './pages/engagement-model/engagement-model.component'
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component'
import { CookiesComponent } from './pages/cookies/cookies.component'
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component'
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component' 
import { ResourcingComponent } from './pages/resourcing/resourcing.component'
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { EzdeskComponent } from './pages/ezdesk/ezdesk.component'
import { MainProductpageComponent } from './pages/main-productpage/main-productpage.component'
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};
const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    data: {
      title: "Homepage",
      description: "ency is a Technology Consulting and Engineering Group specializing in transforming strategic priorities into continued business benefit.",
      keywords:"ency, ency Consulting, ency Consulting  Inc, business consulting , business consulting , technology consulting , Toronto, digital transformation, digital adoption, bespoke development, staff augmentation, small business consulting services",
      robots:"index, follow"
    }
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
    data: {
      title: "Disclaimer",
      description: "ency is a Technology Consulting and Engineering Group specializing in transforming strategic priorities into continued business benefit.",
      keywords:"ency, ency Consulting, ency Consulting  Inc, business consulting , business consulting , technology consulting , Toronto, digital transformation, digital adoption, bespoke development, staff augmentation, small business consulting services",
      robots:"index, follow"
    }
  },
  {
    path: 'cookie-policy',
    component: CookiesComponent,
    data: {
      title: "Cookie Policy",
      description: "ency is a Technology Consulting and Engineering Group specializing in transforming strategic priorities into continued business benefit.",
      keywords:"ency, ency Consulting, ency Consulting  Inc, business consulting , business consulting , technology consulting , Toronto, digital transformation, digital adoption, bespoke development, staff augmentation, small business consulting services",
      robots:"index, follow"
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: "Privacy Policy",
      description: "ency is a Technology Consulting and Engineering Group specializing in transforming strategic priorities into continued business benefit.",
      keywords:"ency, ency Consulting, ency Consulting  Inc, business consulting , business consulting , technology consulting , Toronto, digital transformation, digital adoption, bespoke development, staff augmentation, small business consulting services",
      robots:"index, follow"
    }
  },
  {
    path: 'terms-conditions',
    component: TermsAndConditionsComponent,
    data: {
      title: "Terms & Conditions",
      description: "ency is a Technology Consulting and Engineering Group specializing in transforming strategic priorities into continued business benefit.",
      keywords:"ency, ency Consulting, ency Consulting  Inc, business consulting , business consulting , technology consulting , Toronto, digital transformation, digital adoption, bespoke development, staff augmentation, small business consulting services",
      robots:"index, follow"
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
    title: "About Us",
    description: "Global presence across 2 Countries and domain expertise in 5 Verticals & Domains. Core values Transparency | Efficiency | Consistency | Consistency",
    keywords:"Mission of ency, Vision of ency, 2020 , services across India, United States, US , Canada, UAE, transform your business, growth year on year, enterprise customers, core values, Efficiency, Transparency, Consistency, Competency",
    robots:"index, follow"
    }
  },
  {
    path: 'career',
    component: CareerpageComponent, 
    data: {
      title: "Careers",
      description: "Certified Professionals across Project and Product Mgmt | AWS & Azure | Microsoft. Multiple Domain Experts. Upskilling with Inhouse & External training",
      keywords:"remote, flexible working hours, leave policy, ",
      robots:"index, follow"
      }
  },
  {
    path: 'meet-our-team',
    component: MeetOurTeamComponent,
    data: {
      title: "Meet Our Team",
      description: "Leadership Experience of 75+ years, Certified Professionals & Experts. Solving Business Problems using Technology & Business Consulting",
      keywords:"leadership at ency, ratan singh, john praveen kumar, architect, project manager, certified experts, Azure, AWS, DevOps, CSM, CSPO, PMP",
      robots:"index, follow"
      }
  },
  {
    path: 'ency-advantage',
    component: EncyAdvantageComponent,
    data: {
      title: "Ency Advantage",
      description: "With Industry expertise and Proven track record, we are Client focused and provide Unique solutions which are Results-driven and meets Business Need",
      keywords:"Why ency, advantages working with ency, ency advantage",
      robots:"index, follow"
      }
  },
  {
    path: 'business-consulting',
    component: BusinessConsultingComponent,
    data: {
      title: "Business Consulting",
      description: "With our Business Consulting services we help our customers with IT Strategy & Consulting, Agile Adoption, P3M Management and Technology Health-Check",
      keywords:"IT Strategy & Consulting, P3M Consulting, Project Management , Agile Adoption, Hiring contract, Technology Health-Check",
      robots:"index, follow"
      }
  },
  {
    path: 'technology-consulting',
    component: TechnologyConsultingComponent,
    data: {
      title: "Technology Consulting",
      description: "With our Technology Consulting services we help our customers with Enterprise Technology Strategy, Digital Transformation and Adopting Emerging Tech",
      keywords:"Enterprise Technology Strategy, Digital Transformation, Technological Development, Enhanced Operations Effeciency, Business Continuity, Scaling IT Infrastructure, Adopting Emerging Tech, Technology Change Management",
      robots:"index, follow"
      }
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: {
      title: "Contact Us",
    }
   
  },
  {
    path: 'software-services',
    component: SoftwareServicesComponent,
    data: {
      title: "Software Services",
      description: "Our Software Services help in building Enterprise software Development(Web & Mobile) with Bespoke Development and Emerging Technologies",
      keywords:"Enterprise software Development, Full stack Development, Web App Development, Mobile App Development, Be Spoke Application, Integration Solutions, Product Development, Cloud Solutions, DevOps Consulting, Technology Upgrades, Software Support Services, Legacy Software Services, Managed Services, Data Science, BI & Data Analytics, Artificial Intelligence, Machine learning, Web3",
      robots:"index, follow"
      }
  },
  {
    path: 'tech-stack',
    component: TechStackComponent,
    data: {
      title: "Tech Stack",
      description: "With our Technology Consulting services we help our customers with Enterprise Technology Strategy, Digital Transformation and Adopting Emerging Tech",
      keywords:"Enterprise Technology Strategy, Digital Transformation, Technological Development, Enhanced Operations Effeciency, Business Continuity, Scaling IT Infrastructure, Adopting Emerging Tech, Technology Change Management",
      robots:"index, follow"
      }
  },
  {
    path: 'domain-solutions',
    component: DomainSolutionsComponent,
    data: {
      title: "Domain Solutions",
      description: "ency has solved business problems across Shipping, Logistics, Healthcare, Data Science, Data Engineering, AI, Machine Learning, EdTech and E-Commerce",
      keywords:"Shipping, Logistics, Healthcare, Data Science, Data Engineering, AI, Machine Learning, EdTech, E-Commerce",
      robots:"index, follow"
      }
  },
  {
    path: 'engagement-model',
    component: EngagementModelComponent,
    data: {
      title: "Engagement Model",
      description: "We offer our clients multiple Engagement module as per their need & operational policy",
      keywords:"Consultation Model , Project Model, Onsite/Offshore Model, Offshore Delivery Centre, Managed Captive Model, Expertise on demand",
      robots:"index, follow"
      }
  },
  {
    path: 'resourcing',
    component: ResourcingComponent,
    data: {
      title: "Resourcing",
      description: "We offer staff augmentation/resourcing/consultants across functional & technical domains globally on full & part-time basis. ",
      keywords:"staff augmentation, contractor, consultant, experts, Sharepoint, India, canada, resourcing, full time, part-time, part time",
      robots:"index, follow"
      }
  },
  {
    path: 'traveho',
    component: TravehoComponent,
    data: {
      title: "Traveho",
      description: "We offer staff augmentation/resourcing/consultants across functional & technical domains globally on full & part-time basis. ",
      keywords:"staff augmentation, contractor, consultant, experts, Sharepoint, India, canada, resourcing, full time, part-time, part time",
      robots:"index, follow"
      }
  },
  {
    path: 'la',
    component: LearnanywhereComponent,
    data: {
      title: "Learn Anywhere",
      description: "We offer staff augmentation/resourcing/consultants across functional & technical domains globally on full & part-time basis. ",
      keywords:"staff augmentation, contractor, consultant, experts, Sharepoint, India, canada, resourcing, full time, part-time, part time",
      robots:"index, follow"
      }
  },
  {
    path: 'ezdesk',
    component: EzdeskComponent,
    data: {
      title: "ezDesk",
      description: "We offer staff augmentation/resourcing/consultants across functional & technical domains globally on full & part-time basis. ",
      keywords:"staff augmentation, contractor, consultant, experts, Sharepoint, India, canada, resourcing, full time, part-time, part time",
      robots:"index, follow"
      }
  },{
    path: 'product',
    component: MainProductpageComponent,
    data: {
      title: "Products",
      description: "We offer staff augmentation/resourcing/consultants across functional & technical domains globally on full & part-time basis. ",
      keywords:"staff augmentation, contractor, consultant, experts, Sharepoint, India, canada, resourcing, full time, part-time, part time",
      robots:"index, follow"
      }
  }
  
  
]

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions),CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
