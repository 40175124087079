<div id="container" class="business-consulting-wrapper">
    <app-navbar class="black-links mobile-nav"></app-navbar>
    <div class="hero-wrapper">
        <app-navbar class=" black-links desktop-nav"></app-navbar>
        <!-- <section class="navbar_wrapper" id="navbar_wrapper_section">
            <nav class="navbar navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <img id="ency_logo" alt="ency logo" src="assets/ency-logo-no-bg.webp" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link active f-18 fw-500" aria-current="page" href="#">About us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Resourcing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Domain</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Engagement model</a>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <button class="btn btn-success contact_us_button" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </nav>
        </section> -->

        <p id="title1">Business</p>
        <p id="title2">Consulting</p>
    </div>

    <div class="we-assist-wrapper">
        <div class="heading-wrapper">
            <div class="inner-wrapper">
                <div class="prefix_style_circle_wrapper">
                    <div class="circle">
                        <div class="inner_circle"></div>
                    </div>
                    <div class="line"></div>
                </div>
                <p id="heading1">Consulting Is More Than Giving Advice </p>
            </div>
            <p id="heading2">We Assist You to Make You Successful</p>
        </div>
        <div class="content-wrapper">
            <div class="left-content">
                <div class="para_start">ency joins your team bringing in deep expertise and best practices you need. We
                    help you build a digital roadmap that prioritizes the right projects and investments along with
                    delivering systematic improvements contributing to operational enhancements.</div>
                <ul>
                    <li>We will identify why strategic projects are not as effective as you want them to be.</li>
                    <li>Our team leads, collaborate, and help deliver initiatives that are most important to the
                        organization.</li>
                    <li>We improve governance and compliance procedures for higher visibility to assist in timely decisions.
                    </li>
                    <li>We provide coaching, management, assessment, processes, products, systems, and training
                        capabilities to help ensure successful projects are repeatable maximizing the value of your IT
                        investment.</li>
                </ul>
            </div>
            <img id="animation" src="assets/business-consulting/we-assist-content-animation.gif" />
        </div>
        <div class="labels-wrapper">
            <div class="inner-wrapper">
                <p class="assist-label" (click)="onLinkBusiness()">Business Process Management </p>
                <p class="assist-label" (click)="onLinkP3M()">P3M Consulting </p>
                <p class="assist-label" (click)="onLinkAgile()">Agile Adoption</p>
                <p class="assist-label" (click)="onLinkDigital()">Digital Adoption</p>
            </div>
            <!-- <div class="inner-wrapper">
                <p class="assist-label">Agile Adoption </p>
                <p class="assist-label">Hiring contract </p>
                <p class="assist-label">Technology Health-Check </p>
            </div> -->
        </div>
    </div>

    <div class="all-timelines-wrapper">
        <div class="timeline1-wrapper">
            <div id="business_managment" class="content1-wrapper Business">
                <div style="width: 5vw;">
                    <div style="width: 2px;
                    height: 42vw;
                    margin-left: 3vw;
                    background-color: orange;position: relative;"></div>
                    <img style="position: absolute;top: -20px;left: 5.4vw;width: 5.5vw;"
                        src="assets/business-consulting/timeline-circle-animation.gif" />
                </div>
                <div class="inner-content Business">
                    <img id="right-image" src="assets/business-consulting/it-strategy-img.png" style="float:right;padding-left: 2vw;"/>
                    <div>
                        <p id="heading">
                            Business Process Management
                        </p>
                        <p id="content">
                            To achieve digital transformation or operation optimization at enterprise scale, team
                            leaders need to reengineer business processes to produce business outcomes in support of a
                            business strategy to improve the quality of customer service and reduce costs.
                            <br>
                            <br>
                            We use methods
                            to discover, model, analyze & measure existing processes and redesign, monitor and optimize
                            them to fit the new goals of the business.
                            Some of the common goals sighted by organizations are:

                        </p>
                        <ul id="content">
                            <li>Simplified Operations</li>
                            <li>Improved Efficiency</li>
                            <li>Lower Costs</li>
                            <li>Reduced Risks</li>
                            <li>Enhanced Consistency</li>
                        </ul>

                    </div>
                </div>

            </div>



            <div id="pm_consulting" class="content2-wrapper P2M ">
                <div style="width: 5vw;position: relative;">
                    <img style="position: absolute;top: -19px;left: 0.4vw;width: 5.5vw;"
                        src="assets/business-consulting/timeline-circle-animation.gif" />
                </div>
                <div class="inner-content">
                    <img id="right-image" src="assets/business-consulting/p3m-img.png" style="float:right;padding-left: 2vw;" />
                    <p id="heading">
                        P3M Consulting
                    </p>
                    <p id="content">
                        The health of a business has become dependent on its ability to successfully execute
                        changes. CEOs and senior management cannot execute on the business strategy without being
                        able to effectively execute on the projects that underpin and enable that strategy.
                        <br> <br>
                        We are highly specialized in professional project, programme, and portfolio management (P3M)
                        services with extensive experience in all aspects of PMLC and SDLC using methodologies like
                        Waterfall, Iterative, Agile, Scrum, Scrumban, Kanban, Hybrid & SAFe.
                        <br> <br>
                        We have rich global experience in formulating, executing, and delivering multi-disciplinary
                        global heterogeneous technology Programs/Projects across North America, Europe &
                        Asia-Pacific.
                        <br> <br>
                        We help organisations build capabilities by developing a governance framework, PMO processes,
                        and tools/templates along with training/upskilling the team ensuring that successful
                        projects are repeatable within the organization.
                    </p>
                </div>
               
            </div>
        </div>
        <img style="margin-left: 25vw;" id="between-timeline-animation1"
            src="assets/business-consulting/timeline-animation.gif" />
        <div class="second-timeline-wrapper" style="margin-bottom: 1vw;padding: 0;">
            <!-- <div class="timeline1-wrapper" style="padding: 0"> -->
            <div id="agile_adoption" class="content1-wrapper Agile" style="padding: 0 4vw;">
             
                <div class="inner-content Business">
                    <img id="right-image" src="assets/business-consulting/business-process-img.png" style="float:left;padding-right: 2vw;" />
                    <!-- <img style="position: absolute;top: -1vw;right: 1.8vw;width: 5.5vw;"
                    src="assets/business-consulting/timeline-circle-animation.gif" /> -->
                   
                    <p id="heading">
                        Agile Adoption
                    </p>
                    <p id="content">
                        Agile adoption is combining team practices and processes with effective product/project
                        management and delivery best practices; and then infusing that with Agile leadership
                        practices to encourage growth, openness and transparency.
                        <br> <br>
                        Whether you are taking the first step down the road to Agile or you want to make sure you’re
                        heading in the right direction, we bring together the latest Agile development principals
                        and practices from real world software teams, experienced professionals and thought leaders.
                        <br> <br>
                        We evaluate your readiness to Agile, build a roadmap with measurable milestones and
                        timeline. Implementation of Agile practices will involve placing the right process & tools,
                        training the team, ongoing coaching and support followed by continuous improvement.

                    </p>
                </div>
                <div style="width: 5vw; position: relative;">
                    <div style="width: 2px;
                        height: 44vw;
                        margin-right: 3vw;
                        background-color: orange;"></div>
               
                    <!-- <img style="position: absolute;bottom: 47vw;right: 16px;width: 5.5vw;" src="assets/business-consulting/timeline-circle-animation.gif" /> -->
                </div>
                <div style="width: 5vw;position: relative;">
                    <img style="width: 5.5vw;
                        position: absolute;
                        top: -23px;
                        right: 7.6vw;"  
                         src="assets/business-consulting/timeline-circle-animation.gif" src="assets/business-consulting/timeline-circle-animation.gif" />
                </div>
            </div>



            <div id="digital_adoption" class="content2-wrapper Digital " style="padding: 0 4vw;">
               
                <div class="inner-content">
                    <img id="right-image" src="assets/business-consulting/agile-img.png" style="float:left;padding-right: 2vw;" />
                    <p id="heading">
                        Digital Adoption
                    </p>
                    <p id="content">
                        A digital adoption challenge arises when a technological change has not been embraced by the
                        users as it seems too complicated, or users are unclear as to its potential benefits. It can
                        result in lack of use or misuse of the enterprise’s software.
                        <br>
                        <br>
                        Organizational culture plays
                        a pivotal role. Ensuring your team is trained on using a specific application, tool,
                        website, or other digital technology and they also have a support system to receive
                        continued support.
                    </p>
                    <ul id="content">
                        <li>We can help your organization to design and create an environment which facilitates your employees with adequate input and support.</li>
                        <!-- <li>Our End-user training helps your staff get the support they want.</li> -->
                        <li>We can produce the required usage statistics essential in understanding user behavior.</li>
                        <li>Our governance solutions lay a solid path to help manage your software.</li>
                        <li>We can help you get new deployments while ensuring old deployments don’t get out of control.</li>
                        <li>We conduct training programs which allow speedy adoption and familiarity with the tools.
                        </li>
                    </ul>
                </div>
                <div style="width: 5vw;position: relative;">
                    <img style="width: 5.5vw;
                        position: absolute;
                        top: -47px;
                        right: 7vw;"
                         src="assets/business-consulting/timeline-circle-animation.gif" />
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" /> -->
    </div>



    <!-- <div class="second-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" />

                <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <div id="agile_adoption" class="content1-wrapper Agile">
                    <img id="right-image" src="assets/business-consulting/business-process-img.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Agile Adoption
                        </p>
                        <p id="content">
                            Agile adoption is combining team practices and processes with effective product/project
                            management and delivery best practices; and then infusing that with agile leadership
                            practices to encourage growth, openness and transparency.

                            Whether you are taking the first step down the road to agile or you want to make sure you’re
                            heading in the right direction, we bring together the latest agile development principals
                            and practices from real world software teams, experienced professionals and thought leaders.
                            We evaluate your reediness to agile, build a roadmap with measurable milestones and
                            timeline. Implementation of Agile practices will involve placing the right process & tools,
                            training the team, ongoing coaching and support followed by continuous improvement.

                        </p>
                    </div>

                </div>
                <div id="digital_adoption" class="content2-wrapper content3-wrapper Digital">
                    <img id="right-image" src="assets/business-consulting/agile-img.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Digital Adoption
                        </p>
                        <p id="content">
                            A digital adoption challenge arises when a technological change has not been embraced by the
                            users as it seems too complicated, or users are unclear as to its potential benefits. It can
                            result into lack of use or misuse of the enterprise software’s.Organizational culture plays
                            a pivotal role. Ensuring your team is trained on using a specific application, tool,
                            website, or other digital technology and they also have a support system to receive
                            continued support.
                          
                        </p>
                        <ul id="content">
                            <li>We can help your organization design to create an environment which facilitates your employees with adequate input support.</li>
                            <li>Our End-user training helps your staff get the support they want.</li>
                            <li>We can produce the required usage statistics essential in understanding user behavior.</li>
                            <li>Our governance solutions lay a correct path for you to manage software’s.</li>
                            <li>We can help you get new deployments while ensuring old deployments don’t get out of control either.</li>
                            <li>We conduct training programs which allow speedy adoption and familiarity with the tools.</li>
                        </ul>

                    </div>

                </div>
            </div>
            <img id="between-timeline-animation2" src="assets/business-consulting/timeline-animation.gif" />
        </div> -->

    <!-- <div class="third-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" />

                <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Technology health check
                        </p>
                        <p id="content">
                            Monitoring Governance and Strategy, IT Operations, Organizational Staffing, and Technology
                            helps
                            the
                            business grow sooner, we at ency help you write and review assessments of these areas to
                            have
                            expected performance
                        </p>
                    </div>
                    <img id="right-image" src="assets/business-consulting/tech-health-img.png" />
                </div>
                <div class="content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Change management
                        </p>
                        <p id="content">
                            The change toward organizational goals, processes, and technology gets easier with
                            our ency team. At ency we help in crafting your change, implementing it, and reviewing and
                            analyzing
                            results
                        </p>
                    </div>
                    <img id="right-image" src="assets/business-consulting/change-manage-img.png" />
                </div>
            </div>
        </div> -->


  
</div>