<div class="engagement-model-main-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>
    <div class="engagement-model-banner-wrapper">
        <app-navbar [isNavImageBlack]="true" class="desktop-nav"></app-navbar>
        <div class="rectangle-and-heading-wrapper">
            <img class="rectangle" src="assets/engagement-model/rectangle-with-text.png" />
        </div>
    </div>
    <div class="our-offering-wrapper">
        <img class="isolation-mode-left-side" src="assets/engagement-model/isolation-mode-left-side.png" />
        <div class="our-offering-title-wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
            </div><span id="our-offering-text">Our Offering</span>
        </div>
        <img class="isolation-mode-right-side" src="assets/engagement-model/isolation-mode-right-side.png" />
        <div class="our-offering-paragraph">Our engagement models are focused on successful partnerships with our
            clients. We offer wide range of models but customize them to fit unique business needs. </div>
        <div class="labels-wrapper">
            <div class="inner-wrapper">
                <div class="assist-label-wrapper" (click)="onLinkConsulation()">
                    <p class="assist-label" >Consultation</p>
                </div>
                <div class="assist-label-wrapper" (click)="onLinkProject()">
                    <p class="assist-label" >Project</p>
                </div>
                <div class="assist-label-wrapper" (click)=" onsiteProject()">
                    <p class="assist-label" >Onsite/Offshore</p>
                </div>
            </div>
            <div class="inner-wrapper">
                <div class="assist-label-wrapper " (click)="offshoreProject()">
                    <p class="assist-label" >Offshore Delivery Centre</p>
                </div>
                <div class="assist-label-wrapper" (click)="Captive()">
                    <p class="assist-label" >Managed Captive </p>
                </div>
                <div class="assist-label-wrapper" (click)="Expertise()">
                    <p class="assist-label" >Expertise On Demand</p>
                </div>
            </div>

        </div>


    </div>
    <div class="models-main-wrapper">
        <div class="model-wrapper consulation">
            <div class="model-left-content">
                <div class="model-heading">Consultation</div>
                <!-- <div class="model-subheading">“Innovative solutions for complex challenges” </div> -->
                <div class="model-content">We help you identify your pain points or needs, i.e. what is the question you
                    are trying to answer? Once identified a consultant(s) with the required expertise will engage with
                    you and your team. Under your guidance they will consult, assess, identify, execute, manage, or
                    train. </div>
                <div class="model-content">Based on your needs the consultant may engage in hourly accounted efforts or
                    a fixed price to execute a particular task. We ensure complete transparency in communication and
                    accountability of results.</div>
                <img class="rotation-animation" src="assets/engagement-model/rotating-animation.gif" />
            </div>
            <img class="model-image" src="assets/engagement-model/consultation-model-image.png" />
            <img class="messy-lines-animation" src="assets/engagement-model/messy-lines-animation.gif" />
        </div>
        <div class="model-wrapper project">
            <img class="messy-lines-animation-second" src="assets/engagement-model/messy-lines-animation.gif" />
            <img class="model-image" src="assets/engagement-model/project-model-image.png" />
            <div class="model-left-content">
                <div class="model-heading">Project</div>
                <div class="model-content">When you have an identified goal to achieve, and you don’t want to get into
                    the nuts and bolts of execution. We can execute the project for you based on your guideline.

                    Project cost will align to your strategy of fixed cost or time and material.</div>
                <div class="model-content">We will build a project team with the required skills and availability. We
                    manage the execution by following Project Management and Software Development processes.

                    Milestone deliveries will be aligned to agreed timelines and quality standards. We establish clear
                    processes along with transparent and effective communication.  </div>
            </div>

        </div>
    </div>
    <div class="models-two-main-wrapper">
        <div class="model-wrapper">
            <div class="model-left-content">
                <div class="model-heading onsite">Onsite/Offshore</div>
                <div class="model-content">We can build a team spread across onshore & offshore to help you take advantage of our expertise without sacrificing control or visibility of your team. It also allows you to distribute work between the teams, streamline your operations, reduce costs, and increase efficiency.</div>
                <div class="model-content">Onsite team works with you and also works with the offshore delivery team filling any communication gaps. You experience personalized onshore services with benefits from offshore delivery rates without dealing with offshoring challenges. </div>
                <img class="rotation-animation" src="assets/engagement-model/rotating-animation.gif" />
            </div>
            <img class="model-image" src="assets/engagement-model/onsite-offshore-model-image.png" />
            <img class="blue-ellipse" src="assets/engagement-model/blue-ellipse.png" />
        </div>
        <div class="model-wrapper">
            <img class="blue-ellipse-second" src="assets/engagement-model/blue-ellipse.png" />
            <img class="model-image" src="assets/engagement-model/offshore-delivery-center-model.png" />
            <div class="model-left-content">
                <div class="model-heading offshore">Offshore Delivery Centre
                    </div>
                <div class="model-content">Our delivery centers provide full software development and engineering services. Under your guidance our delivery managers fully manage your delivery with dedicated teams of resources possessing relevant and pre-identified skill sets. We offer varied team sizes with flexibility to scale up or down.</div>
                <div class="model-content">We follow best practices on Project Management and Software Development along with early adoption to emerging technologies and standards. Along with major cost savings & overlap time we establish clear processes, fostering effective communication, and regularly assessing performance.   </div>
            </div>

        </div>
    </div>
    <div class="models-three-main-wrapper">
        <div class="model-wrapper captive">
            <div class="model-left-content">
                <div class="model-heading">Managed Captive </div>
                <div class="model-content">A dedicated team of resources with relevant and pre-identified skill sets that you have full access to and control. They operate under your processes and procedures to act as your extended arm. Key benefits that captives bring to the table are tight operational control, cultural alignment, and IP protection.
                </div>
                <div class="model-content">Data Protection is aligned to your corporate policy and expectations. With a high degree of concern about data security, privacy, and intellectual property you as a parent company have complete control over how information is handled/stored in the center and the people who have access to it. </div>
                <img class="rotation-animation" src="assets/engagement-model/rotating-animation.gif" />
            </div>
            <img class="model-image" src="assets/engagement-model/consultation-model-image.png" />
            <img class="messy-lines-animation" src="assets/engagement-model/messy-lines-animation.gif" />
        </div>
        <div class="model-wrapper expertise">
            <img class="messy-lines-animation-second" src="assets/engagement-model/messy-lines-animation.gif" />
            <img class="model-image" src="assets/engagement-model/project-model-image.png" />
            <div class="model-left-content">
                <div class="model-heading">Expertise On Demand </div>
                <div class="model-content">On demand a resource with relevant and pre-identified skill will be allocated to you. We support your strategy be it short term or on-going, hourly, or full time, onsite or off site, permanent hire, or contract to hire. With a flexible staffing strategy, we enable you to extend your team with the missing and needed skills.</div>
                <div class="model-content">Your extended team works as part of your in-house team, working your work hours, representing your company, and report directly to your managers. They will operate under your guidance, processes and procedures proving a real competitive advantage for your business. </div>
            </div>

        </div>
    </div>

   
</div>