<div class="traveho-main-container" id="container">
    <app-navbar class="black-links"></app-navbar>
    <div class="traveho_hero_banner">
    </div>
    <div class="rider_features_main_wrapper">
        <div class="rider_features_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="rider_key">Rider Key <span id="riders_featues_text">Features</span></div>
            </div>


        </div>
        <div class="features_row_wrapper">
            <div class="features features-one">
                <div class="features_image">
                    <img class="features_image1" src="assets/traveho/in-app-chat.png" />
                </div>
                <div class="features_content">
                    Keep track of your driver’s real-time location during the trip and easily contact them via call or
                    in-app chat
                </div>

            </div>
            <div class="features ">
                <div class="features_content ">
                    Beautiful and intuitive design for choosing the trip destination
                </div>
                <div class="features_image">
                    <img class="features_image2" src="assets/traveho/man_asking_lift.png" />
                </div>


            </div>
            <div class="features features-three">
                <div class="features_image">
                    <img class="features_image3" src="assets/traveho/girl_announcing.png" />
                </div>
                <div class="features_content">
                    Stay up-to-date with the latest news and updates by checking our announcements section
                </div>

            </div>
            <div class="features features-four">
                <div class="features_content">
                    Take advantage of our coupon system to get discounts on service fees
                </div>
                <div class="features_image">
                    <img class="features_image4" src="assets/traveho/coupon_sytem.png" />
                </div>


            </div>
            <div class="features ">
                <div class="features_image">
                    <img  class="features_image5" src="assets/traveho/four_cars.png" />
                </div>
                <div class="features_content">
                    Choose from a range of services available in your region
                </div>

            </div>
        </div>
        <div class="car_on_road">

        </div>
    </div>
    <div class="driver_features_main_wrapper">
        <div class="driver_features_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="driver_key">Driver Key <span id="drivers_featues_text">Features</span></div>
            </div>


        </div>
        <div class="drivers_features_content_wrapper">

            <div class="two_mobiles_image_container">
                <img class="two_mobiles_image" src="assets/traveho/two_mobile.png" />
            </div>
            <div class="drivers_features_content">
                <div class="drivers_features_with_image drivers_features_with_image1">
                    <div class="drivers_features_image-container"> <img class="drivers_features_image image1"
                            src="assets/traveho/call.png" /></div>
                    <div class="features_content">Stay in touch with the rider via call or in-app chat</div>
                </div>
                <div class="drivers_features_with_image drivers_features_with_image2">
                    <div class="drivers_features_image-container"><img class="drivers_features_image image2"
                            src="assets/traveho/transaction_history.png" /></div>
                    <div class="features_content">Access your transaction history to keep track of your credit</div>
                </div>
                <div class="drivers_features_with_image drivers_features_with_image3">
                    <div class="drivers_features_image-container"><img class="drivers_features_image image3"
                            src="assets/traveho/travel.png" /></div>
                    <div class="features_content">Cancel travel before starting if needed</div>
                </div>
                <div class="drivers_features_with_image drivers_features_with_image4">
                    <div class="drivers_features_image-container"> <img class="drivers_features_image image4"
                            src="assets/traveho/one_car.png" /></div>

                    <div class="features_content">Accept the most suitable request that comes your way</div>
                </div>
                <div class="drivers_features_with_image drivers_features_with_image5">
                    <div class="drivers_features_image-container"> <img class="drivers_features_image image5"
                            src="assets/traveho/4th_feature.png" /></div>
                    <div class="features_content">Easily switch between available and unavailable statuses</div>
                </div>
            </div>
            <div class="dotted_image_container">
                <img class="dotted_image" src="assets/traveho/dotted.png" />
            </div>
        </div>
    </div>
    <div class="admin_features_main_wrapper">
        <!-- <div class="driver_features_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="driver_key">Driver Key <span id="drivers_featues_text">Features</span></div>
            </div>


        </div> -->

        <div class="admin_features_content_wrapper">

            <div class="admin_features_content">
                <div class="admin_features_heading_wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                        <div id="driver_key">Admin Key <span id="drivers_featues_text">Features</span></div>
                    </div>
        
        
                </div>
                <div class="admin_features_with_images_container">
                <div class="admin_features_with_image admin_features_with_image1">
                    <div class="admin_features_image-container"> <img class="admin_features_image image1"
                            src="assets/traveho/admin_feature1.png" /></div>
                    <div class="features_content">Get an overview of your service’s current state, including the number of online drivers and relevant statistics</div>
                </div>
                <div class="admin_features_with_image admin_features_with_image2">
                    <div class="admin_features_image-container"><img class="admin_features_image image2"
                            src="assets/traveho/admin_feature2.png" /></div>
                    <div class="features_content">View and search through all service requests in one place</div>
                </div>
                <div class="admin_features_with_image admin_features_with_image3">
                    <div class="admin_features_image-container"><img class="admin_features_image image3"
                            src="assets/traveho/admin_feature3.png" /></div>
                    <div class="features_content">Define payment gateways for users to pay for trips and top up their wallets</div>
                </div>
                <div class="admin_features_with_image admin_features_with_image4">
                    <div class="admin_features_image-container"> <img class="admin_features_image image4"
                            src="assets/traveho/admin_feature4.png" /></div>

                    <div class="features_content">Handle complaints made by drivers or riders during their trips through the app</div>
                </div>
                <div class="admin_features_with_image admin_features_with_image5">
                    <div class="admin_features_image-container"> <img class="admin_features_image image5"
                            src="assets/traveho/admin_feature5.png" /></div>
                    <div class="features_content">Manage and view detailed driver/rider activities and records</div>
                </div>
            </div>
            </div>
            <div class="two_mobiles_image_container">
                <img class="two_mobiles_image" src="assets/traveho/admin_panel.png" />
            </div>

        </div>
    </div>
    <div class="multi_language_main_wrapper">
        <img class="horizontal_dots" src="assets/traveho/horizontal_dots.png"/>
        <img class="horizontal_dots2" src="assets/traveho/horizontal_dots.png"/>
        <div class="multi_language_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="multi_language_key"><span id="multi_language_text">Multi</span> Language </div>
            </div>


        </div>
        <!-- <div class="multi_language_image_wrapper">
            <img class="multi_language_image" src="assets/traveho/multilanguage_image.png"/>
        </div> -->
    </div>
    <div class="payment_methods_main_wrapper">
        <div class="payment_methods_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="payment_methods_key"><span id="payment_methods_text">Payment</span> Methods</div>
            </div>


        </div>
        <div class="payment_methods_content_wrapper">
            <img class="payment_methods_image1" src="assets/traveho/payment_methods_image1.png"/>
            <img  class="payment_methods_image2" src="assets/traveho/payment_methods_image2.png"/>
        </div>
    </div>
    <div class="Drivers_application_demo_main_wrapper">
        <div  [routerLink]="['/contact-us']" class="redirection1"></div>
        <div class="Drivers_application_demo_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="Drivers_application_demo_key">
                    Driver Application <span id="Drivers_application_demo_text">Demo</span> </div>
            </div>


        </div>
        <div class="Drivers_application_demo_content">
            <div class ="dotted_container"><img src="assets/traveho/dotted.png" class="vertical_dotted"/></div>
            <!-- <div class="Drivers_application_demo_image_container">
                <img class="Drivers_application_demo_image" src="assets/traveho/drivers_application_demo_image.png"/>
            </div> -->
        </div>
    </div>
    <div class="rider_application_demo_main_wrapper">
        <div  [routerLink]="['/contact-us']" class="redirection2"></div>
        <img  class="rider_application_demo_image_container" src="assets/traveho/rider_left_image.png"/>
        <div class="rider_application_demo_right_container">
            <div class="rider_application_demo_heading_wrapper">
                <div class="prefix_style_circle_wrapper">
                    <div class="circle">
                        <div class="inner_circle"></div>
                    </div>
                    <div class="line"></div>
                    <div id="rider_application_demo_key">
                        Rider Application <span id="rider_application_demo_text">Demo</span> </div>
                </div>
    
    
            </div>
            <img class="rider_right_image" src="assets/traveho/rider_right_image_copy.png"/>
        </div>
    </div>
    <div class="available_main_container">
        <div  [routerLink]="['/contact-us']" class="redirection3"></div>
        <div class="available_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="available_key">
                    Available on<span id="available_text"> Multiple Platforms</span> </div>
            </div>


        </div>
        <div class="available_image_wrapper">
            <img class="available_image" src="assets/traveho/available.png"/>
          <img class="googlepay" src="assets/ezdesk/googlepay.png"/>
          <img class="appstore" src="assets/ezdesk/appstore.png"/>
        </div>
    </div>
    <div class="screen_main_container">
        <div class="screen_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="screen_key">
                    <span id="screen_text"> Screens</span> </div>
            </div>


        </div>
        <div class="screen_image_wrapper">
            <img class="screen_image" src="assets/traveho/screens.png"/>
        </div>
    </div>
</div> 