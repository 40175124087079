<div id="container" class="about-us-page-wrapper">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav" id="mobile-navs"></app-navbar>
    <div class="about-us-content-wrapper">
        <!-- <section class="navbar_wrapper" id="navbar_wrapper_section">
            <nav class="navbar navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <img id="ency_logo" alt="ency logo" src="assets/ency-logo-no-bg.webp" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link active f-18 fw-500" aria-current="page" href="#">About us</a>
                            </li>
                            <li (hover)="servicesHovered()" class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Resourcing</a>
                            </li>
                            <li class="nav-item">ency
                                <a class="nav-link f-18 fw-500" href="#">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Domain</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Engagement model</a>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <button class="btn btn-success contact_us_button" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </nav>
        </section> -->
        <app-navbar [isNavImageBlack]="true" class="desktop-nav" id="desktop-navs"></app-navbar>
        <div class="banner-wrapper">
            <img id="banner-triangle-1" src="assets/aboutus/banner-triangle-1.png" />
            <img id="banner-triangle-2" src="assets/aboutus/Isolation_Mode_2.png" />
            <img id="banner-triangle-3" src="assets/aboutus/Isolation_Mode_3.png" />
            <div class="text-wrapper">
                <p id="one">Transform your</p>
                <p><span id="one">Business </span> <span id="two"> into</span></p>
                <p id="two">Long Lasting</p>
                <p id="two">Success with </p>
                <p id="three"><img id="ency_logo" alt="ency logo" src="assets/ency_ca_logo.png" /> <span
                        id="box"></span> <span id="box"></span> <span id="box"></span> <span id="box"></span></p>
            </div>
        </div>


    </div>

    <div class="overview-wrapper">
        <div class="upper-content-wrapper">
            <div>
                <div class="left-content">
                    <div class="overview-title-wrapper" style="position: relative;">
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line"></div>
                        </div>
                        <span id="overview-text">Overview</span>
                        <img id="content-animation-2" src="assets/aboutus/content-animation-2.gif" style="position: absolute; top: -2vw; right: 15vw; width: 20vw;
                        z-index: -1;" />
                    </div>
                    <div class="about-ency-wrapper">
                        <p id="about-ency-text">about ency</p>
                    </div>
                    <div class="para-content-wrapper">
                        <div id="para-content">
                            <img src="assets/aboutus/content-image-combo.png"
                                style="float: right; width: 45vw; height: 21vw;padding-left: 2vw;" />
                            ency is a Technology Consulting and Engineering Group specializing in transforming strategic
                            priorities into continued business benefit by maximizing technical opportunities and
                            improving your digital capabilities.
                            <br>
                            <br>

                            ency is an ISO/IEC 27001:2022 certified company and offers a full set of capabilities in
                            consulting, advisory, resourcing and technology services to help you address a pain point or
                            have end-to-end transformation resolving strategic issues and fully aligning to your core
                            business objectives while driving down operational costs.
                            <br>
                            <br>

                            If you are looking to build a technology roadmap or want to develop Web/Mobile applications,
                            Data science or AI/ML solutions our technology-led services can help you solve your toughest
                            problems by taking full advantage of new and emerging technologies. Integration and
                            modernization of legacy systems can help leverage your existing technology investments.
                            <br>
                            <br>
                            Headquartered in Toronto, Canada with operations in India and global reach we deliver
                            in-person service with the benefits of offshore rates. We offer tailored and personalized
                            engagements ranging at multiple levels.
                            <br>
                            <br>
                            <div class="bullet-point-wrapper">
                                <div class="inner-bullet">
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Advisory</div>
                                    </div>
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Consultation</div>
                                    </div>
                                </div>
                                <div class="inner-bullet">
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Projects</div>
                                    </div>
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Hybrid</div>
                                    </div>
                                </div>

                                <div class="inner-bullet">
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Onsite/Offshore</div>
                                    </div>
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Delivery Centre</div>
                                    </div>
                                </div>
                                <div class="inner-bullet">
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Managed Captive</div>
                                    </div>
                                    <div class="bullet-points">
                                        <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                                        <div class="bullet-points-text">Expertise on demand</div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

                <!-- <div class="right-content col-lg-6">
                    <img id="content-image-1" src="assets/aboutus/content-image-1.jpeg" />
                    <img id="content-image-2" src="assets/aboutus/content-image-2.jpeg" />
                    <img id="content-animation-2" src="assets/aboutus/content-animation-2.gif" />
                </div> -->
            </div>
        </div>
        <br id="break-line" />
        <!-- <div class="images-wrapper">
            <img id="yrs-image" src="assets/aboutus/75yrs.png" />
            <img id="content-animation" src="assets/aboutus/content-animation.gif" />
        </div> -->
        <div class="counter-wrapper">
            <div class="inner-wrapper">
                <p id="number" class="statistics-animation-counter" data-target="50">0</p>
                <p id="label">Years of experience</p>
            </div>
            <div class="inner-wrapper">
                <p id="number"><span class="statistics-animation-counter" data-target="200">0</span>%</p>
                <p id="label">Year on year growth</p>
            </div>
            <div class="inner-wrapper">
                <p id="number" class="statistics-animation-counter" data-target="10">0</p>
                <p id="label">Enterprise customers</p>
            </div>
            <div class="inner-wrapper">
                <p id="number" class="statistics-animation-counter" data-target="4">0</p>
                <p id="label">Global Clients</p>
            </div>
            <div class="inner-wrapper">
                <p id="number" class="statistics-animation-counter" data-target="19">0</p>
                <p id="label">Technology Domains</p>
            </div>
        </div>
    </div>

    <div class="vision-mission-wrapper">
        <img id="isolation-background-1" src="assets/aboutus/Isolation_Mode.png" />
        <img id="isolation-background-2" src="assets/aboutus/Isolation_Mode_2.png" />
        <div class="upper-content">
            <img id="vision-image" src="assets/aboutus/vision-image.png" />
            <div class="content-wrapper">
                <div class="title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <p id="title-text">Vision</p>

                </div>
                <p id="content-text">Our vision is to become the go to partner of choice</p>
            </div>
            <img id="vision-background" src="assets/aboutus/vision.png" />
        </div>
        <br /><br />
        <div class="animation-wrapper">
            <img src="assets/aboutus/vision-mission-animation.gif" />
        </div>
        <div class="lower-content">
            <div class="content-wrapper">
                <div class="title-wrapper">
                    <div class="prefix_style_circle_wrapper">
                        <div class="circle">
                            <div class="inner_circle"></div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <p id="title-text">Mission</p>

                </div>
                <p id="content-text">ency’s mission is to contribute to the success of our clients’ key initiatives by
                    applying business and technical expertise with accountability</p>
            </div>
            <img id="mission-image" src="assets/aboutus/mission-image.png" />
        </div>
        <img id="mission-background" src="assets/aboutus/Mision.png" />
    </div>

    <div class="core-values-wrapper">
        <div class="core-values-title">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
            </div>
            <p id="title-text">Core Values</p>
        </div>
        <div class="core-values-body">
            <img id="base-image" src="assets/aboutus/core-values-base.png" />
            <img id="circle-animation" src="assets/aboutus/core-values-animation-circle.gif" />
            <img id="background-design" src="assets/aboutus/core-values-background.png" />

        </div>
        <img id="box-anim-1" src="assets/aboutus/core-values-box-animation.gif" />
        <img id="box-anim-2" src="assets/aboutus/core-values-box-animation.gif" />
    </div>


    <div class="discover_stories_wrapper">
        <div class="title-wrapper">
            <p id="sub-title">Discover the stories that
            </p>
            <p id="main-title">Shaped our World</p>
            <!-- <br /> -->
            <p id="title-caption">Our company has a rich and diverse history and encompassing a wide range of milestones
                and achievements. Here is a brief overview of our history</p>
            <img id="rect1" src="assets/aboutus/stories_rect_1.png" />
            <img id="rect2" src="assets/aboutus/stories_rect_2.png" />
            <img id="celebration" src="assets/aboutus/story-celebration-animation.gif" />
        </div>
        <br /><br />
        <div class="content-wrapper">
            <img class="timeline-image tab-pane fade active show" id="v-pills-home" role="tabpanel"
                aria-labelledby="v-pills-home-tab" src="assets/aboutus/timeline-2020.png" />

            <img class="timeline-image tab-pane fade" id="v-pills-home2" role="tabpanel"
                aria-labelledby="v-pills-home2-tab" src="assets/aboutus/timeline-2021.png" />

            <img class="timeline-image tl-2022 tab-pane fade" id="v-pills-home3" role="tabpanel"
                aria-labelledby="v-pills-home3-tab" src="assets/aboutus/timeline-2022.png" />

            <img id="dummy-image" src="assets/aboutus/timeline-2020.png" />

        </div>
        <div id="v-pills-tab" role="tablist" class="timeline-left-years-wrapper">
            <div class="inner-circles-wrapper">
                <div class="circle-2020 active" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                    role="tab" aria-controls="v-pills-home" aria-selected="true" id="v-pills-home-tab"
                    (click)="clearTimelineAutoInterval()">2020</div>
                <div class="circle-2021" data-bs-toggle="pill" data-bs-target="#v-pills-home2" type="button" role="tab"
                    aria-controls="v-pills-home2" aria-selected="true" id="v-pills-home2-tab"
                    (click)="clearTimelineAutoInterval()">2021</div>
                <div class="circle-2022" data-bs-toggle="pill" data-bs-target="#v-pills-home3" type="button" role="tab"
                    aria-controls="v-pills-home3" aria-selected="true" id="v-pills-home3-tab"
                    (click)="clearTimelineAutoInterval()">2022</div>
            </div>
            <!-- <h1 class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">Hello</h1>
            <h1 class="tab-pane fade" id="v-pills-home2" role="tabpanel" aria-labelledby="v-pills-home2-tab">Hello2</h1> -->
        </div>
        <div class="right-content-wrapper">
            <div class="inner-wrapper">
                <img id="right-circle-anim" src="assets/aboutus/story-right-circle-anim.gif" />
                <img id="matrix-anim-1" src="assets/aboutus/story-right-matrix-anim.gif" />
                <img id="matrix-anim-2" src="assets/aboutus/story-right-matrix-anim.gif" />
            </div>
        </div>

    </div>


    <!-- Footer -->
    <!-- <app-footer></app-footer> -->
    <!-- Footer -->
</div>