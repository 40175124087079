<router-outlet></router-outlet>

<div id="cookie_consent_wrapper" class="cookie-consent-banner">
    <div class="cookie-consent-banner__inner">
        <div class="cookie-consent-banner__copy">
            <div class="cookie-consent-banner__header">THIS WEBSITE USES COOKIES</div>
            <div class="cookie-consent-banner__description">We use cookies to personalise content and ads, to
                provide social media features and to analyse our traffic. We also share information about your use
                of our site with our social media, advertising and analytics partners who may combine it with other
                information that you’ve provided to them or that they’ve collected from your use of their services.
                You consent to our cookies if you continue to use our website.</div>
        </div>

        <div class="cookie-consent-banner__actions">
            <span id="accept_cookie_button" class="style_cursor_pointer cookie-consent-banner__cta">
                OK
            </span>

            <span id="decline_cookie_button"
                class="style_cursor_pointer cookie-consent-banner__cta cookie-consent-banner__cta--secondary">
                Decline
                </span>
        </div>
    </div>
</div>
<app-footer></app-footer>
