import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resourcing',
  templateUrl: './resourcing.component.html',
  styleUrls: ['./resourcing.component.scss']
})
export class ResourcingComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor() { }

  ngOnInit(): void {
    this.scrollToTop()
  }
  
  scrollToTop(){
    const element = document.querySelector('.resourcing-main-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
 
  onLinkManaged() {
    const element = document.querySelector('.timeline-content-inner-wrapper-one')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onLinkDelivery() {
    const element = document.querySelector('.timeline-content-inner-wrapper-two')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onLinkHiring() {
    const element = document.querySelector('.timeline-content-inner-wrapper-three')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }

}
