import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core'

@Component({
  selector: 'app-careerpage',
  templateUrl: './careerpage.component.html',
  styleUrls: ['./careerpage.component.scss']
})
export class CareerpageComponent implements OnInit, AfterViewInit {

  current_year = new Date().getFullYear()
  pageLoading = true
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
   
    this.scrollToTop()
  }


  ngAfterViewInit() {
     setTimeout(() => {
      this.pageLoading = false;
       this.cdr.detectChanges();
    }, 2000)
    this.cdr.detectChanges();
    this.scrollToTop();
  }

  scrollToTop(){
    const element = document.querySelector('.main-career-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
}
}
