<div class="main-privacy-policy" id="container">
    <app-navbar class="black-links"></app-navbar>
    <div class="inner_privacy_policy">
        <h1>PRIVACY POLICY</h1>
        <div>We respect your privacy and are committed to protecting it through our compliance with this privacy policy
            (“Policy”). This Policy describes the types of information we may collect from you or that you may provide
            (“Personal Information”) on the <a href="https://www.encyconsulting.ca/"> encyconsulting.ca</a> website
            (“Website” or “Service”), any of its related products
            and
            services (collectively, “Services”), engage with us in other related ways, including any sales, marketing,
            or
            events
            and our practices for collecting, using, storing, maintaining, protecting, share, process and disclosing
            that
            Personal Information. It also describes the choices available to you regarding our use of your Personal
            Information
            and how you can access and update it.
            <br>
            <br>
            This Policy is a legally binding agreement between you (“User”, “you” or “your”) and ency Consulting Inc
            (“ency
            Consulting Inc”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or
            other
            legal entity, you represent that you have the authority to bind such entity to this agreement, in which case
            the
            terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do
            not
            agree
            with the terms of this agreement, you must not accept this agreement and may not access and use the Website
            and
            Services. By accessing and using the Website and Services, you acknowledge that you have read, understood,
            and
            agree
            to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do
            not
            own
            or control, or to individuals that we do not employ or manage. If you still have any questions or concerns,
            please
            contact us at <a class="mail" href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a>
            <br>
            <br>
            <h4>Relevant legislation</h4>
            <p>Alongside our business and internal computer systems, this website is designed to comply
                with
                the following national and international legislation with regards to data protection and user privacy:
            </p>
            <ul>

                ­ <li>EU General Data Protection Regulation (GDPR) of 2018</li>

                ­ <li>UK General Data Protection Regulation (GDPR) of 2016</li>

                ­ <li>USA: California Consumer Privacy Act (CCPA) of 2018</li>

                ­ <li>USA: Colorado Privacy Act (CPA) of 2021</li>

                ­ <li>USA: Connecticut Data Privacy Act (CTDPA) of 2023</li>

                ­ <li>USA: Virginia Consumer Data Protection Act (VCDPA) of 2021</li>

                ­ <li>Canada: Personal Information Protection and Electronic Documents Act (PIPEDA) of 2019</li>

                ­ <li>Australia: Privacy Act of 1988</li>

                ­ <li>New Zealand: Privacy Act of 2020</li>
            </ul>


            This site’s compliance with the above legislation, all elements of which are stringent in nature, means that
            this
            site is likely compliant with the data protection and user privacy legislation set out by many other
            countries
            and
            territories as well.
        </div>
        <div class="index">
            <h4 style="margin-top:1vw ;color:blue">Contents</h4>
            <div class="inner-index-wrapper">
                <div class="two">
                    <p>Personal information you disclose.</p>

                </div>
                <div class="two">
                    <p>Automatic collection of information.</p>
                    <!-- <p>............................................................</p>
                <p>3</p> -->
                </div>
                <div class="two">
                    <p>Privacy of
                        children.</p>
                    <!-- <p>............................................................</p>
                <p>3</p> -->
                </div>
                <div class="two">
                    <p> Use and processing of collected
                        information</p>
                    <!-- <p>............................................................</p>
                <p>3</p> -->
                </div>
                <div class="two">
                    <p> Legal bases
                        to process your information</p>
                    <!-- <p>............................................................</p>
                <p>4</p> -->
                </div>
                <div class="two">
                    <p>
                        Disclosure of
                        information</p>
                    <!-- <p>............................................................</p>
                <p>4</p> -->
                </div>
                <div class="two">
                    <p>
                        International Data Transfer</p>
                    <!-- <p>............................................................</p>
                <p>6</p> -->
                </div>
                <div class="two">
                    <p>
                        Retention of information</p>
                    <!-- <p>............................................................</p>
                <p>7</p> -->
                </div>
                <div class="two">
                    <p>
                        Retention of information</p>
                    <!-- <p>............................................................</p>
                <p>7</p> -->
                </div>
                <div class="two">
                    <p>
                        Cookies</p>
                    <!-- <p>............................................................</p>
                <p>7</p> -->
                </div>
                <div class="two">
                    <p>
                        Do Not Track signals</p>
                    <!-- <p>............................................................</p>
                <p>8</p> -->
                </div>
                <div class="two">
                    <p>
                        Links to other resources</p>
                    <!-- <p>............................................................</p>
                <p>8</p> -->
                </div>
                <div class="two">
                    <p>
                        Information security</p>
                    <!-- <p>............................................................</p>
                <p>8</p> -->
                </div>
                <div class="two">
                    <p>
                        Privacy Rights</p>
                    <!-- <p>............................................................</p>
                <p>9</p> -->
                </div>
                <div class="two">
                    <p>
                        California residents specific privacy rights</p>
                    <!-- <p>............................................................</p>
                <p>10</p> -->
                </div>
                <div class="two">
                    <p>
                        Virginia residents specific privacy rights</p>
                    <!-- <p>............................................................</p>
                <p>14</p> -->
                </div>
                <div class="two">
                    <p>
                        Data breach</p>
                    <!-- <p>............................................................</p>
                <p>16</p> -->
                </div>
                <div class="two">
                    <p>
                        Changes and amendments</p>
                    <!-- <p>............................................................</p>
                <p>16</p> -->
                </div>
                <div class="two">
                    <p>
                        Acceptance of this policy</p>
                    <!-- <p>............................................................</p>
                <p>16</p> -->
                </div>
                <div class="two">
                    <p>
                        Maintain your personal information and consent</p>
                    <!-- <p>............................................................</p>
                <p>16</p> -->
                </div>
                <div class="two">
                    <p>
                        Contact us</p>
                    <!-- <p>............................................................</p>
                <p>17</p> -->
                </div>
            </div>
        </div>
        <br>
        <br>
        <h2>Personal information you disclose</h2>

        <p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining
            information about us or our products and Services, when you participate in activities on the Services, or
            otherwise when you contact us. The personal information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the products and features you use. The
            personal
            information we collect may include first and last name, email address, postal or billing address, country,
            job
            titles, employer, phone number and other similar contact data. For employment we may also collect education,
            work experience and certification details. We do not process sensitive information</p>
        <br>
        <br>
        <h2>Privacy of children</h2>
        <p>We do not knowingly collect any Personal Information from children under the age of 18. If you are under the
            age
            of 18, please do not submit any Personal Information through the Website and Services. If you have reason to
            believe that a child under the age of 18 has provided Personal Information to us through the Website and
            Services, please contact us to request that we delete that child’s Personal Information from our Services.
        </p>
        <br>
        <br>
        <h2>Use and processing of collected information</h2>
        <p>We process your information to provide, improve, and administer our Services, communicate with you, for
            security
            and fraud prevention, and to comply with law. We may also process your information for other purposes with
            your
            consent.</p>

        <p>We may process your information for a variety of reasons, depending on how you interact with our Services,
            including:</p>
        <ul>
            <li><span style="font-weight: 600;">To run and operate the Website and Services</span>. We use this
                information
                to measure the site usage,
                improve content and to ensure safety and security as well enhance performance.</li>
            <li><span style="font-weight: 600;">To deliver and facilitate delivery of services to the user.</span> We
                may
                process your information to provide you
                with the requested service.</li>
            <li><span style="font-weight: 600;">To respond to user inquiries/offer support to users. </span>We may
                process
                your information to respond to your
                inquiries and solve any potential issues you might have with the requested service</li>
            <li><span style="font-weight: 600;">To send administrative information to you.</span> We may process your
                information to send you details about our
                products and services, changes to our terms and policies, and other similar information.</li>
            <li><span style="font-weight: 600;">To fulfill and manage your orders.</span> We may process your
                information to
                fulfill and manage your orders,
                payments, returns, and exchanges made through the Services.</li>
            <li><span style="font-weight: 600;">To request feedback. </span>We may process your information when
                necessary
                to request feedback and to contact you
                about your use of our Services.</li>
            <li><span style="font-weight: 600;">To send you marketing and promotional communications.</span> We may
                process
                the personal information you send to us
                for our marketing purposes if this is in accordance with your marketing preferences. You can opt out of
                our
                marketing emails at any time.</li>
            <li><span style="font-weight: 600;">To protect our Services.</span> We may process your information as part
                of
                our efforts to keep our Services safe
                and secure, including fraud monitoring and prevention.</li>
            <li><span style="font-weight: 600;">To identify usage trends.</span> We may process information about how
                you
                use our Services to better understand how
                they are being used so we can improve them.</li>
            <li><span style="font-weight: 600;">To determine the effectiveness of our marketing and promotional
                    campaigns.</span> We may process your information
                to better understand how to provide marketing and promotional campaigns that are most relevant to you.
            </li>
            <li><span style="font-weight: 600;">To save or protect an individual's vital interest.</span> We may process
                your information when necessary to save or
                protect an individual’s vital interest, such as to prevent harm.</li>
        </ul>
        <br>
        <br>
        <p>Note that under some legislations we may be allowed to process information until you object to such
            processing by
            opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy
            to
            clarify the specific legal basis that applies to the processing, and in particular whether the provision of
            Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a
            contract.</p>
        <br>
        <h3>Legal bases to process your information</h3>
        <p style="text-decoration: underline;">If you are located in the EU or UK, this section applies to you.</p>
        <br>
        <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely
            on
            in order to process your personal information. As such, we may rely on the following legal bases to process
            your
            personal information:</p>
        <ul>
            <li><span style="font-weight: 600;">Consent</span>. We may process your information if you have given us
                permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw
                your
                consent at any time. Learn more about in our section below “Maintain your personal information and
                consent”.
            </li>
            <li><span style="font-weight: 600;">Performance of a Contract.</span> We may process your personal
                information
                when we believe it is necessary to fulfill our contractual obligations to you, including providing our
                Services or at your request prior to entering into a contract with you.</li>
            <li><span style="font-weight: 600;">Legitimate Interests.</span>We may process your information when we
                believe
                it is reasonably necessary to achieve our legitimate business interests and those interests do not
                outweigh
                your

                interests and fundamental rights and freedoms. For example, we may process your personal information for
                some of the purposes described in order to:
                <ul>
                    <li>Send users information about special offers and discounts on our products and services</li>
                    <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
                    <li>Support our marketing activities</li>
                    <li>Diagnose problems and/or prevent fraudulent activities</li>
                    <li>Understand how our users use our products and services so we can improve user experience</li>
                </ul>
            </li>
            <li><span style="font-weight: 600;">Legal Obligation.</span> We may process your information where we
                believe it
                is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body
                or
                regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in
                litigation in which we are involved.</li>
            <li><span style="font-weight: 600;">Vital Interests.</span>We may process your information where we believe
                it
                is necessary to protect your vital interests or the vital interests of a third party, such as situations
                involving potential threats to the safety of any person.</li>

        </ul>
        <br>
        <br>
        <p style="text-decoration: underline;">If you are located in Canada, this section applies to you.</p>
        <p>We may process your information if you have given us specific permission (i.e., express consent) to use your
            personal information for a specific purpose, or in situations where your permission can be inferred (i.e.,
            implied consent). You can withdraw your consent at any time. Learn more about in our section below “Maintain
            your personal information and consent”.</p>
        <br>
        <p>In some exceptional cases, we may be legally permitted under applicable law to process your information
            without
            your consent, including, for example:</p>
        <ul>
            <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely
                way
            </li>
            <li>For investigations and fraud detection and prevention</li>
            <li>For business transactions provided certain conditions are met</li>
            <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle
                an
                insurance claim</li>
            <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
            <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
            </li>
            <li>If it is reasonable to expect collection and use with consent would compromise the availability or the
                accuracy of the information and the collection is reasonable for purposes related to investigating a
                breach
                of an agreement or a contravention of the laws of Canada or a province</li>
            <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                relating to
                the production of records</li>
            <li>If it was produced by an individual in the course of their employment, business, or profession and the
                collection is consistent with the purposes for which the information was produced</li>
            <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
            <li>If the information is publicly available and is specified by the regulations</li>

        </ul>
        <br>
        <h3>Disclosure of information</h3>
        <br>
        <p>Depending on the requested Services or as necessary to complete any transaction or provide any Service you
            have
            requested, we may share your information with our affiliates, contracted companies, affiliates, business
            partners, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation
            of
            the Website and Services available to you. We have contracts in place with our third parties, which are
            designed
            to help safeguard your personal information. We will not share any information with unaffiliated third
            parties.
        </p>
        <br>
        <p>Service Providers are not authorized to use or disclose your information except as necessary to perform
            services
            on our behalf or comply with legal requirements. Service Providers are given the information they need only
            in
            order to perform their designated functions, and we do not authorize them to use or disclose any of the
            provided
            information for their own marketing or other purposes. We will share and disclose your information only with
            the
            following categories of Service Providers:</p>
        <ul>
            <li>Communication and collaboration services</li>
            <li>Data analytics services</li>
            <li>Data storage services</li>
            <li>Performance monitoring services</li>
            <li>Product engineering and design services</li>
            <li>Website hosting service providers</li>
            <li>Government Entities</li>
            <li>Finance & Accounting Tools</li>
            <li>Sales & Marketing Tools</li>
        </ul>
        <br>
        <p>We may also disclose any Personal Information we collect, use, or receive if required or permitted by law,
            such
            as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is
            necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond
            to a
            government request.</p>
        <br>
        <h3>International data transfer</h3>
        <p>Our servers are located in Canada. If you are accessing our Services from outside Canada, please be aware
            that
            your information may be transferred to, stored, and processed by us in our facilities and by those third
            parties
            with whom we may share your personal information (see " Disclosure of information" above), in Canada, India,
            United States, and other countries.</p>
        <br>
        <p>If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may
            not
            necessarily have data protection laws or other similar laws as comprehensive as those in your country.
            However,
            we will take all necessary measures to protect your personal information in accordance with this privacy
            notice
            and applicable law.</p>
        <br>
        <p>European Commission's Standard Contractual Clauses:</p>
        <br>
        <p>We have implemented measures to protect your personal information, including by using the European
            Commission's
            Standard Contractual Clauses for transfers of personal information between our group companies and between
            us
            and our third-party providers. These clauses require all recipients to protect all personal information that
            they process originating from the EEA or UK in accordance with European data protection laws and
            regulations.
            Our Data Processing Agreements that include Standard Contractual Clauses are available. We have implemented
            similar appropriate safeguards with our third-party service providers and partners and further details can
            be
            provided upon request.</p>

        <br>
        <h3>Retention of information</h3>
        <p>We will retain and use your Personal Information for the period necessary to comply with our legal
            obligations,
            to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted
            by
            law.</p>
        <br>
        <p>We may use any aggregated data derived from or incorporating your Personal Information after you update or
            delete
            it, but not in a manner that would identify you personally. Once the retention period expires, Personal
            Information shall be deleted. Therefore, the right to access, the right to erasure, the right to
            rectification,
            and the right to data portability cannot be enforced after the expiration of the retention period.</p>
        <br>
        <h3>Cookies</h3>
        <p>Our Website and Services use “cookies” to help personalize your online experience. A cookie is a text file
            that
            is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses
            to
            your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that
            issued the cookie to you.
            <br><br>
            We may use cookies to collect, store, and track information for security and personalization, and for
            statistical purposes. We use cookies and similar technologies for several purposes, which may include:
        </p>
        <li><span style="font-weight: 600;">Storing your Preferences and Settings –</span>Settings that enable our
            website
            to operate correctly or that maintain your preferences over time may be stored on your device.</li>
        <li><span style="font-weight: 600;">Security –</span>We use cookies to detect fraud and abuse of our websites
            and
            services.
        </li>
        <li><span style="font-weight: 600;">Data analytics –</span>Our Website and Services may use third-party
            analytics
            tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard
            internet activity and usage information. The information gathered is used to compile statistical reports on
            User
            activity such as how often Users visit our Website and Services, what pages they visit and for how long,
            etc. We
            use the information obtained from these analytics tools to monitor the performance and improve our Website
            and
            Services.</li>
        <br>
        <p>Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept
            cookies by default, but you can modify your browser settings to decline cookies if you prefer.</p>
        <br>

        <h3>Do Not Track signals</h3>
        <p>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to
            have
            your online activity tracked. Tracking is not the same as using or collecting information in connection with
            a
            website. For these purposes, tracking refers to collecting personally identifiable information from
            consumers
            who use or visit a website or online service as they move across different websites over time. How browsers
            communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet
            set up
            to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more
            detail throughout this Policy, we limit our use and collection of your Personal Information.</p>
        <br>
        <h3>Links to other resources</h3>
        <p>The Website and Services contain links to other resources that are not owned or controlled by us. Please be
            aware
            that we are not responsible for the privacy practices of such other resources or third parties. We encourage
            you
            to be aware when you leave the Website and Services and to read the privacy statements of each and every
            resource that may collect Personal Information.</p>
        <br>
        <h3>Information security</h3>
        <p>We secure information you provide on computer servers in a controlled, secure environment, protected from
            unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical
            safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of
            Personal
            Information in our control and custody. However, no data transmission over the Internet or wireless network
            can
            be guaranteed.</p>
        <br>
        <p>Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security
            and
            privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy
            of
            any and all information and data exchanged between you and the Website and Services cannot be guaranteed;
            and
            (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best
            efforts.</p>
        <br>
        <h3>Privacy Rights</h3>
        <p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection
            laws.
            These may include the right (i) to request access and obtain a copy of your personal information, (ii) to
            request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may also have the right to object to the
            processing of your personal information. You can make such a request by contacting us by using the contact
            details provided in the section below “Contact us“</p>
        <br>
        <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
        <br>
        <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
            you
            also have the right to complain to your Member State data protection authority or UK data protection
            authority.
        </p>
        <br>
        <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
        </p>
        <br>
        <p><span style="text-decoration: underline;">Withdrawing your consent:</span> If we are relying on your consent
            to
            process your personal information, which may be express and/or implied consent depending on the applicable
            law,
            you have the right to withdraw your consent at any time. You can withdraw your consent at any time by
            contacting
            us by using the contact details provided in the section below “Contact us</p>
        <br>
        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
            when
            applicable law allows, will it affect the processing of your personal information conducted in reliance on
            lawful processing grounds other than consent.</p>
        <br>
        <p><span style="text-decoration: underline;">Opting out of marketing and promotional communications:</span> You
            can
            unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe
            link in
            the emails that we send, or by contacting us using the details provided in the section below “Contact us“.
            You
            will then be removed from the marketing lists. However, we may still communicat

            nistration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
        <br>
        <p><span style="text-decoration: underline;">Cookies and similar technologies:</span> Most Web browsers are set
            to
            accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and
            to
            reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or
            services of our Services. You may also opt out on our Services. For further information, please see our
            Cookies
            section above.</p>
        <br>
        <p>If you have questions or comments, you may email us at <a class="mail"
                href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a></p>
        <br>
        <h3>California residents specific privacy rights</h3>
        <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
            California residents to request and obtain from us, once a year and free of charge, information about
            categories
            of personal information (if any) we disclosed to third parties for direct marketing purposes and the names
            and
            addresses of all third parties with which we shared personal information in the immediately preceding
            calendar
            year. If you are a California resident and would like to make such a request, please submit your request in
            writing to us using the contact information provided below.</p>
        <br>
        <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have
            the
            right to request removal of unwanted data that you publicly post on the Services. To request removal of such
            data, please contact us using the contact information provided below and include the email address
            associated
            with your account and a statement that you reside in California. We will make sure the data is not publicly
            displayed on the Services, but please be aware that the data may not be completely or comprehensively
            removed
            from all our systems (e.g., backups, etc.).</p>
        <br>
        <h3>CCPA Privacy Notice</h3>
        <p>The California Code of Regulations defines a "resident" as:</p>
        <ul>
            <li>every individual who is in the State of California for other than a temporary or transitory purpose and
            </li>
            <li>every individual who is domiciled in the State of California who is outside the State of California for
                a
                temporary or transitory purpose</li>
        </ul>
        <br>
        <p>All other individuals are defined as "non-residents."</p>
        <p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding
            your
            personal information.</p>
        <br>
        <h4>What categories of personal information do we collect?</h4>
        <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
        <!-- table -->
        <table style="width:100%">
            <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
            </tr>
            <tr>
                <td>A. Identifiers</td>
                <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number,
                    unique
                    personal identifier, online identifier, Internet Protocol address, email address, and account name
                </td>
                <td>YES</td>
            </tr>
            <tr>
                <td>B. Personal information categories

                    listed in the California Customer

                    Records statute</td>
                <td>Name, contact information, education, employment, employment history, and financial information</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>C. Protected classification

                    characteristics under California or

                    federal law</td>
                <td>Gender and date of birth</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>D. Commercial information</td>
                <td>Transaction information, purchase history, financial details, and payment information</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>E. Biometric informatio</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>F. Internet or other similar network

                    activity</td>
                <td>Browsing history, search history, online behavior, interest data, and interactions with our and
                    other
                    websites, applications, systems, and advertisements</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>G.Geolocation data</td>
                <td>Device location</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>H.Audio, electronic, visual,

                    thermal, olfactory, or similar

                    information</td>
                <td>Images and audio, video or call recordings created in connection with our business activitie</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>I. Professional or employment-

                    related information</td>
                <td>Business contact details in order to provide you our Services at a business level or job title, work
                    history, and professional qualifications if you apply for a job with us</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>K. Inferences drawn from other

                    personal information</td>
                <td>Inferences drawn from any of the collected personal information listed above to create a profile or
                    summary about, for example, an individual’s preferences and characteristics</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>L. Sensitive Personal Information</td>
                <td></td>
                <td>NO</td>
            </tr>
        </table>
        <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
        <ul>
            <li>Category A - as long as necessary to provide access to our website & services</li>
            <li>Category B - as long as necessary to provide access to our website & services</li>
            <li>Category F - as long as necessary to provide access to our website & service</li>
            <li>Category G - as long as necessary to provide access to our website & services</li>
            <li>Category I - as long as necessary to provide employment opportunities to candidates and as long as
                necessary
                to maintain historical of employment.</li>

        </ul>
        <p>We may also collect other personal information outside of these categories through instances where you
            interact
            with us in person, online, or by phone or mail in the context of:</p>
        <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
            <li>Employment discussion</li>
        </ul>
        <br>
        <h4>How do we use and share your personal information?</h4>
        <p>ency Consulting Inc collects and shares your personal information, more information about our data collection
            and
            sharing practices can be found in this privacy notice.</p>
        <br>
        <p>You can opt out from the selling or sharing of your personal information by disabling cookies on your browser
            or
            you can make such a request by contacting us by using the contact details provided in the section below
            “Contact
            us“</p>
        <br>
        <p>If you are using an authorized agent to exercise your right to opt out we may deny a request if the
            authorized
            agent does not submit proof that they have been validly authorized to act on your behalf.</p>
        <br>
        <h4>Will your information be shared with anyone else?</h4>
        <p> We may disclose your personal information with our service providers pursuant to a written contract between
            us
            and
            each service provider. Each service provider is a for-profit entity that processes the information on our
            behalf,
            following the same strict privacy protection obligations mandated by the CCPA.</p>
        <br>
        <p> We may use your personal information for our own business purposes, such as for undertaking internal
            research
            for
            technological development and demonstration. This is not considered to be "selling" of your personal
            information.</p>
        <br>
        <p>ency Consulting Inc has disclosed the following categories of personal information to third parties for a
            business or commercial purpose in the preceding twelve (12) months:</p>
        <ul>
            <li>Category A. Identifiers, such as contact details like your real name, alias, postal address, telephone
                or
                mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email
                address, and account name.</li>
            <li>Category B. Personal information, as defined in the California Customer Records law, such as your name,
                contact information, education, employment, employment history, and financial information</li>
            <li>Category F. Internet or other electronic network activity information, such as browsing history, search
                history, online behavior, interest data, and interactions with our and other websites, applications,
                systems, and advertisements.</li>
            <li>Category G. Geolocation data, such as device location.</li>
            <li>Category I. Professional or employment-related information, such as business contact details in order to
                provide you our Services at a business level or job title, work history, and professional qualifications
                if
                you apply for a job with us.</li>
        </ul>
        <p>The categories of third parties to whom we disclosed personal information for a business or commercial
            purpose
            can be found under " Disclosure of information".</p>
        <h4>Your rights with respect to your personal data</h4>
        <p style="text-decoration: underline;">Right to request deletion of the data — Request to delete</p>
        <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information,
            we
            will respect your request and delete your personal information, subject to certain exceptions provided by
            law,
            such as (but not limited to) the exercise by another consumer of his or her right to free speech, our
            compliance
            requirements resulting from a legal obligation, or any processing that may be required to protect against
            illegal activities.</p>
        <br>
        <p style="text-decoration: underline;">Right to be informed — Request to know</p>
        <p>Depending on the circumstances, you have a right to know:</p>
        <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>the purposes for which the collected personal information is used;</li>
            <li>whether we sell or share personal information to third parties;</li>
            <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>
            <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a
                business purpose;</li>
            <li>the business or commercial purpose for collecting, selling, or sharing personal information; and</li>
            <li>the specific pieces of personal information we collected about you.</li>
        </ul>
        <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is
            de-identified in response to a consumer request or to re-identify individual data to verify a consumer
            request.
        </p>
        <br>
        <p style="text-decoration: underline;">Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
        </p>
        <p>We will not discriminate against you if you exercise your privacy rights</p>
        <br>
        <p style="text-decoration: underline;">Right to Limit Use and Disclosure of Sensitive Personal Information</p>
        <p>We do not process consumer's sensitive personal information.</p>
        <br>
        <p style="text-decoration: underline;">Verification process</p>
        <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about
            whom
            we have the information in our system. These verification efforts require us to ask you to provide
            information
            so that we can match it with information you have previously provided us. For instance, depending on the
            type of
            request you submit, we may ask you to provide certain information so that we can match the information you
            provide with the information we already have on file, or we may contact you through a communication method
            (e.g., phone or email) that you have previously provided to us. We may also use other verification methods
            as
            the circumstances dictate.</p>
        <br>
        <p>We will only use personal information provided in your request to verify your identity or authority to make
            the
            request. To the extent possible, we will avoid requesting additional information from you for the purposes
            of
            verification. However, if we cannot verify your identity from the information already maintained by us, we
            may
            request that you provide additional information for the purposes of verifying your identity and for security
            or
            fraud-prevention purposes. We will delete such additionally provided information as soon as we finish
            verifying
            you.</p>
        <br>
        <p style="text-decoration: underline;">Other privacy rights</p>
        <ul>
            <li>You may object to the processing of your personal information.</li>
            <li>You may request correction of your personal data if it is incorrect or no longer relevant or ask to
                restrict
                the processing of the information.</li>
            <li>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a
                request
                from an authorized agent that does not submit proof that they have been validly authorized to act on
                your
                behalf in accordance with the CCPA.</li>
        </ul>
        <p>To exercise these rights, you can contact us by email at <a class="mail"
                href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a>, or by referring to the contact
            details at the bottom of this document.</p>
        <br>
        <h4>Virginia residents specific privacy rights</h4>
        <p>Under the Virginia Consumer Data Protection Act (CDPA): Virginia CDPA Privacy Notice</p>
        <br>
        <p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or
            household
            context. It does not include a natural person acting in a commercial or employment context.

            "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable
            natural person. "Personal data" does not include de-identified data or publicly available information.

            "Sale of personal data" means the exchange of personal data for monetary consideration.

            If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding
            your
            personal data.</p>
        <br>
        <p>The information we collect, use, and disclose about you will vary depending on how you interact with ency
            Consulting Inc and our Services. To find out more, please visit the following sections:</p>
        <ul>
            <li>Personal information you disclose.</li>
            <li>Automatic collection of information</li>
            <li>Use and processing of collected information</li>
            <li>Disclosure of information</li>
            <li>International Data Transfe</li>
            <li>Cookies</li>
        </ul>
        <p style="text-decoration: underline;">Your rights with respect to your personal data</p>
        <ul>
            <li>Right to be informed whether or not we are processing your personal data</li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>Right to obtain a copy of the personal data you previously shared with us</li>
            <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the
                sale of
                personal data, or profiling in furtherance of decisions that produce legal or similarly significant
                effects
                ("profiling")</li>
        </ul>
        <p>ency Consulting Inc has not sold any personal data to third parties for business or commercial purposes. ency
            Consulting Inc will not sell personal data in the future belonging to website visitors, users, and other
            consumers.</p>
        <br>
        <p style="text-decoration: underline;">Exercise your rights provided under the Virginia CDPA</p>
        <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
        <br>
        <p>You may contact us by email at <a class="mail"
                href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a> or
            by referring to the contact details at the bottom of this document.</p>
        <br>
        <p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent
            does
            not submit proof that they have been validly authorized to act on your behalf.</p>
        <br>
        <p style="text-decoration: underline;">Verification process</p>
        <p>We may request that you provide additional information reasonably necessary to verify you and your consumer's
            request. If you submit the request through an authorized agent, we may need to collect additional
            information to
            verify your identity before processing your request.</p>
        <br>
        <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45)
            days
            of receipt. The response period may be extended once by forty-five (45) additional days when reasonably
            necessary. We will inform you of any such extension within the initial 45-day response period, together with
            the
            reason for the extension.</p>
        <br>
        <p style="text-decoration: underline;">Right to appeal</p>
        <p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind
            it.
            If you wish to appeal our decision, please email us at <a class="mail"
                href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a> Within sixty (60) days of receipt
            of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal,
            including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact
            the
            Attorney General to submit a complaint.</p>
        <br>

        <h4>Data breach</h4>
        <p>In the event we become aware that the security of the Website and Services has been compromised or Users’
            Personal Information has been disclosed to unrelated third parties as a result of external activity,
            including,
            but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures,
            including, but not limited to, investigation and reporting, as well as notification to and cooperation with
            law
            enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected
            individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or
            if
            notice is otherwise required by law. When we do, we will send you an email.</p>
        <br>
        <h4>Changes and amendments</h4>
        <p>We reserve the right to modify this policy or its terms related to the Website and Services at any time at
            our
            discretion. When we do, we will send you an email to notify you. We may also provide notice to you in other
            ways
            at our discretion, such as through the contact information you have provided.

            An updated version of this Policy will be effective immediately upon the posting of the revised Policy
            unless
            otherwise specified. Your continued use of the Website and Services after the effective date of the revised
            Policy (or such other act specified at that time) will constitute your consent to those changes. However, we
            will not, without your consent, use your Personal Information in a manner materially different than what was
            stated at the time your Personal Information was collected.</p>
        <br>
        <h4>Acceptance of this policy</h4>
        <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and
            using
            the Website and Services and submitting your information you agree to be bound by this Policy. If you do not
            agree to abide by the terms of this Policy, you are not authorized to access or use the Website and
            Services.
        </p>
        <br>
        <h4>Maintain your personal information and consent</h4>
        <p>Based on the applicable laws of your country, you may have the right to request access to the personal
            information we collect from you, change that information, or delete it. To request to review, update, or
            delete
            your personal information or withdrawing your consent please email us at <a class="mail"
                href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a> or visit:
            <a target="_blank" href='/contact-us#form-wrapper'
                style="cursor:pointer;">https://www.encyconsulting.ca/contact.html.</a>
        </p>
        <h4>Contact us</h4>
        <p>If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you,
            or
            if you wish to exercise your rights, we encourage you may email us at <a class="mail"
                href="mailto:info@encyconsulting.ca">info@encyconsulting.ca</a> or visit: <a target="_blank"
                href='/contact-us#form-wrapper' style="cursor:pointer;">https://www.encyconsulting.ca/contact.html.</a>
        </p>

        <br>
        <p>We will attempt to resolve complaints and disputes and make every reasonable effort to honor your request to
            exercise your rights as quickly as possible and, in any event, within the timescales provided by applicable
            data
            protection laws</p>
        <br>
        <p>This document was last updated on July 6, 2023</p>
    </div>
  
    <div>