import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-ency-advantage',
  templateUrl: './ency-advantage.component.html',
  styleUrls: ['./ency-advantage.component.scss']
})
export class EncyAdvantageComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor () {}

  ngOnInit (): void {
    this.scrollToTop();
  }

  // <!-- ----------------------------------------------------------------------------------------------->
  navItemDropDownMouseEnter (id: any) {
    document.getElementById(id)?.click()
  }

  navItemDropDownMouseLeave () {}

  leavingNavbar () {
    document.getElementById('mouse-leave-tab')?.click()
  }
  // <!-- ----------------------------------------------------------------------------------------------->

  scrollToTop(){
    const element = document.querySelector('.ency-advantage-main-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
