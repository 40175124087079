<div class="product-page-main-container" id="container">
    <app-navbar class="black-links"></app-navbar>
    <div class="product-banner-area-wrapper">
        <div class="banner-left-content-wrapper">
            <div class="product-text">Products</div>
            <div class="product-para">We develop and implement your products & put your ideas into practice</div>
            <div class="products-list-wrapper">
                <!-- <div class="product1">EZDesk.ai</div>
                <div class="product2">Driveez</div>
                <div class="product3">LearnAnywhere</div> -->
                <img class="logo-wrapper" src="assets/product-main/logo-wrapper.png"/>
            </div>
            <!-- <div class="product4">Traveho</div> -->
        </div>
    </div>
    <div class="customer-support-main-wrapper">
        <img class="messy-line" src="assets/product-main/messy-line.png" />
        <div class="customer-support-left-wrapper">
            <div class="heading1">Customer Support </div>
            <div class="heading2">Management Software</div>
            <div class="line"></div>
            <img class="ezdesk-logo" src="assets/product-main/ezdesk-logo.png" />
            <div class="ezdesk-para">ezdesk solution is a comprehensive software platform that enables businesses to
                provide exceptional customer support and effectively manage customer inquiries, issues, and requests.
                This tool is essential for businesses of all sizes to enhance customer satisfaction, streamline internal
                processes, and maintain a positive brand image</div>
            <p id="explore_more" routerLink="/ezdesk">
                <img src="assets/next_icon.png" alt="next_icon" id="next_icon" />
                <span class="explore_more_text  f-22 fw-600">Explore
                    More</span>
            </p>
        </div>
        <div class="customer-support-right-wrapper">
            <img class="customer-support-right-image" src="assets/product-main/customer-support-right.png" />

        </div>
    </div>
    <div class="driving-school-management-wrapper">
        <div class="customer-support-right-wrapper">
            <img class="customer-support-right-image" src="assets/product-main/driving-management-left.png" />

        </div>
        <div class="customer-support-left-wrapper">
            <div class="heading1">Driving School </div>
            <div class="heading2">Management Software</div>
            <div class="line"></div>
            <img class="ezdesk-logo" src="assets/product-main/driveez.png" />
            <div class="ezdesk-para">Driveez is a comprehensive and user-friendly driving school management software
                designed to streamline and automate the operations of driving schools.Whether you're a small independent
                driving instructor or a larger driving school with multiple instructors and locations, Driveez provides
                the tools you need to efficiently manage your scheduling, student records, payments, and communication.
            </div>
            <a style="text-decoration: none;" id="explore_more" target="_blank" href="https://driveezpro.ca/" >
                <img src="assets/next_icon.png" alt="next_icon" id="next_icon" />
                <span class="explore_more_text  f-22 fw-600">Explore
                    More</span>
                </a>
        </div>

    </div>
    <div class="learning-management-wrapper">
        <img class="la-bottom-right" src="assets/product-main/la-bottom-right.png" />

        <div class="customer-support-left-wrapper">
            <div class="heading1">
                <div>Learning</div><img class="learning_img" src="assets/product-main/learning_img.gif" />
            </div>
            <div class="heading2">Management Software</div>
            <div class="line"></div>
            <div class="la-logo-wrapper"> <img class="ezdesk-logo" src="assets/product-main/la.png" /> <img
                    class="polygon-logo" src="assets/product-main/polygon.png" /></div>
            <div class="ezdesk-para">Design for creating & Managing Educational content. Easy to create a course & sell
                online</div>
            <div class="great_for_wrapper">
                <div class="great-for-heading">It’s Great For :</div>
                <div class="bullet-wrapper">
                    <div class="bullet1-wrapper">
                        <div class="bullet1">
                             <img class="bullet-img" src="assets/product-main/black-check.png" />
                            <div class="bullet-point">Online Coaching <br/><span>Platform</span></div>
                          
                        </div>
                        <div class="bullet2">
                            <img class="bullet-img" src="assets/product-main/black-check.png" />
                           <div class="bullet-point">School Learning Management System</div>
                       </div>

                    </div>
                    <div class="bullet2-wrapper">
                        <div class="bullet3">
                            <img class="bullet-img" src="assets/product-main/black-check.png" />
                           <div class="bullet-point">Corporate Training Anywhere</div>
                       </div>
                       <div class="bullet4">
                        <img class="bullet-img" src="assets/product-main/black-check.png" />
                       <div class="bullet-point">E-Learning Marketplace</div>
                   </div>
                    </div>
                </div>
            </div>
            <p id="explore_more"  routerLink="/la">
                <img src="assets/next_icon.png" alt="next_icon" id="next_icon" />
                <span class="explore_more_text  f-22 fw-600">Explore
                    More</span>
            </p>
        </div>
        <div class="customer-support-right-wrapper">
            <img class="customer-support-right-image" src="assets/la/la_hero_image.png" />

        </div>
    </div>
    <div class="taxi-booking-management" >
        <img class="squares1" src="assets/product-main/squares1.png" />
        <img class="squares2" src="assets/product-main/squares1.png" />
        <div class="customer-support-right-wrapper">
            <img class="customer-support-right-image" src="assets/product-main/taxi-booking-left.png" />

        </div>
        <div class="customer-support-left-wrapper">
            <div class="heading1">Taxi Booking</div>
            <div class="heading2">Service Platform</div>
            <div class="line"></div>
            <img class="ezdesk-logo" src="assets/product-main/traveho.png" />
            <div class="ezdesk-para">Our comprehensive taxi application provides all the essential tools to efficiently
                run a location-based on-demand service. Using WebSocket technology, we establish a fast and real-time
                bidirectional connection among all users, ensuring seamless communication and efficient operations.
            </div>
            <p id="explore_more"  routerLink="/traveho">
                <img src="assets/product-main/explore more_blue.png" alt="next_icon" id="next_icon" />
                <span class="explore_more_text  f-22 fw-600">Explore
                    More</span>
            </p>
        </div>

    </div>

</div>