<div class="meet-our-team-main-wrapper" id="container"> 
    <app-navbar class="black-links  mobile-nav"></app-navbar>
    <div class="our-leadership-section-main-wrapper">
        <app-navbar class="black-links position-absolute-navbar desktop-nav"></app-navbar>
        <!-- <div class="our-leadership-section-content-wrapper"> -->
        <div class="left-content col-lg-6">
            <div class="overview-title-wrapper">
                <div class="prefix_style_circle_wrapper">
                    <div class="circle">
                        <div class="inner_circle"></div>
                    </div>
                    <div class="line"></div>
                </div>
                <div class="outer-circle-animation">
                    <img class="circular-animation" src="assets/circular-animation.gif" />
                    <img class="text-inside-animation" src="assets/Our.png" />
                </div>
            </div>
            <div class="about-ency-wrapper">
                <p id="about-ency-text">Leadership</p>
            </div>
            <div class="para-content-wrapper">
                <p id="para-content">We are proud to introduce you to the individuals who make up our company. Our
                    team is made up of passionate, dedicated, and talented individuals who are committed to
                    achieving excellence in everything they do</p>
            </div>
            <div class="images-wrapper">
                <img class="arrow-animation" src="assets/arrow-animation.gif" />
            </div>
        </div>
        <!-- <img class="our-leadership-frame-one-image" src="assets/our-leadership-frame-one.png"/> -->
        <!-- </div> -->

    </div>
    <div class="cofounder-wrapper-one">
        <div class="cofounder-information-wrapper">
            <div class="cofounder-name">Ratan Ravindra Singh</div>
            <div class="cofounder-title">(Co-Founder and CEO)</div>
            <div class="cofounder-details-one">Ratan is a Strategic leader and a Business Consultant with 25+ years of
                progressive and rich global experience in formulating, executing, and delivering enterprise-wide
                business solutions supported with technological and strategic innovations across North America, Europe &
                Asia-Pacific bringing strategic goals to lasting business value.
                <br>
                <br>
                Ratan is an expert in consulting on large-scale multi-million $ programs by engaging CXO’s, management
                and operations across functional, organizational, and geographic boundaries to create full alignment &
                synergy from vision to strategy & execution to successfully attain long-term strategic initiatives.
                <br><br>
                Ratan draws inspiration from the varied backgrounds and cultures he encounters while engaging with teams
                across the world. In his free time either he is travelling or enjoying time with his family.
            </div>

            <div class="gmail-id"><a href="mailto:ratan.singh@encyconsulting.ca">ratan.singh@encyconsulting.ca</a></div>
        </div>
        <div class="founder-wrapper">
            <img class="cofounder-photo" src="assets/cofounder-pic2.png" />
            <a href="https://www.linkedin.com/in/ratansingh" target="_blank"><img class="linkedin-icon"
                    src="assets/linkedin-mot.png" /></a>
        </div>

    </div>
    <div class="cofounder-wrapper-two">
        <div class="founder-wrapper">
            <img class="cofounder-photo" src="assets/John.png" />
            <a href="https://www.linkedin.com/in/john77praveen" target="_blank"><img class="linkedin-icon-two"
                    src="assets/linkedin-mot.png" /></a>
        </div>
        <div class="cofounder-information-wrapper">
            <div class="cofounder-name">John Praveen Kumar</div>
            <div class="cofounder-title">(Co-Founder and Chief Product Officer)</div>
            <div class="cofounder-details-one">Passionate about Emerging Technology and Innovations, with 20+ years of
                Strategic and Operational experience via, Product Management, Operations Model Strategy, and Team
                Leadership. Being sensitive with technological Innovative Dynamism with a Business mindset, strong
                analytical skills, and Smart Solutions have built 7 products in the E-Learning, Data Science &
                AI space. Strongly believes in Customer Empathy and sensitivity towards other ideas and has the Emotional
                Intelligence to  exhibit Equality & Generosity in my sphere of influence.

            </div>
            <div class="gmail-id"><a href="mailto:john.p@encycg.com">john.p@encycg.com</a></div>
        </div>
        <img class="small-square-mixing-animation" src="assets/small-square-mixing-animation.gif" />
        <img class="small-square-mixing-animation-two" src="assets/small-square-mixing-animation.gif" />
    </div>





    <div class="team-gallary-wrapper">
        <div class="meet-our-team-heading-wrapper">
            <div class="heading-part-one heading-part-three">
                <img class="back-next-arrow-circle" src="assets/arrow-circle.png" />
                <img class="back-next-arrow-circle" src="assets/arrow-next-circle.png" />
            </div>
            <div class="heading-part-two">
                <img class="round-animation" src="assets/round-animation.gif" />
                <div class="middle-heading">ency Team</div>
                <img class="round-animation" src="assets/round-animation.gif" />
            </div>
            <div class="heading-part-three">
                <!-- <img class="back-next-arrow-circle" data-bs-target="#carouselExampleControls" data-bs-slide="prev"
                    src="assets/arrow-circle.png" />
                <img class="back-next-arrow-circle" data-bs-target="#carouselExampleControls" data-bs-slide="next"
                    src="assets/arrow-next-circle.png" /> -->
                <!-- <div class="arrows_wrapper"> -->
                <a class="arrow_btn" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    < </a>
                        <a class="arrow_btn" data-bs-target="#carouselExampleControls" data-bs-slide="next">></a>
                        <!-- </div> -->
            </div>
        </div>
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="meet-our-team-members-wrapper">
                        <div *ngFor="let member of team_mates_list_one | slice:0:5; index as i;"
                            (click)="team_mate_set(i)" class="team-member-wrapper" data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                            <img class="team-member-photo"
                                [src]="member?.image ? member?.image : 'assets/Avtar_image.png'" />
                            <div class="team-member-name">{{member?.name}}</div>
                            <div class="team-member-position">{{member?.role}}</div>
                        </div>
                    </div>
                    <br />
                    <div class="meet-our-team-members-wrapper-two">
                        <div *ngFor="let member of team_mates_list_one | slice:5:9; index as i;"
                            (click)="team_mate_set(i+5)" class="team-member-wrapper" data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                            <img class="team-member-photo"
                                [src]="member?.image ? member?.image : 'assets/Avtar_image.png'" />
                            <div class="team-member-name">{{member?.name}}</div>
                            <div class="team-member-position">{{member?.role}}</div>
                        </div>
                        <img class="dotted-circle-animation-one" src="assets/dotted-circle-animation.gif" />
                        <img class="dotted-circle-animation-two" src="assets/dotted-circle-animation.gif" />
                    </div>

                </div>
                <div class="carousel-item">
                    <div class="meet-our-team-members-wrapper-three">
                        <div *ngFor="let member of team_mates_list_one | slice:9:14; index as i;"
                            (click)="team_mate_set(i+9)" class="team-member-wrapper" data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                            <img class="team-member-photo"
                                [src]="member?.image ? member?.image : 'assets/Avtar_image.png'" />
                            <div class="team-member-name">{{member?.name}}</div>
                            <div class="team-member-position">{{member?.role}}</div>
                        </div>
                        <!-- <img class="dotted-circle-animation-one" src="assets/dotted-circle-animation.gif" />
                        <img class="dotted-circle-animation-two" src="assets/dotted-circle-animation.gif" /> -->
                    </div>

                    <br />
                    <div class="meet-our-team-members-wrapper-two">
                        <!-- <img class="dotted-circle-animation-three" src="assets/dotted-circle-animation.gif" />
                        <img class="dotted-circle-animation-four" src="assets/dotted-circle-animation.gif" /> -->

                    </div>
                </div>

            </div>
            <!-- <button class="carousel-control-prev" type="button" >
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button> -->
        </div>


    </div>


    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-size-full" style="max-width: 100%;margin: 0px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="outer-wrapper">
                        <img class="team-member-photo"
                            [src]="team_mates_list_one[team_mate_active]?.image ? team_mates_list_one[team_mate_active]?.image : 'assets/Avtar_image.png'" />
                        <div class="data-wrapper">
                            <div class="team-data">
                                <p class="member-name">{{ team_mates_list_one[team_mate_active]?.name}}</p>
                                <p class="member-role">{{ team_mates_list_one[team_mate_active]?.role}}</p>
                                <p class="member-desc">{{ team_mates_list_one[team_mate_active]?.desc}}</p>
                            </div>
                            <div class="navigation-wrapper">
                                <span class="separator"></span>

                                <div class="internal-wrapper">
                                    <div [ngClass]="prev_member != null? 'active-navigation-item-visibility':'navigation-item-wrapper-visibility'"
                                        class="left-navigation " (click)="team_mate_set(previousItemIndex)">
                                        <img src="assets/mot-left-nav.png" />
                                        <div class="internal-footer-wrapper">
                                            <p class="footer-person-name">{{prev_member?.name}}</p>
                                            <p class="footer-person-role">{{prev_member?.role}}</p>
                                        </div>
                                    </div>
                                    <div [ngClass]="next_member!= null ? 'active-navigation-item-visibility':'navigation-item-wrapper-visibility'"
                                        class="right-navigation" (click)="team_mate_set(nextItemIndex)">
                                        <div class="internal-footer-wrapper">
                                            <p class="footer-person-name">{{next_member?.name}}</p>
                                            <p class="footer-person-role">{{next_member?.role}}</p>
                                        </div>
                                        <img src="assets/mot-right-nav.png" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</div>