<div class="terms-and-conditions-main" id="container">
    <app-navbar class="black-links"></app-navbar>
    <div class="inner_terms-and-conditions">
    <h1>TERMS OF USE</h1>
    <br>
    <p>Last revision: July 6, 2023</p>
    <br>
    <h3>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.</h3>
    <p>The following Terms and Conditions govern and apply to your use of or reliance upon this website maintained by
        ency Consulting Inc (the "Website").
        <br>
        <br>
        Your access or use of the Website indicates that you have read, understand, and agree to be bound by these Terms
        and Conditions and any other applicable laws, statutes and/or regulations. We may change these Terms and
        Conditions at any time without notice, effective upon its posting to the Website. Your continued use of the
        Website will be considered your acceptance to the revised Terms and Conditions.
    </p>
    <br>
    <br>
    <h3>1. INTELLECTUAL PROPERTY</h3>

    <p>All intellectual property on the Website is owned by us or our licensors, which includes materials protected by
        copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or
        licensed by us. All content on the Website, including but not limited to text, software, code, designs,
        graphics, photos, sounds, music, videos, applications, interactive features, and all other content is a
        collective work under Canadian and other copyright laws and is the proprietary property of the Company; All
        rights reserved.</p>
    <br>
    <h2>2.USE OF COMPANY MATERIALS</h2>
    <p>We may provide you with certain information as a result of your use of the Website including, but not limited to,
        documentation, data, or information developed by us, and other materials which may assist in the use of the
        Website or Services ("Company

        Materials"). The Company Materials may not be used for any other purpose than the use of this Website and the
        services offered on the Website. Nothing in these Terms of Use may be interpreted as granting any licence of
        intellectual property rights to you.</p>
    <br>
    <h2>3.ACCEPTABLE USE</h2>
    <p>You agree not to use the Website for any unlawful purpose, or any purpose prohibited under this clause. You agree
        not to use the Website in any way that could damage the Website, the services, or the general business of ency
        Consulting Inc.
        You further agree not to use and/or access the Website:

    </p>
    <ul>
        <li>To harass, abuse, or threaten others or otherwise violate any person's legal rights;</li>
        <li>To violate any intellectual property rights of us or any third party;</li>
        <li>To upload or otherwise disseminate any computer viruses or other software that may damage the property of
            another;</li>
        <li>To perpetrate any fraud;</li>
        <li>To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;</li>
        <li>To publish or distribute any obscene or defamatory material;</li>
        <li>To publish or distribute any material that incites violence, hate or discrimination towards any group;</li>
        <li>To unlawfully gather information about others.</li>
    </ul>
    <br>

    <h3>4.PROTECTION OF PRIVACY</h3>
    <p>Through your use of the Website, you may provide us with certain information. By using the Website, you authorize
        us to use your information in Canada and any other country where we may operate.

        We may receive information from external applications you use to access our Website, or we may receive
        information through various web technologies, such as cookies, log files, clear gifs, web beacons or others.

        We use the information gathered from you to ensure your continued good experience on our website. We may also
        track certain of the passive information received to improve our marketing and analytics, and for this, we may
        work with third-party providers, including other marketers.

        If you would like to disable our access to any passive information we receive from the use of various
        technologies, you may choose to disable cookies in your web browser.</p>
    <br>
    <h3>5.REVERSE ENGINEERING & SECURITY</h3>
    <p>You may not undertake any of the following actions:</p>
    <br>

    <ul>
        <li>
            Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website;
        </li>
        <li>Violate the security of the Website through any unauthorized access, circumvention of encryption or other
            security tools, data mining or interference to any host, user, or network.</li>
        <li>Violate the security of the Website through any unauthorized access, circumvention of encryption or other
            security tools, data mining or interference to any host, user, or network.</li>
    </ul>
    <h3>6.INDEMNIFICATION</h3>
    <p>You defend and indemnify ency Consulting Inc and any of its affiliates and hold us harmless against any and all
        legal claims and demands, including reasonable attorney's fees, which may arise from or relate to your use or
        misuse of the Website, your breach

        of these Terms and Conditions, or your conduct or actions. We will select our own legal counsel and may
        participate in our own defense if we wish to so.</p>
    <br>
    <h3>7.SERVICE INTERRUPTIONS</h3>
    <p>We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled
        or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled
        downtime, for any reason, but that we will have no liability for any damage or loss caused as a result of such
        downtime.</p>
    <br>
    <h3>8.CORRECTIONS</h3>
    <p>There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
        including descriptions, pricing, availability, and various other information. We reserve the right to correct
        any errors, inaccuracies, or omissions and to change or update the information on the Services at any time,
        without prior notice.</p>
    <h3>9.NO WARRANTIES</h3>
    <p>Your use of the Website is at your sole and exclusive risk and any services provided by us are on an "as is"
        basis. We disclaim any and all express or implied warranties of any kind, including, but not limited to the
        implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no
        warranties that the Website will meet your needs or that the Website will be uninterrupted, error-free, or
        secure. We also make no warranties as to the reliability or accuracy of any information on the Website or
        obtained through the Services. Any damage that may occur to you, through your computer system, or as a result of
        loss of your data from your use of the Website is your sole responsibility and we are not liable for any such
        damage or loss.</p>
    <br>
    <h3>10.PRIVACY</h3>
    <p>In addition to these Terms and Conditions, this Website has a Privacy Policy that describes how your personal
        data is collected and how cookies are used on the Website. For more information, you may refer to our Privacy
        Policy, which you may find at the following address: www.encyconsulting.ca/legal.html.

        By using or browsing this Website, you also acknowledge that you have read our Privacy Policy</p>
    <br>
    <h3>11.LIMITATION ON LIABILITY</h3>
    <p>We are not liable for any damages that may occur to you as a result of your use of the Website, to the fullest
        extent permitted by law. The maximum liability of ency Consulting Inc arising from your use of the Website is
        limited to the greater of one hundred ($100) Canadian Dollars or the amount you paid to ency Consulting Inc in
        the last six (6) months. This applies to any and all claims by you, including, but not limited to, lost profits
        or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind</p>
    <br>
    <h3>12.GOVERNING LAW</h3>
    <p>These Legal Terms shall be governed by and defined following the laws of Canada. ency Consulting Inc and you
        irrevocably consent that the courts of Canada shall have exclusive jurisdiction to resolve any dispute which may
        arise in connection with these Legal Terms.</p>
    <br>
    <h3>13.CALIFORNIA USERS AND RESIDENTS</h3>
    <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market
        Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254</p>
    <br>
    <h3>14.CONTACT US</h3>
    <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the
        Services, please contact us at:</p>
    <br>
    <div>ency Consulting Inc</div>
    <div> 25 Sheppard Ave W #300</div>
    <div>North York, Ontario M2N 6S6</div>
    <div>Canada</div>
    <div>Phone: 6473910700</div>
    <div> info@encyconsulting.ca</div>
</div>
  
    
</div>