<div class="la_main_container" id="container">
    <app-navbar class="black-links"></app-navbar>
    <div class="la_hero_section_wrapper">
        <div class="la_hero_left">
            <div class="learning_container">
                <div class="learning">Learning</div>
                <img class="learning_right_image" src="assets/la/learning_right_image.png"/>
            </div>
            <div class="management">Management</div>
            <div class="system">System</div>
            <div class="hero_para_wrapper">
                
                <div class="hero_para">Design for creating & Managing Educational content. Easy to create a course & sell online</div>
            </div>
        </div>
        <div class="la_hero_right_wrapper">
            <img class="la_right_image" src="assets/la/la_hero_image.png"/>
        </div>
    </div>
    <div class="la_how_it_works_wrapper">
        <div class="la_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="la_key">How it <span id="la_text">Works</span> </div>
            </div>


        </div>
        <div class="how_it_works_content_wrapper">
            <img class="right-corner" src="assets/la/right-corner.png"/>
            <img class="left-corner" src="assets/la/left-corner.png"/>
            <div class="how_it_works_left_section">
                <img class="how-it-works-left-image" src="assets/la/how-it-works-left-image.png"/>
            </div>
            <div class="how_it_works_right_Wrapper">
                <div class="how_it_works_items_wrapper">
                    <img class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Start your course portal installing LMS</div>
                </div>
                <div class="how_it_works_items_wrapper">
                    <img class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Assign your co-instruction for creating courses</div>
                </div>
                <div class="how_it_works_items_wrapper">
                    <img class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Record videos & prepare course materials for making lessons</div>
                </div>
                <div class="how_it_works_items_wrapper">
                    <img class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Your students browse the course portal & select preferred course</div>
                </div>
                <div class="how_it_works_items_wrapper">
                    <img class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Student purchase or enroll for free to courses & start learning</div>
                </div>
                <div class="how_it_works_items_wrapper">
                    <img style="padding-top:0" class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Instructors can collaborate with students by live classes, forum mobile apps, messaging etc</div>
                </div>
                <div class="how_it_works_items_wrapper">
                    <img class="how_it_works_items_image" src="assets/la/how_it_works_item.png"/>
                    <div class="how_it_works_item">Thus you run your online course business make your own education platform</div>
                </div>
            </div>
        </div>
    </div>
    <div class="la_great_for_container">
        <img class="yellow_bar" src="assets/la/yellow_bar.png"/>
        <div class="la_great_for_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="la_great_for_key">Learn Anywhere is <span id="la_great_for_text">Great for</span> </div>
            </div>


        </div>
        <div class="la_great_for_content_wrapper">
            <div class="la_great_for_inner_wrapper">
                <img class="la_great_for_image" src="assets/la/la_great_for_image1.png"/>
                <div class="la_great_for_item_heading">Online Coaching Platform</div>
                <div class="la_great_for_item_para">Learn Anywhere will accelerate your coaching business in more effective way</div>
            </div>
            <div class="la_great_for_inner_wrapper">
                <img class="la_great_for_image" src="assets/la/la_great_for_image2.png"/>
                <div class="la_great_for_item_heading">School Learning Management System</div>
                <div class="la_great_for_item_para">Learn Anywhere makes school activities easier & faster for all users</div>
            </div>
            <div class="la_great_for_inner_wrapper">
                <img class="la_great_for_image" src="assets/la/la_great_for_image3.png"/>
                <div class="la_great_for_item_heading">Corporate Training Anywhere</div>
                <div class="la_great_for_item_para">Train your employess to make more skilled & productive by using Learn Anywhere</div>
            </div>
            <div class="la_great_for_inner_wrapper">
                <img class="la_great_for_image" src="assets/la/la_great_for_image4.png"/>
                <div class="la_great_for_item_heading">E-Learning Marketplace</div>
                <div class="la_great_for_item_para">Convert your skills to online course, sell them & earn money</div>
            </div>
        </div>
    </div>
    <div class="la_student_key_features_wrapper">
        <img class="plus1" src="assets/la/plus_icon.png"/>
        <img class="plus2" src="assets/la/plus_icon.png"/>
        <div class="la_student_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="la_student_key">Student’s Key <span id="la_student_text"> Features</span> </div>
            </div>


        </div>
        <div class="la_student_key_features_content">
            <img class="student_key_feature_upper" src="assets/la/student_key_feature_upper.png"/>
            <img class="student_key_feature_top_right" src="assets/la/student_key_feature_top_right.png"/>
            <img class="students_left_image" src="assets/la/students_left_image.png"/>
            <div class="student_right_section_wrapper">
                <div class="student_features_heading1">Learn From Anywhere -</div>
               <div class="student_features_heading2_wrapper">
                <div class="student_features_heading2">Affordable Online Courses for Everyone</div>
                <img class="student_features_heading2_image"src="assets/la/student_features_heading2.png"/>
               </div> 
                <div class="students_features_in_detail_wrapper">
                    <div class="students_features_in_detail_wrapper1">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Browse Course portal & choose what to learn</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Filter Courses using several criterias</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Student can take quiz to demonstrate their learnings</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Course description page includes summary, requirements, Instructor details, reviews & purchase link</div>
                        </div>
                    </div>
                    <div class="students_features_in_detail_wrapper2">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Courses that student purchase are listed on my course page</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Completion course are tracked individually for each student</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Student can give rating and post reviews for courses</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Favourite courses can be added to wishlist for future purchase or sharing reference</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="stud_features_content_wrapper">
                    <div class="row1" >
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Browse Course portal & choose what to learn</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Filter Courses using several criterias</div>
                        </div>
                    </div>
                    <div class="row1">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Browse Course portal & choose what to learn</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Filter Courses using several criterias</div>
                        </div>
                    </div>
                    <div class="row1">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Student can take quiz to demonstrate their learnings</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Course description page includes summary, requirements, Instructor details, reviews & purchase link</div>
                        </div>
                    </div>
                    <div class="row1">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Courses that student purchase are listed on my course page</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Completion course are tracked individually for each student</div>
                        </div>
                    </div>
                    <div class="row1">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Student can give rating and post reviews for courses</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/student_feature_icon.png"/>
                            <div class="student_feature">Favourite courses can be added to wishlist for future purchase or sharing reference</div>
                        </div>
                    </div>
                </div> -->
            
            </div>
        </div>
    </div>
    <div class="la_instructor_key_features_wrapper">
        <img class="plus1" src="assets/la/blue_plus.png"/>
        
        <div class="la_instructor_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="la_instructor_key">Instructor Key<span id="la_instructor_text"> Features</span> </div>
            </div>


        </div>
        <div class="la_instructor_key_features_content">
      
            <div class="instructor_right_section_wrapper">
                <div class="instructor_features_heading1">Instruct From Anywhere -</div>
               <div class="instructor_features_heading2_wrapper">
                <div class="instructor_features_heading3">Dedicated Instructor in your way</div>
                <div class="instructor_features_heading2">Dedicated Instructor in your </div>
                <div class="way_wrapper">way <img class="instructor_features_heading2_image"src="assets/la/student_features_heading2.png"/></div>
              
               </div> 
                <div class="instructor_features_in_detail_wrapper">
                    <div class="instructor_features_in_detail_wrapper1">
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Instructors get separate panel for managing courses</div>
                        </div>
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Course can be organized in sections & lessons</div>
                        </div>
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Instructors can raise & see a payout request</div>
                        </div>
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Instructors can send messages to all the students who have enrolled to their courses</div>
                        </div>
                    </div>
                    <div class="instructor_features_in_detail_wrapper2">
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Instructor has the opportunity to update their profile information</div>
                        </div>
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Instructor can add course or lesson from anywhere they want</div>
                        </div>
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Instructor can add more than one instructor in a single course</div>
                        </div>
                        <div class="instructor_feature_with_icon">
                            <img class="instructor_feature_icon" src="assets/la/check_orange.png"/>
                            <div class="instructor_feature">Course curriculum can be organized in sections & lessons</div>
                        </div>
                    </div>
                </div>
          
            
            </div>
            <img class="instructor_left_image" src="assets/la/instructor_right_image.png"/>
           
        </div>
    </div>
    <div class="la_admin_key_features_wrapper">
        <img class="plus1" src="assets/la/blue_plus.png"/>
        <img class="plus2" src="assets/la/admin_half_circle.png"/>
        <div class="la_student_heading_wrapper">
            <div class="prefix_style_circle_wrapper">
                <div class="circle">
                    <div class="inner_circle"></div>
                </div>
                <div class="line"></div>
                <div id="la_student_key">Admin Key  <span id="la_student_text"> Features</span> </div>
            </div>


        </div>
        <div class="la_admin_key_features_content">
            <img class="student_key_feature_upper" src="assets/la/downward_admin.png"/>
            <img class="student_key_feature_top_right" src="assets/la/left-corner.png"/>
            <img class="students_left_image" src="assets/la/admin_left.png"/>
            <div class="student_right_section_wrapper">
                <div class="student_features_heading1">Manage Learn Anywhere -</div>
               <div class="student_features_heading2_wrapper">
                <div class="student_features_heading2">Manage your Users & Interactions easily</div>
                <img class="student_features_heading2_image"src="assets/la/admin.png"/>
               </div> 
                <div class="students_features_in_detail_wrapper">
                    <div class="students_features_in_detail_wrapper1">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Admin manages all courses & pending courses from instructors</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Course curriculam manager help user to manage courses</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Admin can add multiple instructors in a course</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Admin can add different coupons. It reduce price while checking out</div>
                        </div>
                    </div>
                    <div class="students_features_in_detail_wrapper2">
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Course categories can be organized very easily</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Admin can watch list of all enrolled students</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Instead of online purchase admin can enroll student to a course</div>
                        </div>
                        <div class="student_feature_with_icon">
                            <img class="student_feature_icon" src="assets/la/blue_check.png"/>
                            <div class="student_feature">Admin can see all registered instructors in learn anywhere</div>
                        </div>
                    </div>
                </div>
           
            </div>
        </div>
    </div>
    <div class="la_last_section_wrapper">
        <div class="try_demo_now_section">
            <div class="learn_anywhere_img_wrapper">
                <img class="learn_anywhere_img" src="assets/la/learn_anywhere.png"/>
            </div>
            <div  [routerLink]="['/contact-us']"  class="try_demo_now">Try Demo now</div>

        </div>
        <div class="middle_section_wrapper">
            <img class="middle_section_image" src="assets/la/middle_section_image.png"/>
        </div>
        <div class="last_Section_wrapper">
            <img class="last_section_right" src="assets/la/last_section_right.png"/>
            <img class="last_section_left" src="assets/la/last_section_left.png"/>
            <img class="last_section_image" src="assets/la/last_section_image.png"/>
        </div>
    </div>
</div>
