import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  counter1 = 0
  counter2 = 0
  counter3 = 0
  counter4 = 0
  current_year = new Date().getFullYear()
  active_timeline = 0
  active_timeline_interval: any = null
  isMobileNav = false;
  
  constructor () {}

  servicesHovered () {}

  statisticsAnimationCounter () {
    const counters = document.querySelectorAll('.statistics-animation-counter')
    const speed = 100 // The lower the slower

    counters.forEach(counter => {
      const updateCount = () => {
        //@ts-ignore
        const target = +counter.getAttribute('data-target')
        //@ts-ignore
        const count = +counter.innerText

        // Lower inc to slow and higher to slow
        const inc = Math.ceil(target / speed)

        // Check if target is reached
        if (count < target) {
          // Add inc to count and output in counter
          //@ts-ignore
          counter.innerText = count + inc
          // Call function every ms
          setTimeout(updateCount, 1)
        } else {
          //@ts-ignore
          counter.innerText = target
        }
      }

      updateCount()
    })
  }

  ngOnInit (): void {
    screen.width > 992 ? this.isMobileNav = false :  this.isMobileNav = true;
    this.scrollToTop();
    // this.animateValue(0, 85, 2000)
    this.statisticsAnimationCounter()
    this.active_timeline_interval = setInterval(() => {
      if (this.active_timeline == 2) {
        //@ts-ignore
        document.getElementById('v-pills-home3-tab').classList.remove('active')
        //@ts-ignore
        document.getElementById('v-pills-home3').classList.remove('active')
        //@ts-ignore
        document.getElementById('v-pills-home3').classList.remove('show')
        //@ts-ignore
        document.getElementById('v-pills-home-tab').classList.add('active')
        //@ts-ignore
        document.getElementById('v-pills-home').classList.add('active')
        //@ts-ignore
        document.getElementById('v-pills-home').classList.add('show')
        this.active_timeline = 0
      } else if (this.active_timeline == 0) {
        //@ts-ignore
        document.getElementById('v-pills-home-tab').classList.remove('active')
        //@ts-ignore
        document.getElementById('v-pills-home').classList.remove('active')
        //@ts-ignore
        document.getElementById('v-pills-home').classList.remove('show')
        //@ts-ignore
        document.getElementById('v-pills-home2-tab').classList.add('active')
        //@ts-ignore
        document.getElementById('v-pills-home2').classList.add('active')
        //@ts-ignore
        document.getElementById('v-pills-home2').classList.add('show')
        this.active_timeline = 1
      } else if (this.active_timeline == 1) {
        //@ts-ignore
        document.getElementById('v-pills-home2-tab').classList.remove('active')
        //@ts-ignore
        document.getElementById('v-pills-home2').classList.remove('active')
        //@ts-ignore
        document.getElementById('v-pills-home2').classList.remove('show')
        //@ts-ignore
        document.getElementById('v-pills-home3-tab').classList.add('active')
        //@ts-ignore
        document.getElementById('v-pills-home3').classList.add('active')
        //@ts-ignore
        document.getElementById('v-pills-home3').classList.add('show')
        this.active_timeline = 2
      }
    }, 4000)
  }

  clearTimelineAutoInterval () {
    clearInterval(this.active_timeline_interval)
  }

  ngOnDestroy () {
    clearInterval(this.active_timeline_interval)
  }

  startCounter () {}

  //   animateValue (start: any, end: any, duration: any) {
  //     let startTimestamp: any = null
  //     const step = (timestamp: any) => {
  //       if (!startTimestamp) startTimestamp = timestamp
  //       const progress = Math.min((timestamp - startTimestamp) / duration, 1)
  //       this.counter1 = Math.floor(progress * (end - start) + start)
  //       // if (progress < 1) {
  //       //   window.requestAnimationFrame(step)
  //       // }
  //     }
  //     // window.requestAnimationFrame(step)
  //   }
  // }

  // const obj = document.getElementById('value')
  // animateValue(obj, 100, 0, 5000)
 

  scrollToTop(){
    const element = document.querySelector('.about-us-page-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
