<div class="technology-consulting-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>
    <div class="hero-wrapper">
        <!-- <section class="navbar_wrapper" id="navbar_wrapper_section">
            <nav class="navbar navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <img id="ency_logo" alt="ency logo" src="assets/ency-logo-no-bg.webp" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link active f-18 fw-500" aria-current="page" href="#">About us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Resourcing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Domain</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Engagement model</a>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <button class="btn btn-success contact_us_button" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </nav>
        </section> -->
        <app-navbar [isNavImageBlack]="true" class="desktop-nav"></app-navbar>
    </div>

    <div class="heading-wrapper">
        <p id="heading1">We help you realize your digital ideas</p>
        <br />
        <p id="heading2">Our consulting services help you think through the strategic issues and fully align technology
            to your overall purpose and business objectives.</p>
        <br />
        <ul class="heading-wrapper_list">
            <li>We partner with you to help you transform the way you use technology to achieve your digital goals.</li>
            <li>Whether you are looking to automate, leverage, modernize, or have end-to-end transformation, our
                technology-led services help you take full advantage of new and emerging technologies while leveraging
                your existing technology investments.</li>
            <li>We assess your current IT gaps and map out a roadmap aligned to your business and organizational goals
                with effective implementation and adoption of technology.</li>
            <li>For your unique requirements and objectives, we navigate the digital landscape and identify how
                technology can be best used to give a competitive edge.</li>
        </ul>
    </div>

    <div class="all-timelines-wrapper">
        <div class="first-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-middle-animation-left"
                    src="assets/technical-consulting/timeline-circle-middle-animation.gif" />
                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Enterprise Technology
                            Strategy
                        </p>
                        <p id="content">
                            Technology remains an integral part of every organization and contributes to overall
                            business success. For business continuity and solving strategic problems an enterprise-wide
                            technology strategy has to be effective enabling everyone to work smarter and faster with
                            the right technology.
                            <br><br>
                            We understand the breadth of technology that exists and can help evaluate/prioritize
                            technology investments which can help highlight technology gaps and opportunities essential
                            for your organization. Systems, platforms, and tools shared across an organization need to
                            meet
                            current and future needs.
                            <br>
                            <br>
                            We help you frame a clear strategy how technology can contribute to the overall business
                            with
                            a custom IT roadmap establishing time-bound and measurable objectives.
                        </p>
                    </div>
                    <img id="right-image" src="assets/technical-consulting/enterprise-tech-img.jpeg" />
                    <!-- <img id="between-img-animation-right" src="assets/technical-consulting/between-img-animation.gif" /> -->
                </div>
                <div class="content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Digital Transformation
                        </p>
                        <p id="content">
                            Digital transformation is necessary to address an organization’s specific challenges and
                            demands by
                            replacing existing business processes with new practices. The goal is to improve customer
                            experience, remove process noise, digitalize processes, increasing productivity, elevating
                            profitability or achieve competitive advantage.
                            <br><br>
                            We have you covered from identifying strategy to developing & implementing change to
                            sustaining digital adoption for continued business benefit. We will assess your landscape
                            and identify pain points, gaps, and improvement areas to define a strategy roadmap with
                            measurable quick wins and long-term goals.
                            <br><br>
                            We provide services in building an IT Strategy/roadmap and technology assessment, business
                            process management & re-engineering, governance setup, software services to change
                            management and training followed by consultation for continued evaluation and assessment.
                        </p>
                    </div>
                    <img id="right-image" src="assets/technical-consulting/digital-transform-img.png" />
                </div>
            </div>
            <img id="between-timeline-animation1" src="assets/technical-consulting/big-cube-anim.gif" />
            <img id="between-timeline-animation2" src="assets/technical-consulting/small-cube-anim.gif" />
        </div>



        <div class="second-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-middle-animation-right"
                    src="assets/technical-consulting/timeline-circle-middle-animation.gif" />
                <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <div class="content1-wrapper">
                    <img id="right-image" src="assets/technical-consulting/tech-dev-img.png" />
                    <img id="between-img-animation-left" src="assets/technical-consulting/between-img-animation.gif" />
                    <div class="inner-content">
                        <p id="heading">
                            Technological Development
                        </p>
                        <p id="content">
                            Technology has revolutionized the way we conduct business. It is ever evolving, and newly
                            emerging tech may provide better solutions in resolving business challenges. To sustain
                            operation efficiency, improved profitability, user experience and competitive advantage it's
                            important to take advantage of new developments.
                            <br>
                            <br>
                            Leverage our technology expertise and extensive knowledge to evaluate the health of your
                            technology functions. Health check would cover effectiveness, performance and
                            value addition by technologies which are used. We will also Identify risk to growth or
                            sustainability and discover improvement opportunities.
                            <br>
                            <br>
                            Our commitment to technology helps us adopt and excel in varied technologies in minimal
                            time. Our experts will help you identify and assess your technology needs while introducing
                            you to the latest in tech.
                        </p>
                    </div>

                </div>
                <div class="content2-wrapper">
                    <img id="right-image" src="assets/technical-consulting/enhanced-opr-img.png" />

                    <div class="inner-content">
                        <p id="heading">
                            Technology Change Management
                        </p>
                        <p id="content">
                            Technology alone cannot solve business problems; it has to be accepted and used effectively.
                            Successful change management begins with understanding the problem and aligning people,
                            processes, and technology to attain an efficient and effective outcome.
                            <br>
                            <br>
                            Each initiative may face resistance and hinges on successful adoption. We help you asses
                            risk tolerance across your organization in all three streams (People, Processes and
                            Technology) and devise a plan driving change from inception to fruition.
                            <br>
                            <br>
                            By considering every individual as a stakeholder we influence change in the right direction.
                            By making small change deliverables continually & progressively followed by effective
                            cross-functional communication and train & educate employees we ensure your changes go well.
                        </p>
                    </div>

                </div>
            </div>
            <!-- <img id="between-timeline-animation2" src="assets/business-consulting/timeline-animation.gif" /> -->
        </div>

        <!-- <div class="third-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-middle-animation-left"
                    src="assets/technical-consulting/timeline-circle-middle-animation.gif" />
                <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Business Continuity
                        </p>
                        <p id="content">
                            At ency, we know that in today's fast-paced world, mobile applications are critical to your
                            success. That's why we specialize in developing and ensuring quality assurance for mobile
                            apps that meet the demands of your business. With our expertise, you can stay relevant,
                            responsive, and avoid the severe consequences of mobile app failures on your corporate
                            reputation
                        </p>
                    </div>
                    <img id="right-image" src="assets/technical-consulting/business-cont-img.png" />
                    <img id="between-img-animation-right" src="assets/technical-consulting/between-img-animation.gif" />
                </div>
                <div class="content2-wrapper content3-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Scaling IT Infrastructure
                        </p>
                        <p id="content">
                            At ency, we know that in today's fast-paced world, mobile applications are critical to your
                            success. That's why we specialize in developing and ensuring quality assurance for mobile
                            apps that meet the demands of your business. With our expertise, you can stay relevant,
                            responsive, and avoid the severe consequences of mobile app failures on your corporate
                            reputation
                        </p>
                    </div>
                    <img id="right-image" src="assets/technical-consulting/scale-it-infra.png" />
                </div>
            </div>
            <img id="between-timeline-animation1" src="assets/technical-consulting/big-cube-anim.gif" />
            <img id="between-timeline-animation2" src="assets/technical-consulting/small-cube-anim.gif" />
        </div>


        <div class="second-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-middle-animation-right"
                    src="assets/technical-consulting/timeline-circle-middle-animation.gif" />
                <img id="timeline-circle" src="assets/business-consulting/timeline-circles.png" />
                <div class="content1-wrapper">
                    <img id="right-image" src="assets/technical-consulting/adopting-emerge-img.png" />
                    <img id="between-img-animation-left" src="assets/technical-consulting/between-img-animation.gif" />
                    <div class="inner-content">
                        <p id="heading">
                            Adopting Emerging Tech
                        </p>
                        <p id="content">
                            At ency, we know that in today's fast-paced world, mobile applications are critical to your
                            success. That's why we specialize in developing and ensuring quality assurance for mobile
                            apps that meet the demands of your business. With our expertise, you can stay relevant,
                            responsive, and avoid the severe consequences of mobile app failures on your corporate
                            reputation
                        </p>
                    </div>

                </div>
                <div class="content2-wrapper">
                    <img id="right-image" src="assets/technical-consulting/change-manage-img.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Technology Change
                            Management
                        </p>
                        <p id="content">
                            At ency, we know that in today's fast-paced world, mobile applications are critical to your
                            success. That's why we specialize in developing and ensuring quality assurance for mobile
                            apps that meet the demands of your business. With our expertise, you can stay relevant,
                            responsive, and avoid the severe consequences of mobile app failures on your corporate
                            reputation
                        </p>
                    </div>

                </div>
            </div>
          </div> -->
    </div>




</div>