
<div class="cookies-main" id="container">
    <app-navbar class="black-links"></app-navbar>
    <div class="inner_content"><h1>COOKIE POLICY</h1>
    <p>Below is information about how ency Consulting and its affiliates (“we”) use cookies and other similar technology
        on this website.</p>
    <div>This policy is effective as of July 12, 2023. Please note that this privacy statement will be updated from time
        to time.
        <br>
        We can place cookies and other similar technology on your device, including mobile device, in accordance with
        your preferences set on our cookie consent manager. Depending on your settings in our cookie consent manager on
        your mobile device, the following information may be collected through cookies or similar technology: your
        unique device identifier, mobile device IP address, information about your device’s operating system, mobile
        carrier and your location information (to the extent permissible under applicable law).
    </div>


    <h3>What are cookies?</h3>
    <div>Cookies are text files containing small amounts of information which are downloaded to your computer or mobile
        device when you visit a site and allow a site to recognize your device. Cookies managed by ency Consulting only
        are called “first party cookies” whereas cookies from third parties are called “third party cookies” as
        explained below.</div>
    <h3>Why do we use cookies and similar technologies?</h3>
    <div>Cookies do a lot of different jobs, such as letting you navigate between pages efficiently, remembering your
        preferences and generally improving the user experience. They can also help to ensure that the advertisements
        you see online are more relevant to you and your interests. In addition, cookies can help us to analyze the use
        of our websites and online content (analytics cookies) and they can also facilitate/track the interaction on our
        websites and online content with social media (e.g. links to social media sites, like buttons, etc.).</div>
    <h3>Does ency Consulting use cookies for marketing and analytics?</h3>
    <div>Yes, we may use information collected from our cookies to identify user behavior and to serve content and
        offers based on your profile, and for the other purposes described below, to the extent legally permissible in
        certain jurisdictions.

        In other cases, we can associate cookie information (including information from cookies placed via our
        advertisements on third party sites) with an identifiable individual. For example:</div>

    <br>

    <ul>
        <li>­ If we send you a targeted email which includes web beacons, cookies or similar technologies we will know
            whether you open, read, or delete the message.</li>
        <li>­ When you click a link in a marketing e-mail you receive from ency Consulting, we will also use a cookie to
            log what pages you view and what content you download from our websites, even if you are not registered at
            or signed into our site.</li>
        <li>­ Combining and analyzing personal data – As described above, we may combine data from publicly available
            sources, and from our different e-mail, website, and personal interactions with you this includes
            information collected across our different websites such as our careers and corporate sites and information
            collected when you sign-up or log on to our sites or connect to our sites using your social media
            credentials (such as LinkedIn). We combine this data to better assess your experience with ency Consulting
            and to perform the other activities described throughout our privacy policy.</li>
    </ul>
    <!-- <br> -->
    <h3>Do you use any cookies from third party companies?</h3>
    <div>Some cookies, web beacons and other tracking and storage technologies that we use are from third party
        companies (third party cookies), such as Facebook, Microsoft, Marketo Munchkin Tracking, Twitter, Knotch,
        YouTube, Instagram, Yoptima and Linkedin Analytics to provide us with web analytics and intelligence about our
        sites which may also be used to provide measurement services and target ads. These companies use programming
        code to collect information about your interaction with our sites, such as the pages you visit, the links you
        click on and how long you are on our sites. This code is only active while you are on an ency Consulting
        website. For more information on how these companies collect and use information on our behalf, please refer to
        their privacy policies: Facebook at Facebook <a target="_blank" href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Data Policy</a> Google (including YouTube) at <a  target="_blank" href="https://policies.google.com/?hl=en">Google Privacy & Terms</a>,
        Microsoft at <a target="_blank" href="https://privacy.microsoft.com/en-us/privacystatement">Microsoft Privacy Statement</a>, Marketo at Linkedin at <a  target="_blank" href="https://www.linkedin.com/legal/privacy-policy">Linkedin Privacy Policy</a>, Twitter at <a target="_blank" href="https://twitter.com/en/privacy">Twitter
            Privacy Policy</a>, Instagram at <a target="_blank" href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect">Instagram Data Policy</a></div>

    <h3>What if I don’t want cookies or similar tracking technologies?</h3>
    
    <div>Please bear in mind that deleting and blocking cookies may have an impact on your user experience. If you have
        any questions now or during your visit, please submit your request through our <a href='/contact-us#form-wrapper' class="label_card  fw-700" style="cursor:pointer;text-decoration: none;">Contact Us form</a>..</div>
    </div>


    </div>