declare global {
  interface Window {
      appSettings: any
  }
}

export const apiBaseUrl = window.appSettings.apiBaseUrl

export const sendMail:string = apiBaseUrl + "sendMail"

export const environment = {
  production: false,
  sendMail
}