import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { MetaServiceService } from './pages/meta-service.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ency-angular';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaServiceService
  ) {}
  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap(({title,description,keywords,robots}: Data) => {
           this.metaService.updateTitle(title);
           this.metaService.updateDescription(description);
           this.metaService.updateKeywords(keywords);
           this.metaService.updateRobots(robots);
         })
      ).subscribe();
  }
}
