import { animate, style, transition, trigger } from '@angular/animations'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
  // animations: [
  //   trigger('fade', [
  //     transition('void => active', [
  //       // using status here for transition
  //       style({ opacity: 0 }),
  //       animate(1000, style({ opacity: 1 }))
  //     ]),
  //     transition('* => void', [animate(1000, style({ opacity: 0 }))])
  //   ])
  // ]
})
export class HomepageComponent implements OnInit {
  //social media links
  linkedInd=" https://www.linkedin.com/company/encycg";
  fb="https://www.facebook.com/encyConsulting";
  twitter="https://twitter.com/encyconsulting?lang=en";
  levelImageSrc = 'assets/homepage-multiple-levels/1.webp';
  // helpClientImageSrc = 'assets/homepage-helpclients/our_team.webp'
  helpClientImageSrc = 'assets/ency-advantage/strategic_circle.png'
  socialMedia(fb:any){
    window.open(fb);
  }
  // SOFTWARE SERVICES SECTION BELOW
  software_services_active = 0
  software_services_list = [
    {
      sno: 1,
      heading: 'Application Development ',
      bullet1: ["Custom Software Development","Web App Development"],
      bullet2:["Mobile App Development","Technology Upgrades"],
  
      image:'../../../assets/homepage-software-services/bespoke-software-development.webp',
      fragment: 'application_development'
    },
    {
      sno: 2,
      heading: 'Solutions & Services',
      bullet1: ["Cloud Solutions","DevOps Consulting","Integration Solutions"],
      bullet2:["Legacy Software Services","Software Support Services"],
    
      image:'../../../assets/homepage-software-services/web_development.webp',
      fragment: 'solutions_services'
    },
    {
      sno: 3,
      heading: 'Data Science & AI / ML',
      bullet1: ["Data Science","BI & Data Analytics"],
      bullet2:["Artificial Intelligence","Machine Learning"],
      image:'../../../assets/homepage-software-services/mobile-app-development.webp',
      fragment: 'data_science'
    },
    // {
    //   sno: 4,
    //   heading: 'Cloud based solutions',
    //   content:
    //     'We offer secure, scalable, and cost-effective cloud-based software solutions that enable you to manage your business operations from anywhere at any time.',
    //   image:'../../../assets/homepage-software-services/cloud_based_solution.webp'
    // },
    // {
    //   sno: 5,
    //   heading: 'E-commerce Solutions',
    //   content:
    //     'We create secure and easy-to-use e-commerce solutions that offer a seamless shopping experience for your customers, allowing you to sell your products or services online',
    //   image:'../../../assets/homepage-software-services/e- commerce-solution.webp'
    // },
    // {
    //   sno: 6,
    //   heading: 'Business Intelligence',
    //   content:
    //     'We create software for data gathering and analysis to improve business decision-making',
    //   image:'../../../assets/homepage-software-services/business_intelligence.webp'
    // },
    // {
    //   sno: 7,
    //   heading: 'Software Integration',
    //   content:
    //     'we help clients Integrate different software applications to enable them to work together seamlessly.',
    //   image:'../../../assets/homepage-software-services/software-integration.webp'
    // },
    // {
    //   sno: 8,
    //   heading: 'Quality Assurance and Testing',
    //   content:
    //     'We provide software testing services to ensure bug-free software applications meet quality standards. ',
    //   image:'../../../assets/homepage-software-services/quality-assurance-and-testing.webp'
    // },
    // {
    //   sno: 9,
    //   heading: 'Support and Maintenance',
    //   content:
    //     'We provide continues ongoing support and maintenance services for software applications to ensure they are up-to-date and secure ',
    //   image:'../../../assets/homepage-software-services/customer_support.webp'
    // }
  ]
  // set the currently clicked index for the "Software Services" section
  software_services_set (index: number) {
    this.software_services_active = index
  }

  // OUR OFFERINGS SECTIONS BELOW
  our_offerings_active = 0
  our_offerings_list = [
    {
      sno: 1,
      heading: 'Business Consulting',
      bullet:[],
      content:
        'ency joins your team bringing in deep expertise and best practices you need. We help you build a digital roadmap that prioritizes the right projects and investments along with delivering systematic improvements contributing to operational enhancements ',
      labels: [
        {label:'Business Process Management',link:'/business-consulting',section:'business_managment'},
        {label:'P3M Consulting',link:'/business-consulting',section:'pm_consulting'},
        {label:'Agile Adoption',link:'/business-consulting',section:'agile_adoption'},
        {label:'Digital Adoption',link:'/business-consulting',section:'digital_adoption'},
      
       
      ],
      link: 'business-consulting',
      image:'../../../assets/homepage-our-offerings/business_consulting.webp'
    },
    {
      sno: 2,
      heading: 'Technical Consulting',
      bullet:[],
      content:
        'ency offers a full set of consulting capabilities to help you think through the strategic issues and fully align technology to your overall purpose and core business objectives. ',
      labels: [
        {label:'Technology Consulting',link:'/technology-consulting', section:''},
        {label: 'Software Services',link:'/software-services', section:''},
        {label:'Technology Stacks',link:'/tech-stack', section:''},
     
      ],
      link: 'technology-consulting',
      image:'../../../assets/homepage-our-offerings/technology_consulting.webp'
    },
    {
      sno: 3,
      heading: 'Resourcing',
      bullet:[],
      content:
        'With our global eco-system, we can enable the right experts, at the right moment and at the required location for you.We support your strategy with top-notch talent, be it short term or on-going, hourly, or full time, onsite or off site, permanent hire, or contract to hire ',
      labels:[
      {label:'Top-notch talent ',link:'/resourcing',section:''},
      {label: 'Global reach',link:'/resourcing',section:''},
      {label:'Hiring/Contracting',link:'/resourcing',section:''},
    ],
      link: 'resourcing',
      image:'../../../assets/homepage-our-offerings/resources.webp'
    },
    {
      sno: 4,
      heading: 'Solutions',
      bullet:[
        'Shipping and logistics',
        'Education and learning',
        'CMS',
        'E-Commerce ',
        'AI/Ml'
        
      ],
      content:
        'Our Solutions and Services powers digital transformation through innovative and sustainable business software solutions.',
  
      labels:[
        {label:'Shipping and logistics',link:'/domain-solutions',section:'shipping'},
        {label: 'Education and learning',link:'/domain-solutions',section:'education'},
        {label:'CMS',link:'/domain-solutions',section:'cms'},
        {label:'E-Commerce',link:'/domain-solutions',section:'ecommerce'},
        {label:'AI/ML',link:'/domain-solutions',section:'ai'},
      ],
      link: 'domain-solutions',
      image:'../../../assets/homepage-our-offerings/technology.webp'
    },
    // {
    //   sno: 5,
    //   heading: 'Products',
    //   content:
    //     'We develop high-quality software products based on client needs and vision. With the know-how, capacity, and industry experience we set new standards. We take full advantage of our fast, innovative, and solution-oriented work focused on user experience, scalability, and results',
    //   labels: ['Lucency','LMS'],
    //   link: 'products',
    //   image:'../../../assets/homepage-our-offerings/technology.webp'
    // }
  ]
  our_offerings_set (index: number) {
    this.our_offerings_active = index
  }

  // SOFTWARE SERVICES SECTION BELOW
  technologies_active = 0
  technologies_list = [
    {
      title: 'Cloud',
      content:
        'Cloud computing has revolutionized the way businesses and individuals use technology. Instead of relying on physical hardware and servers, cloud computing enables users to access computing resources and services over the internet. Our team is dedicated to helping you understand cloud computing and its various applications. Whether you are a business looking to migrate to the cloud or an individual interested in cloud-based services, we provide you with the information you need to make informed decisions',
      labels: [
        'Cloud Migration',
        'Cloud Solution Engineering',
        'Cloud Architecture',
        'Cloud Consulting'
      ]
    },
    {
      title: 'Automation',
      content:
        "We at ency help businesses understand automation's benefits and potential, providing news and insights about the latest technologies and trends. Whether you're a business looking to automate, or an individual interested in the impact on the workforce, we've got you covered. ",
      labels: ['Devops Services', 'QA Automation Services']
    },
    {
      title: 'Data AI',
      content:
        "We at ency help businesses leverage the power of data and AI to achieve new levels of efficiency and productivity. Our services include data analysis, machine learning, and custom software development. With extensive experience in the field, we're committed to staying at the forefront of this rapidly evolving industry. ",
      labels: [
        'Data Engineering',
        'Data Consulting ',
        'Business Intelligence ',
        'Big Data Services ',
        'ML and AI Services ',
        'Data ETL',
        'Data Migrations',
        'Data Scrapping'
      ]
    },
    {
      title: 'Mobile & web app',
      content:
        'We specialize in mobile and web development for businesses of all sizes and industries. Our team creates custom solutions that deliver results, from intuitive mobile apps to user-friendly websites. We stay up to date with the latest trends and technologies and offer ongoing support to keep your app or website relevant. ',
      labels: [
        'Mobile and Web app consultation',
        'Mobile and Web app development',
        'Mobile and Web app customizations'
      ]
    }
  ]
  // set the currently clicked index for the "Software Services" section
  technologies_set (index: number) {
    this.technologies_active = index
  }

  // SOFTWARE SERVICES SECTION BELOW
  our_current_works_active = 0
  our_current_works_list = [
    {
      breadcrumb: 'Data Science/Global Delivery',
      title: 'Solution for Life Science R&D lifecycles',
      content1:
        'Maximizing use of latest technology to develop a visualized semantic search and analytics engine that can seamlessly operate across fully federated data source',
      content2:
        'We are also building strategy and global delivery for entire product life cycle. Establishing standardized,salable, productive and effective processes to maximize quality minimize cost and achieve in time delivery',
      image:'../../../assets/homepage-our-current-works/life-science.webp'
  
    },
    {
      breadcrumb: 'Advisory / Product Engineering / Delivery',
      title: 'Solution to provide scientific guidance and coaching',
      content1:
        'Challenged with low quality and inconsistent delivery posed risk for their 3K+ paying customers. We reengineered  their Project Delivery and Product Engineering processes to achieve consistent, effective and scalable results on time every time.',
      content2:
        'Coaching, mentoring and training the team helped to bring in the change faster.',
      image:'../../../assets/homepage-our-current-works/learning-education-ideas-insight.webp'
    },
    {
      breadcrumb: 'Technical Consulting / Delivery',
      title: 'eLearning Content development solution',
      content1:
        'eLearning space requires continues upgrades and is challenged with quick turnarounds.',
      content2:
        'We established a technical team to build contents/courses/games to facilitate learning needs. Established processes for development and delivery to facilitate good quality and quick deliveries.',
      image:'../../../assets/homepage-our-current-works/eLearning-Education.webp'
    }
  ]

  current_works_automatic: any = null;
  help_clients_active_automatic: any = null;
  levelImageSrc_automatic: any = null;
  // set the currently clicked index for the "Software Services" section
  our_current_works_set (index: number) {
    clearInterval(this.current_works_automatic)
    this.our_current_works_active = index
  }
  nextItemInTimeline (index: number) {
    if (this.our_current_works_active == 0 && index < 0) {
      this.our_current_works_active = 2
      return
    }
    this.our_current_works_active = (this.our_current_works_active + index) % 3
  }
//help client section below
  help_clients_active = 0;
  levelImageSrc_active = 1;
  // help_clients_list = [
  //   {
  //     title: 'Cloud',
  //     content:
  //       'Cloud computing has revolutionized the way businesses and individuals use technology. Instead of relying on physical hardware and servers, cloud computing enables users to access computing resources and services over the internet. Our team is dedicated to helping you understand cloud computing and its various applications. Whether you are a business looking to migrate to the cloud or an individual interested in cloud-based services, we provide you with the information you need to make informed decisions',
  //     labels: [
  //       'Cloud Migration',
  //       'Cloud solution engineering',
  //       'Cloud architecture',
  //       'Cloud consulting'
  //     ]
  //   },
   
  // ]
  help_clients_set (index: number, help: string) {
    this.help_clients_active = index;
    this.helpClientImageSrc = 'assets/ency-advantage/strategic_circle.png';
  }


  increase_clients_set () {
    if (this.help_clients_active < 6) this.help_clients_active += 1
    else if (this.help_clients_active == 6) this.help_clients_active = 0
  }

  decrease_clients_set () {
    if (this.help_clients_active > 0) this.help_clients_active -= 1
    else if (this.help_clients_active == 0) this.help_clients_active = 6
  }

  current_year = new Date().getFullYear()

  constructor () {
    this.current_year = new Date().getFullYear();
  }

  ngOnInit (): void {
    this.scrollToTop()
    this.current_works_automatic = setInterval(() => {
      //@ts-ignore
      document.getElementById('our_current_works_next_arrow').click()
    }, 3000)
    this.help_clients_active_automatic = setInterval(() => {
      if(this.help_clients_active < 6){
        this.help_clients_active++;
      } else {
        this.help_clients_active = 0;
      }
    }, 8000);
    this.levelImageSrc_automatic = setInterval(() => {
      if(this.levelImageSrc_active < 6){
        this.levelImageSrc_active++;
      } else {
        this.levelImageSrc_active = 1;
      }
      this.levelImageSrc = `assets/homepage-multiple-levels/${this.levelImageSrc_active}.webp`;
    }, 3000);
  }
  ngOnDestroy () {
    clearInterval(this.current_works_automatic);
    clearInterval(this.help_clients_active_automatic);
    clearInterval(this.levelImageSrc_automatic);
  }

 
   scrollToTop(){
    const screenWidth = screen.width
    const element = screenWidth > 992 ? document.querySelector('.desktop_screen') : document.querySelector('.mobile_screen');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
   }

   onLevelClick(level: string){
      this.levelImageSrc = `assets/homepage-multiple-levels/${level}.webp`;
   }

   getClass(sno: number){
    return sno === 2 ? 'explore_more_wrapper1' : ''
   }
}
