<div class="ency-advantage-main-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>
    <div class="ency-advantage-banner-section">
        <app-navbar [isNavImageBlack]="true" class="desktop-nav"></app-navbar>
        <div class="ency-advantage-content-wrapper">
            <div class="ency-advantage-heading">ency Advantage</div>
            <div class="ency-advantage-content">At ency, our consultants
                help you put your <span class="ideas-into-action">ideas into action</span>
            </div>

        </div>
    </div>

    <div class="heading-wrapper">
        <p id="main-heading">Combining extensive understanding with strong partnerships</p>
        <p id="sub-heading">We have built ency on the principle of being a trusted advisor and a partner to our
            customers advising them of the best possible solution for their business. We help them transform their
            business ideas into business values.</p>
        <img src="assets/ency-advantage/design.img.png" id="design-img-1" />
    </div>

    <!-- TIMELINES STARTING HERE -->
    <div class="all-timelines-wrapper">
        <!-- <div class="first-timeline-wrapper">
            <div class="timeline1-wrapper">
                <img id="timeline-circle" src="assets/ency-advantage/timeline-1-img.png" />
                

                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Industry expertise
                        </p>
                        <p id="content">
                            We bring extensive experience working with various industries like Healthcare, Advertising,
                            Retail, e-commerce, Media and entertainment, Sports and more. Our experts deliver tailored
                            solutions to drive business growth and goals of your company.
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/industry_expertise.jpg" />

                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
                <div class="content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Proven track record
                        </p>
                        <p id="content">
                            Our core strength is our team who brings in years of global diverse expertise to run
                            mission-critical initiatives combining extensive consulting and technology expertise with
                            pragmatic approach. We are commitment to deliver personalized solutions meeting your
                            business needs.
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/img-2.png" />
                </div>
            </div>
            <img id="between-timeline-animation1" src="assets/business-consulting/timeline-animation.gif" />
        </div> -->

        <div class="timeline-one-wrapper">
            <div class="timeline1-wrapper">
                <img id="timeline-circle" src="assets/ency-advantage/timeline-1-img.png" />
                <img id="timeline-circle" src="assets/ency-advantage/timeline-3-img.png" />
                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Securing Your Trust
                        </p>
                        <p lang="en" id="content">
                            Our unwavering dedication is underscored by the ISO/IEC 27001:2022 certification, affirming
                            the safeguarding of your data. Our comprehensive strategy involves meticulous risk
                            assessments, tailored controls, and alignment with industry best practices. Your trust is
                            paramount, and we consider it our solemn responsibility to ensure the utmost security of
                            your data.
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/securing_trust.png" />
                </div>
                <img id="cube-animation2" src="assets/ency-advantage/cube-animation.gif" />
              
                <div class="content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Industry Expertise
                        </p>
                        <p lang="en" id="content">
                            We bring extensive experience working with various industries like Healthcare, Advertising,
                            Retail, E-Commerce, Media and Entertainment, Sports and more. Our experts deliver tailored
                            solutions to drive business growth and goals of your company.
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/industry_expertise.jpg" />
                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
       
             
                <div class="content4-wrapper content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Proven Track Record
                        </p>
                        <p lang="en" id="content">
                            Our core strength is our team who brings in years of global diverse expertise to run
                            mission-critical initiatives combining extensive consulting and technology expertise with
                            pragmatic approach. We are committed to deliver personalized solutions meeting your
                            business needs.
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/img-2.png" />
                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
            </div>
        </div>


        <div class="second-timeline-wrapper">
            <img src="assets/ency-advantage/design.img.png" id="design-img-2" />
            <div class="timeline1-wrapper">
                <!-- <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" /> -->

                <img id="timeline-circle" src="assets/ency-advantage/timeline-2-img.png" />
                <div class="content1-wrapper">
                    <img id="right-image" src="assets/ency-advantage/client_focused.jpg" />
                    <div class="inner-content">
                        <p id="heading">
                            Customer Focused
                        </p>
                        <p lang="en" id="content">
                            At our core, we're all about you. We listen, tailor solutions to your unique needs, and work
                            together as partners to achieve your desired outcomes. We take time to understand your
                            businesses needs, and work closely to develop strategies that drive results.
                        </p>
                    </div>

                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
                <div class="content2-wrapper">
                    <img id="right-image" src="assets/ency-advantage/img-4.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Unique Approach
                        </p>
                        <p lang="en" id="content">
                            We advocate your needs and work in your best interest going over and beyond our service
                            offerings. Our unique approach combines innovation, creativity, and collaboration to solve
                            problems and drive success. We develop a tailored strategy that aligns with your objectives.
                        </p>
                    </div>

                </div>
                
            </div>
            <!-- <img id="between-timeline-animation2" src="assets/business-consulting/timeline-animation.gif" /> -->
        </div>

        <div class="third-timeline-wrapper">
            <div class="timeline1-wrapper">
                <!-- <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" /> -->

                <img id="timeline-circle" src="assets/ency-advantage/timeline-2-img.png" />
                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Exceptional Support
                        </p>
                        <p lang="en" id="content">
                            We believe that trust and strong relationships are the foundation of success. We are
                            committed to providing service every step of the way. With our exceptional customer service
                            and support, we're committed towards your success
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/img-5.png" />
                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
                <div class="content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Results-Driven
                        </p>
                        <p lang="en" id="content">
                            We are accountable for our work, so our focus is on delivering value and aligning to your
                            goals. We continuously challenge ourselves to evaluate and refine our solutions which are
                            feasible, grounded in reality and tied to the business goals of your company
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/result_driven.jpg" />
                </div>
            </div>
        </div>



        <div class="fourth-timeline-wrapper">
            <img src="assets/ency-advantage/design.img.png" id="design-img-2" />
            <img src="assets/ency-advantage/globe-anim.gif" id="globe-anim" />
            <img src="assets/ency-advantage/globe-anim-inner.gif" id="globe-anim-inner" />

            <div class="timeline1-wrapper">
                <!-- <img id="circle-animation-1" src="assets/business-consulting/timeline-circle-animation.gif" />
                <img id="circle-animation-2" src="assets/business-consulting/timeline-circle-animation.gif" /> -->

                <img id="timeline-circle" src="assets/ency-advantage/timeline-2-img.png" />
                <img id="timeline-circle" src="assets/ency-advantage/timeline-3-img.png" />
                <div class="content1-wrapper">
                    <img id="right-image" src="assets/ency-advantage/agile.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Agile / Adaptable
                        </p>
                        <p lang="en" id="content">
                            We draw inspiration from the varied company backgrounds and cultures we encounter in our
                            engagements. It helps us to seamlessly engage with your teams and leadership to work
                            effectively. We are flexible and adaptable to changing priorities and can act quickly to
                            align.
                        </p>
                    </div>

                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />

                <div class="content2-wrapper">
                    <img id="right-image" src="assets/ency-advantage/transparency.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Transparency
                        </p>
                        <p lang="en" id="content">
                            We ensure honesty and transparency in reporting and communication. This builds trust,
                            clarity, and collaboration boosting higher efficiency and productivity. Timely disclosure of
                            information helps make informed decisions and mitigate risk. </p>
                    </div>
                </div>
                <img id="cube-animation2" src="assets/ency-advantage/cube-animation.gif" />
                <div class="content3-wrapper">

                    <img id="right-image" style="width:26vw;" class="strategic_image"
                        src="assets/ency-advantage/strategic_circle.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Strategic Circle of Value
                        </p>
                        <p lang="en" id="content">
                            Our Strategic Circle of Value offers expertise in every stage of the life cycle of a
                            strategy from an idea to start giving continued business value.

                            We believe in being a partner and a team member, so instead of providing independent
                            services we build a single team with all the required Business and Technical expertise to
                            achieve your goals. We are as serious as you are.</p>
                    </div>
                    <!-- <img id="timeline-circle" src="assets/ency-advantage/timeline-2-img.png" /> -->

                </div>
            </div>
            <!-- <img id="between-timeline-animation2" src="assets/business-consulting/timeline-animation.gif" /> -->
        </div>
        <!-- <div class="fourth-timeline-wrapper">
            <img src="assets/ency-advantage/design.img.png" id="design-img-2" />
            <img src="assets/ency-advantage/globe-anim.gif" id="globe-anim" />
            <img src="assets/ency-advantage/globe-anim-inner.gif" id="globe-anim-inner" />

            <div class="timeline1-wrapper">
               

                <img id="timeline-circle" src="assets/ency-advantage/timeline-2-img.png" />
                
                <div class="content1-wrapper">
                    <img id="right-image" src="assets/ency-advantage/agile.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Agile / Adaptable
                        </p>
                        <p id="content">
                             We draw inspiration from the varied company backgrounds and cultures we encounter in our
                            engagements. It helps us to seamlessly engage with your teams and leadership to work
                            effectively. We are flexibility and adaptable to changing priorities and can act quickly to
                            align. 
                        </p>
                    </div>

                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
                <div class="content2-wrapper">
                    <img id="right-image" src="assets/ency-advantage/agile.png" />
                    <div class="inner-content">
                        <p id="heading">
                            Agile / Adaptable
                        </p>
                        <p id="content">
                            We draw inspiration from the varied company backgrounds and cultures we encounter in our
                            engagements. It helps us to seamlessly engage with your teams and leadership to work
                            effectively. We are flexibility and adaptable to changing priorities and can act quickly to
                            align. </p>
                    </div>

                </div>
            </div>
        
        </div> -->

        <!-- <div class="third-timeline-wrapper fifth-timeline-wrapper">
            <div class="timeline1-wrapper">
              
                <img id="timeline-circle" src="assets/ency-advantage/timeline-2-img.png" />
                <div class="content1-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Transparency
                        </p>
                        <p id="content">
                            We ensure honesty and transparency in reporting and communication. This builds trust,
                            clarity, and collaboration boosting higher efficiency and productivity. Timely disclosure of
                            information helps make informed decisions and mitigate risk </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/transparency.png" />
                </div>
                <img id="cube-animation" src="assets/ency-advantage/cube-animation.gif" />
                <div class="content2-wrapper">
                    <div class="inner-content">
                        <p id="heading">
                            Strategic Circle of Value
                        </p>
                        <p id="content">
                            Our Strategic Circle of Value offers expertise in every stage of the life cycle of a
                            strategy from an idea to start giving continued business value.

                            We believe in being a partner and a team member, so instead of providing independent
                            services we build a single team with all the required Business and Technical expertise to
                            achieve your goals. We are as serious as you are
                        </p>
                    </div>
                    <img id="right-image" src="assets/ency-advantage/strategic_circle.png" />
                </div>
            </div>
        </div> -->
    </div>
    <!-- TIMELINES ENDING HERE -->


</div>