<div *ngIf="pageLoading == false" class="main-career-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>
    <div class="heading-wrapper">
    
        <app-navbar [isNavImageBlack]="true"  class="desktop-nav"></app-navbar>
        <div class="heading-wrapper-content">
            <div class="all-reasons-text ">All reasons</div>
            <div class="to-bring-your-text">to bring your</div>
            <div class="career-here-text">Career here!</div>
            <a class="banner-view-buttons" [routerLink]="['/contact-us']" id="color-white" target="_blank"><button
                    class="open-positions-button" type="submit">View Open Positions</button></a>
            <img class="shape-behind-heading-image" src="assets/shape-behind-heading.png">
            <img class="shape-left-image" src="assets/shape-left-white.png">
            <img class="shape-right-image" src="assets/shape-right.png">
        </div>
    </div>



    <!---------------------------- why work with us ------------------------------------------>


    <div class="why-work-with-us-main-heading-wrapper">
        <img class="square-animation-image" src="assets/Rectangle1.gif">
        <div class="heading-with-line-wrapper">
            <div class="why-work-with-us-heading">Why work with us</div>
            <div class="line-ellipse-shape-wrapper">
                <img class="line" src="assets/line.png">
                <div class="solid-hollow-ellipse-container">
                    <img class="solid-ellipse-image" src="assets/solid-ellipse.png">
                    <img class="hollow-ellipse-image" src="assets/hollow-ellipse.png">
                </div>
            </div>
        </div>
        <div class="vertical-line-ellipse-outside-wrapper">
            <div class="vertical-line-ellipse-inside-wrapper">
                <img class="vertical-line" src="assets/vertical-line.png">
                <div class="ellipse-inside-ellipse-animation-wrapper">
                    <img class="ellipse-inside-ellipse" src="assets/ellipse-inside-ellipse.png">
                    <img class="ellipse-inside-ellipse-animation" src="assets/ellipse-inside-ellipse-animation.gif">
                </div>

                <img class="vertical-line" src="assets/vertical-line.png">
            </div>
        </div>
    </div>
    <a class="open-positions-wrapper " [routerLink]="['/contact-us']" id="color-white" target="_blank" style="cursor: pointer;">
        <div class="open-positions-text">Open
                Positions</div>
        <a [routerLink]="['/contact-us']" id="color-white" target="_blank"><img class="vector-image"
                src="assets/vector.png"></a>
        </a>

    <div class="team-one-wrapper">
        <div class="team-one-content">We offer a <span class="dynamic-work-text">dynamic work environment </span>that
            encourages growth and development. We believe
            in investing in our employees and offer <span class="opportunities-text">training and development
                opportunities </span>to help them reach
            their full potential.  </div>
        <img class="team-one-image" src="assets/team-one-image.png">
        <img class="one" src="assets/one.png">
    </div>
    <div class="team-one-wrapper team-two-wrapper">
        <img class="two" src="assets/two.png">
        <img class="team-one-image" src="assets/team-two-image.png">
        <div class="team-two-content-wrapper">
            <div class="team-two-content">
                At ency, we're committed to creating a positive work environment where our employees can
                thrive. We also offer a competitive salary and benefits packages as well as a range of other perks and
                benefits including:</div>
            <div class="bullet-point-wrapper">
                <!-- <div class="bullet-points">
                    <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                    <div class="bullet-points-text">Competitive compensation packages</div>
                </div> -->
                <div class="bullet-points">
                    <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                    <div class="bullet-points-text">Flexible work arrangements</div>
                </div>
                <div class="bullet-points">
                    <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                    <div class="bullet-points-text">Professional development opportunities</div>
                </div>
                <div class="bullet-points">
                    <img class="bullet-point-icon" src="assets/bullet-points-icon.png">
                    <div class="bullet-points-text">Fun team-building activities</div>
                </div>

            </div>
             
        </div>
    </div>
    <div class="team-one-wrapper team-three-wrapper">
        <img class="three" src="assets/three.png">
        <div class="team-one-content">We're looking for individuals who are passionate about consulting firms and are
            dedicated to delivering <span class="dynamic-work-text">exceptional results </span>for our clients. Whether
            you're an experienced professional or
            just starting your career, we welcome individuals who are <span class="opportunities-text">excited to learn,
                grow, and contribute to
                our team</span>  </div>
        <img class="team-one-image" src="assets/team-three-image.png">
        <img class="Rectangle2" src="assets/Rectangle2.gif">
    </div>





    <!--------------------------------life at ency -------------------------------------------->

    <div class="life-at-ency-main-wrapper">
        <div class="life-at-ency-heading">
            <img class="ency-logo-in-heading-image" src="assets/logo-ency-transparent.png">
            <div class="heading-after-logo">is fully Remote by choice</div>
        </div>
        <div class="life-at-ency-paragraph">ency opened the doors of freedom and flexibility by going Remote by choice.
            If you are
            dynamic, a go-better, and willing to grab opportunities that come your way, ency is the
            place to be. ency can be your playground where dreams take flight, ideas turn into
            innovations, and every goal achieved is celebrated</div>

        <div class="life-at-ency-hashtag-and-animation-wrapper">
            <img class="small-square-mixing-animation" src="assets/small-square-mixing-animation.gif">
            <div class="lifeAtency-hashtext">#lifeAtency</div>
            <img class="small-square-mixing-animation" src="assets/small-square-mixing-animation.gif">
        </div>
        <div class="life-at-ency-images-wrapper">
            <div class="life-at-ency-image-wrapper">
                <img class="lifeatency-team-images" src="assets/lifeatency-image-four.JPG">
                <img class="lifeatency-team-images" src="assets/lifeatency-image-six.JPG">
                <img class="lifeatency-team-images" src="assets/lifeatency-image-three.JPG">
            </div>
            <div class="life-at-ency-image-wrapper">
                <img class="lifeatency-team-images" src="assets/lifeatency-image-one.JPG">
                <img class="lifeatency-team-images" src="assets/lifeatency-image-five.JPG">
                <img class="lifeatency-team-images" src="assets/lifeatency-image-two.JPG">
            </div>
        </div>
    </div>

    <!-- ---------------------------Perks and Benefits---------------------------------------------- -->

    <div class="perks-and-benefits-main-wrapper">
        <div class="perks-and-benefits-inner-wrapper">
            <div class="perks-and-benefits-heading-wrapper">
                <div class="heading-1">
                    <div class="ellipse-line-shape-wrapper">
                     
                        <div class="prefix_style_circle_wrapper">
                            <div class="circle">
                                <div class="inner_circle"></div>
                            </div>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="Perks-and-benefits-heading-1">Perks &</div>
                </div>
                <div class="Perks-and-benefits-heading">Benefits <img class="small-square-jumping-animation"
                        src="assets/small-square-jumping-animation.gif"></div>
            </div>
          
        </div>
        <div class="perks-and-benefits-paragraph">
            At ency, we believe that every journey is unique. That's why we've designed a range of benefits to help our
            employees stay healthy and financially prepared at every turn. Whether you're just starting out or well on
            your way, we're here to support you on your path to success!
        </div>

        <div class="benefits-full-rows-wrapper">
            <!-- 1st full row -->
            <div class="first-row-wrapper">
                <div class="benefits">
                    <!-- 1st top left corner -->
                    <div class="p-n-b-inner-content-wrapper">
                        <div class="benefits-heading">Childcare &</div>
                        <div class="benefits-heading benefits-heading-space">parental leave</div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Flexible Work Schedule</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Generous Parental Leave</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="top-row-line"></div> -->
                <div class="benefits benefits-right">
                    <!-- 2nd top right corner -->
                    <div class="p-n-b-inner-content-wrapper">
                        <div class="benefits-heading">Time
                        </div>
                        <div class="benefits-heading benefits-heading-space"> Off Benefits</div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Paid Sick Days</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Paid Holidays</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- middle row -->
            <div class="middle-row-wrapper">
                <img class="layered-square-animation" src="../../../assets/layered-square-animation.gif" />
                <div class="middle-row-line"></div>
                <div class="logo_aninmation">
                    <img class="dotted_circle_animation" src="../../../assets/dotted_circle_animation.gif" />
                    <img class="ency_logo_circle" src="../../../assets/ency_logo_circle.png" />
                    <img class="ency_logo_text" src="../../../assets/ency-transparent-white.png" />
                </div>
                <div class="middle-row-line"></div>
                <img class="layered-square-animation" src="../../../assets/layered-square-animation.gif" />
            </div>

            <!-- 2nd -row -->
            <div class="first-row-wrapper">
                <div class="benefits">
                    <div class="benefits-inner-bottom-left p-n-b-inner-content-wrapper">
                        <div class="benefits-heading">Professional </div>
                        <div class="benefits-heading benefits-heading-space">Development Benefits</div>
                        <!-- bottom-left corner -->
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Continuing Education stipend
                            </div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Job Training & Conferences</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Mentorship program
                            </div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Paid industry certifications</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Time allotted for learning</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="top-row-line"></div> -->
                <!-- bottom right  -->
                <div class="benefits benefits-right">
                    <div class="benefits-inner-bottom-right p-n-b-inner-content-wrapper">
                        <div class="benefits-heading">Positive</div>
                        <div class="benefits-heading benefits-heading-space"> Culture </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Company outings</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Happy hours</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Friends outside of work</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Group brainstorming sessions</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Open door policy</div>
                        </div>
                        <div class="benefits-content-one-wrapper">
                            <div class="outer-circle">
                                <div class="inner-circle"></div>
                            </div>
                            <div class="Flexible-Work-Schedule-text">Remote Work Program</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- --------------------------------current positions ------------------------------------------------ -->
    <div class="current-openings-main-wrapper">
        <div class="current-openings-heading-wrapper">
            <div class="ellipse-line-shape-wrapper">
                <div class="solid-hollow-ellipse-container">
                    <img class="solid-ellipse-image" src="assets/solid-ellipse.png">
                    <img class="hollow-ellipse-image" src="assets/hollow-ellipse.png">
                </div>
                <img class="line" src="assets/line.png">
            </div>
            <div class="current-openings-heading">Current Openings</div>
        </div>
        <div class="current-opening-quote-wrapper">
            <div class="quote"><span class="double-quote">“</span> We're currently hiring for the following
                positions<span class="double-quote">“</span></div>
        </div>
        <div class="current-opening-paragraph">At ency, we're committed to creating a culture of inclusivity and
            respect. We welcome individuals from diverse backgrounds and experiences to apply for our open positions.
            Join us and help us deliver exceptional results for our clients while growing your career</div>
        <div class="open-positions-button-wrapper"><button class="open-positions-button" type="submit">
                <a [routerLink]="['/contact-us']" id="color-white" target="_blank">View Open Positions</a></button></div>
    </div>



</div>
<div *ngIf="pageLoading == true">
    <p class="loading-career-animation">
        Career
    </p>
</div>