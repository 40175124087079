import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

export function checkLimit(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        if (c.value.toString().length < min || c.value.toString().length > max) {
            return { 'range': true };
        }
        return null;
    };
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  current_year = new Date().getFullYear()
  contactUs: FormGroup = this.fb.group({
    yourname: ['', [Validators.required, checkLimit(0, 100)]],
    yourcompanyname: [''],
    phonenumber: [''],
    email: ['', [Validators.required, checkLimit(0, 100)]],
    subject: ['', [Validators.required, checkLimit(0, 100)]],
    message: ['', [Validators.required, checkLimit(0, 200)]]
  })
  loading = false

  constructor (private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute) {}

  // <!-- ----------------------------------------------------------------------------------------------->
  navItemDropDownMouseEnter (id: any) {
    document.getElementById(id)?.click()
  }

  navItemDropDownMouseLeave () {}

  leavingNavbar () {
    document.getElementById('mouse-leave-tab')?.click()
  }
  // <!-- ----------------------------------------------------------------------------------------------->

  ngOnInit (): void {
   
    if(!this.route.snapshot.fragment){
      this.scrollToTop()
    }else {
      this.scrollToElement()
    }
  }

  async checkEmail (input: any) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (input.match(validRegex)) {
      return true
    } else {
      alert('Please enter a valid email address')
      return false
    }
  }
  

  async onSubmit () {
    if (
      this.contactUs.value.yourname.length > 0 &&
      this.contactUs.value.email.length > 0 &&
      this.contactUs.value.subject.length > 0 &&
      this.contactUs.value.message.length > 0
    ) {
      const data = {
        name: this.contactUs.value.yourname,
        company_name: this.contactUs.value.yourcompanyname,
        phone_number: this.contactUs.value.phonenumber,
        email: this.contactUs.value.email,
        subject: this.contactUs.value.subject,
        message: this.contactUs.value.message
      }
      const emailRes = await this.checkEmail(this.contactUs.value.email)
      if (emailRes == false) return
      this.loading = true

      await this.http
        .post(environment.sendMail, data)
        .subscribe(
          (res: any) => {
            if (res.success == true) {
              window.alert(
                'Your response has been recorded. Thank you for contacting Us!'
              )
              this.contactUs.setValue({
                yourname: '',
                yourcompanyname: '',
                phonenumber: '',
                email: '',
                subject: '',
                message: ''
              })
              this.loading = false
            } else {
              window.alert(
                'Some error occured. Please contact the site owner directly on their email.'
              )
              this.loading = false
            }
          },
          (err: any) => {
            window.alert(
              'Some error occured. Please contact the site owner directly on their email.'
            )
            this.loading = false
          }
        )

      // STEP 6: RESET THE FORM IF RESPONSE WAS SUCCESS
    } else {
      window.alert('Please enter all required fields before submitting')
    }
  }
  scrollToTop(){
    const element = document.querySelector('.contact-us-main-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }

  scrollToElement(){
    const element = document.querySelector(`#${this.route.snapshot.fragment}`)
      if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      }
  }
}
