import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor() { }

  ngOnInit(): void {
    this.scrollToTop();
  }
  scrollToTop(){
    const element = document.querySelector('#container');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
}
}
