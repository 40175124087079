<div class="software-services-wrapper" id="container">
    <app-navbar [isNavImageBlack]="true" class="black-links mobile-nav"></app-navbar>
    <div class="hero-wrapper">
        <!-- <section class="navbar_wrapper" id="navbar_wrapper_section">
            <nav class="navbar navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <img id="ency_logo" alt="ency logo" src="assets/ency-logo-no-bg.webp" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link active f-18 fw-500" aria-current="page" href="#">About us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Resourcing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Products</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Domain</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link f-18 fw-500" href="#">Engagement model</a>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <button class="btn btn-success contact_us_button" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </nav>
        </section> -->
        <app-navbar [isNavImageBlack]="true" class="desktop-nav"></app-navbar>
        <div class="hero-content-wrapper">
            <p class="heading">S<span id="animation-o">
                </span>
                <span id="animation-o-inner-circle">
                </span>oftware
            </p>
            <p class="heading heading2">Services</p>
        </div>
    </div>

    <div class="digital-journey-wrapper">
        <div class="heading-wrapper">
            <img src="assets/software-services/bouncing-box-anim.gif" id="bouncing-box-anim" />
            <div class="para-wrappers">
                <p class="heading">Digital journey awaits</p>
                <p class="heading">Ready when you are!</p>
            </div>
            <img src="assets/software-services/bouncing-box-anim.gif" id="bouncing-box-anim" />
            <img src="assets/software-services/heading-circle-anim.gif" id="heading-circle-anim" />
        </div>
        <br />
        <p class="sub-heading-content">We have rich global experience in formulating, executing, and delivering
            multi-disciplinary global heterogeneous technology Programs / Projects across North America, Europe &
            Asia-Pacific. With seasoned engineers and the dedicated team approach, ency can deliver technology solutions
            with quickly assembled teams ready to work on your assignment.
            We offer full-cycle software development services and can build custom solutions from scratch or work on
            existing applications or products to make them effective for you. We bring a wide range of technology
            services spread across Languages, Frameworks, Backends, Frontends, Mobile along with BI, Data Science, AI/ML
            spreading to Cloud and Devops services
        </p>
        <ul class="sub-heading-list-items">
            <li>Leverage our technology expertise and extensive knowledge to implement and align to your business strategy.</li>
            <li>Our experts will help you build custom software/applications, products, leverage legacy systems, and optimize
                your portfolio while introducing you to latest technologies.</li>
            <li>Designers, data visualizers and technical experts meet to give you appealing, business driven, scalable
                applications for the future.</li>
            <li>If you have a business need / pain point / technology silo / technology challenge, we have a solution
                for
                you !</li>
        </ul>
    </div>


    <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
                role="tab" aria-controls="home" aria-selected="true">Home</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                role="tab" aria-controls="profile" aria-selected="false">Profile</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button"
                role="tab" aria-controls="contact" aria-selected="false">Contact</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">...</div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
    </div> -->


    <div class="main-content-outer-wrapper">
        <div class="application_dev_heading" id="application_development">Application Development</div>
        <div class="main-content-wrapper">

            <div class="left-wrapper" role="tablist">
                <!-- first tab  -->
                <div class="tab active" id="software-s-home-tab" data-bs-toggle="tab" data-bs-target="#software-s-home"
                    type="button" role="tab" aria-controls="software-s-home" aria-selected="true">
                    <p class="label">Custom Software Development</p>
                    <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                </div>
                <!-- second tab  -->
                <div class="tab" id="software-s-home2-tab" data-bs-toggle="tab" data-bs-target="#software-s-home2"
                    type="button" role="tab" aria-controls="software-s-home2" aria-selected="true">
                    <p class="label">Web App Development</p>
                    <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                </div>
                <!-- third tab  -->
                <div class="tab" id="software-s-home3-tab" data-bs-toggle="tab" data-bs-target="#software-s-home3"
                    type="button" role="tab" aria-controls="software-s-home3" aria-selected="true">
                    <p class="label">Mobile App Development</p>
                    <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                </div>
                <!-- fourth tab  -->
                <div class="tab" id="software-s-home4-tab" data-bs-toggle="tab" data-bs-target="#software-s-home4"
                    type="button" role="tab" aria-controls="software-s-home4" aria-selected="true">
                    <p class="label">Technology Upgrade</p>
                    <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                </div>

            </div>
            <!-- first content  -->
            <div class="right-wrapper fade active show" id="software-s-home" role="tabpanel"
                aria-labelledby="software-s-home-tab">
                <div class="pointer-wrapper">
                    <!-- <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" /> -->
                    <p class="pointer">We pride ourselves in solving business problems and fulfilling business needs using
                        technology. If you have a business need / pain point / technology silo, our experts will help
                        you build custom products/solutions while introducing you to latest technologies. We design and
                        develop applications for enterprises and SMBs by using handpicked technologies that best fit the
                        need.
                        <br />
                        <br />
                        If you want to redesign legacy systems or get custom software solution across web, mobile and
                        cloud apps. You will get custom services, including tailored consultations with requirements
                        gathering, custom design and development, client-specific deployment, integration,
                        configurations, updates, and support. If you need, we can customize ready apps, integrate to
                        other apps or develop one from scratch.

                    </p>
                </div>
                <img class="content-img" src="assets/software-services/enterprise-img.jpeg" />
            </div>
            <!-- second content  -->
            <div class="right-wrapper fade" id="software-s-home2" role="tabpanel"
                aria-labelledby="software-s-home2-tab">
                <div class="pointer-wrapper">
                    <!-- <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" /> -->
                    <p class="pointer">ency offers a full spectrum of web application development services, from
                        ideation to delivery to performance-tuning, launching, and maintaining premium digital assets.
                        We create innovative human-centric solutions that enhance your competitive edge by tapping into
                        the full potential of cutting-edge technologies turning them into applied solutions.
                        <br />
                        <br />
                        We tailor the development process to your unique needs and requirements. We personalize your
                        website or web application specific to your business need. Our full-stack engineers have the
                        expertise required to develop both the front and back end using the most effective programming
                        languages and technologies for each case.
                    </p>
                </div>
                <img class="content-img" src="assets/software-services/full_stack_development.jpg" />
            </div>
            <!-- third content  -->
            <div class="right-wrapper fade" id="software-s-home3" role="tabpanel"
                aria-labelledby="software-s-home3-tab">
                <div class="pointer-wrapper">
                    <!-- <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" /> -->
                    <p class="pointer">Get outstanding custom mobile applications build for your enterprise or consumer
                        solution from the ground up, starting with the concept outline and prototyping, through design
                        and development, to the end-product release and beyond.
                        <br><br>
                        We bring multi-disciplinary expertise to build Android and iOS applications that leverage the
                        mobile platforms’ capabilities, support exhaustive feature sets, and boast original look and
                        feel.To cater to diverse user bases, we create platform-agnostic solutions working across
                        devices without loss in neither usability nor functionality.
                    </p>
                </div>
                <img class="content-img" src="assets/software-services/mobile_app_development.jpeg" />
            </div>
            <!-- fourth content  -->
            <div class="right-wrapper fade" id="software-s-home4" role="tabpanel"
                aria-labelledby="software-s-home4-tab">
                <div class="pointer-wrapper">
                    <!-- <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" /> -->
                    <p class="pointer">With our applications and architecture upgrade and maintenance services, we
                        provide a comprehensive service, identifying your challenges, creating a modernization strategy,
                        and developing a technology solution.
                        <br><br>Technology upgrade strategy includes maintaining business continuity and
                        reverse-engineering for absence of source code or documentation. Deep analysis help build the
                        upgraded application aligned to your current needs. We assist migration by providing process
                        re-engineering, data migration, user training and change management followed by support.
                    </p>
                </div>
                <img class="content-img" src="assets/software-services/web_app_development.png" />
            </div>


        </div>
    </div>

    <!-- third content wrapper  -->
    <div class="main-content-wrapper-2">
        <div class="solutions-services-heading" id="solutions_services">Solutions & Services</div>
        <div class="filter-outer-wrapper" role="tablist">
            <div class="filter-inner-wrapper active" id="home-2-tab" data-bs-toggle="tab" data-bs-target="#home-2"
                type="button" role="tab" aria-controls="home-2" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Cloud Solutions</p>
            </div>
            <div class="filter-inner-wrapper" id="home2-2-tab" data-bs-toggle="tab" data-bs-target="#home2-2"
                type="button" role="tab" aria-controls="home2-2" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">DevOps Consulting  </p>
            </div>
            <div class="filter-inner-wrapper" id="home3-2-tab" data-bs-toggle="tab" data-bs-target="#home3-2"
                type="button" role="tab" aria-controls="home3-2" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Integration Solutions</p>
            </div>
            <div class="filter-inner-wrapper" id="home3-2-tab" data-bs-toggle="tab" data-bs-target="#home4-2"
                type="button" role="tab" aria-controls="home3-2" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Legacy Software Services</p>
            </div>
            <div class="filter-inner-wrapper" id="home4-2-tab" data-bs-toggle="tab" data-bs-target="#home5-2"
                type="button" role="tab" aria-controls="home4-2" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Software Support Services</p>
            </div>
            <div id="filter-line"></div>
        </div>

        <div class="content-wrapper fade show active" id="home-2" role="tabpanel" aria-labelledby="home-2-tab">
            <!-- <img id="left-img" src="assets/software-services/integration-sol.jpeg" /> -->
            <img id="left-img" src="assets/software-services/cloud_based_solution.jpg" />
            <div class="inner-text-wrapper">
                <p id="text1">Cloud Solutions</p>
                <p id="text2">Our team of experienced IT, cyber security, risk compliance and emerging technology
                    professionals can help you in your cloud transformation journey. We provide end-to-end cloud
                    services from strategy and planning to architecture and build, through to cloud-enabled
                    applications. We help you implement cloud technologies with an efficient operating model maximizing
                    ROI and supporting continued innovation. </p>
            </div>
            <!-- <img id="right-img" src="assets/software-services/product_development.jpeg" /> -->
            <img id="right-img" src="assets/software-services/devops_one.jpeg" />
        </div>
        <div class="content-wrapper fade" id="home2-2" role="tabpanel" aria-labelledby="home2-2-tab">
            <!-- <img id="left-img" src="assets/software-services/product_development.jpeg" /> -->
            <img id="left-img" src="assets/software-services/devops_one.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">DevOps Consulting</p>
                <p id="text2">With our DevOps service we ensure rapid on-boarding of applications by automating
                    end-to-end delivery pipeline and facilitate continuous integration and development across leading
                    cloud platforms. We help you in producing high-quality software-based products and services to
                    quickly go to market, increased efficiency, and reduced cost</p>
            </div>
            <img id="right-img" src="assets/software-services/integration-sol.jpeg" />
        </div>
        <div class="content-wrapper fade" id="home3-2" role="tabpanel" aria-labelledby="home3-2-tab">
            <img id="left-img" src="assets/software-services/integration-sol.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">Integration Solutions</p>
                <p id="text2">As you continue to adopt to newer technology or function specific products you face
                    challenges that they may not talk to each other or do not talk with older systems creating a
                    non-effective technology eco-system a data flow nightmare. We integrate your web, mobile or cloud
                    applications with other applications or products across technologies from your ecosystem or
                    third-party vendors improving information flow, data flow and productivity. </p>
            </div>
            <img id="right-img" src="assets/software-services/legacy_software_services.jpeg" />
        </div>
        <div class="content-wrapper fade" id="home4-2" role="tabpanel" aria-labelledby="home4-2-tab">
            <img id="left-img" src="assets/software-services/legacy_software_services.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">Legacy Software Services</p>
                <p id="text2">We help you leverage your IT investment on outdated or ineffective legacy solutions.
                    Upkeeping of outdated systems may be vital for business continuity or replacing them may be painful.
                    We offer full-cycle legacy services re-architecture, refactor their code, customize, enhance, fix
                    bugs, or maintain them for continued business value. </p>
            </div>
            <img id="right-img" src="assets/software-services/software_support_services.jpeg" />
        </div>
        <div class="content-wrapper fade" id="home5-2" role="tabpanel" aria-labelledby="home4-2-tab">
            <img id="left-img" src="assets/software-services/software_support_services.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">Software Support Services</p>
                <p id="text2">We provide software support services depending on your needs. Our engineers have the right
                    expertise to maintaining the software after project completion, supporting legacy systems, or
                    software products developed by other vendors. The scope of software support services can be
                    ticket-based support or a 24/7 multi level support.</p>
            </div>
            <img id="right-img" src="assets/software-services/cloud_based_solution.jpg" />
        </div>
    </div>


    <!-- third content wrapper  -->
    <!-- <div class="main-content-wrapper-3">
        first content 
        <div class="right-wrapper tab-pane fade show active" id="home-3" role="tabpanel" aria-labelledby="home-3-tab">
            <div class="pointer-wrapper">
                <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" />
                <p class="pointer">We create custom software to fit your specific requirements</p>
            </div>
            <img class="content-img" src="assets/software-services/tech-upgrades-img.png" />
        </div>
        second content 
        <div class="right-wrapper tab-pane fade" id="home2-3" role="tabpanel" aria-labelledby="home2-3-tab">
            <div class="pointer-wrapper">
                <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" />
                <p class="pointer">We create custom software to fit your specific requirements</p>
            </div>
            <img class="content-img" src="assets/software-services/software_support_services.jpeg" />
        </div>
        third content 
        <div class="right-wrapper tab-pane fade" id="home3-3" role="tabpanel" aria-labelledby="home3-3-tab">
            <div class="pointer-wrapper">
                <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" />
                <p class="pointer">We create custom software to fit your specific requirements</p>
            </div>
            <img class="content-img" src="assets/software-services/legacy_software_services.jpeg" />
        </div>
        fourth content 
        <div class="right-wrapper tab-pane fade" id="home4-3" role="tabpanel" aria-labelledby="home4-3-tab">
            <div class="pointer-wrapper">
                <img id="pointer-icon-img" src="assets/software-services/pointer-icon.png" />
                <p class="pointer">We create custom software to fit your specific requirements</p>
            </div>
            <img class="content-img" src="assets/software-services/managed_services.jpg" />
        </div>

        <div class="left-wrapper" role="tablist">
            first tab 
            <div class="tab active" id="home-3-tab" data-bs-toggle="tab" data-bs-target="#home-3" type="button"
                role="tab" aria-controls="home-3" aria-selected="true">
                <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                <p class="label">Technology Upgrades</p>

            </div>
            second tab 
            <div class="tab" id="home2-3-tab" data-bs-toggle="tab" data-bs-target="#home2-3" type="button" role="tab"
                aria-controls="home2-3" aria-selected="true">
                <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                <p class="label">Software Support Services</p>

            </div>
            third tab 
            <div class="tab" id="home3-3-tab" data-bs-toggle="tab" data-bs-target="#home3-3" type="button" role="tab"
                aria-controls="home3-3" aria-selected="true">
                <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                <p class="label">Legacy Software Services</p>

            </div>
            fourth tab 
            <div class="tab" id="home4-3-tab" data-bs-toggle="tab" data-bs-target="#home4-3" type="button" role="tab"
                aria-controls="home4-3" aria-selected="true">
                <img id="label-icon" src="assets/software-services/active-label-icon.png" />
                <p class="label">Managed Services</p>

            </div>
        </div>
    </div> -->

    <!-- fourth content wrapper  -->
    <div class="main-content-wrapper-4" id="data_science">
        <div class="data-science-AI-heading">Data Science & AI/ML</div>
        <div class="content-wrapper fade show active" id="home-4" role="tabpanel" aria-labelledby="home-4-tab">
            <img id="left-img" src="assets/software-services/data_science.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">Data Science</p>
                <p id="text2">Our data science consultants will help you leverage your data's full potential to power
                    high-tech solutions, access valuable insights, and successfully address key business challenges.

                    We guide you through the data science lifecycle transforming raw data into business value. Starting
                    from the quality assessment of the data source to the data model deployment, our data scientist will
                    provide their expertise every step of the way. </p>
            </div>
            <img id="right-img" src="assets/software-services/data_analytics.jpeg" />
        </div>
        <div class="content-wrapper fade" id="home2-4" role="tabpanel" aria-labelledby="home2-4-tab">
            <img id="left-img" src="assets/software-services/data_analytics.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">BI & Data Analytics</p>
                <p id="text2">We integrate BI technology or enhancing the existing solution to enable quick access to
                    managed business data to gain value and accelerate decision-making. We can launch full-scale BI
                    solutions or tune the existing ones into scalable storage solutions and present business insights to
                    end-users via interactive reports and dashboards.

                    We help you access and analyze structured and unstructured data from wide range of data source. Our
                    experts can configure data analytics platforms ranging in scope and complexity aligned to your
                    business requirements. From entry-level solutions relying on descriptive analytics to the most
                    advanced data investigation systems powered by prescriptive analytics capabilities. </p>
            </div>
            <img id="right-img" src="assets/software-services/artificial_intelligence.jpeg" />
        </div>
        <div class="content-wrapper fade" id="home3-4" role="tabpanel" aria-labelledby="home3-4-tab">
            <img id="left-img" src="assets/software-services/artificial_intelligence.jpeg" />
            <div class="inner-text-wrapper">
                <p id="text1">Artificial Intelligence</p>
                <p id="text2">Combining our expertise in ML, data science and innovative AI technologies we provide
                    end-to-end AI consulting or development solutions. We help you create solutions at all stages from
                    pilot to production and help you integrate AI into your processes and workflows and scale it across
                    the organization to maximize its value.
                    <br>
                    AI has the power to transform business outcomes and enhance customer experiences. Harness this power
                    of AI with custom & tailored AI and Analytics solutions expertly crafted for your unique needs and
                    applications. Our technology capabilities and expertise go beyond specific functions or industries,
                    putting people at the heart of everything we do. 
                </p>
            </div>
            <img id="right-img" src="assets/software-services/Machine learning.webp" />
        </div>
        <div class="content-wrapper fade" id="home4-4" role="tabpanel" aria-labelledby="home4-4-tab">
            <img id="left-img" src="assets/software-services/Machine learning.webp" />
            <div class="inner-text-wrapper">
                <p id="text1">Machine Learning</p>
                <p id="text2">Machine learning is a branch of artificial intelligence that operates through data
                    analysis and automates analytical model building. It is based on the self-learning machines and
                    implements the systems that can learn from data, identify patterns, and make decisions with minimal
                    human intervention.
                    <br>
                    Our machine learning experts are skilled at all 3 levels, ML model development, training the
                    developed model and fine tuning it to obtain optimum accuracy and meet your business objectives. We
                    offer a full range of ML services in Deep Learning, Predictive Analytics, Statistical Modeling and
                    Data Mining. </p>
            </div>
            <img id="right-img" src="assets/software-services/data_science.jpeg" />
        </div>

        <div class="filter-outer-wrapper" role="tablist">
            <div class="filter-inner-wrapper active" id="home-4-tab" data-bs-toggle="tab" data-bs-target="#home-4"
                type="button" role="tab" aria-controls="home-4" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Data Science</p>
            </div>
            <div class="filter-inner-wrapper" id="home2-4-tab" data-bs-toggle="tab" data-bs-target="#home2-4"
                type="button" role="tab" aria-controls="home2-4" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">BI & Data Analytics </p>
            </div>
            <div class="filter-inner-wrapper" id="home3-4-tab" data-bs-toggle="tab" data-bs-target="#home3-4"
                type="button" role="tab" aria-controls="home3-4" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Artificial Intelligence </p>
            </div>
            <div class="filter-inner-wrapper" id="home4-4-tab" data-bs-toggle="tab" data-bs-target="#home4-4"
                type="button" role="tab" aria-controls="home4-4" aria-selected="true">
                <div class="filter-circle"></div>
                <p class="filter-text">Machine learning </p>
            </div>
            <div id="filter-line"></div>
        </div>


    </div>

   

</div>