<!-- ----------------------------------------------------------------------------------------------->
<!-- ------------------------------------ NAVBAR STARTS HERE ------------------------------------  -->
<!-- ----------------------------------------------------------------------------------------------->
<section class="navbar_wrapper desktop_screen" id="navbar_wrapper_section">
    <nav class="navbar navbar-expand-lg  bg-body-tertiary" (mouseleave)="leavingNavbar()">


        <!-- ABOUT US DROPDOWN STARTS -->


        <div class="tab-pane fade dropdown-wrapper" id="home2" role="tabpanel" aria-labelledby="home2-tab">
        </div>


        <div class="container-fluid">
            <a class="navbar-brand" href="#" style=" padding-bottom: 20px;">
                <img id="ency_logo" alt="ency logo" src="assets/ency_ca_logo.png" />
            </a>
            <img style="cursor:auto;padding-top: 15px; margin-right: 1vw;" id="ency_badge" alt="ency badge" src="assets/badge.png" />
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarSupportedContentdesk" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContentdesk">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0" role="tablist">
                    <li id="mouse-leave-tab-desk" data-bs-toggle="tab" data-bs-target="#mouse-leave" role="tab"
                        aria-controls="mouse-leave" aria-selected="true">
                        <div class="dummy-tab-for-hiding-on-hover-out" id="mouse-leave" role="tabpanel"
                            aria-labelledby="mouse-leave-tab-desk">

                        </div>
                    </li>

                    <!-- <li class="nav-item" id="home-tab" (mouseenter)="navItemDropDownMouseEnter('home-tab')"
                        (mouseleave)="navItemDropDownMouseLeave()" data-bs-toggle="tab" data-bs-target="#home"
                        role="tab" aria-controls="home" aria-selected="true">

                        <a class="nav-link active f-18 fw-700" aria-current="page"
                         [ngClass]="getNavParentClass(['technology-consulting', 'software-services', 'tech-stack'])">
                         Technical Consulting <i class="bi bi-chevron-down"></i></a>
                        <div class="tab-pane fade dropdown-wrapper" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="dropdown-menu-wrapper">
                                <div class="right_wrapper">
                                    <div class="item" id="business-tab" data-bs-toggle="tab" [routerLink]="['/technology-consulting']"
                                        data-bs-target="#business-consul" role="tab" aria-controls="business-consul"
                                        aria-selected="true" [ngClass]="getSelectedRoute('technology-consulting')">
                                        <a class="link-to-page" >
                                            <span class=" text-submenu text-subsubmenu">Technology Consulting</span>
                                        </a>
                                    </div>
                                    <div class="item" id="resourcing-tab" data-bs-toggle="tab" [routerLink]="['/software-services']"
                                        data-bs-target="#resourcing-consul" role="tab" aria-controls="resourcing-consul"
                                        aria-selected="true" [ngClass]="getSelectedRoute('software-services')">
                
                                        <a class="link-to-page">
                                            <span class=" text-submenu text-subsubmenu">Software Services</span>
                                        </a>
                                    </div>
                                    <div class="item" id="solutions-tab" data-bs-toggle="tab" [routerLink]="['/tech-stack']"
                                        data-bs-target="#solutions-consul" role="tab" aria-controls="solutions-consul"
                                        aria-selected="true" [ngClass]="getSelectedRoute('tech-stack')">
                                        <a class="link-to-page">
                                            <span class=" text-submenu text-subsubmenu">Technology Stack</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="technical-consulting-triangle">
                            <img *ngIf="!isNavImageBlack" class="downward-triangle-black" alt="dragable-triangles" src="assets/black_icon.png" />
                            <img *ngIf="isNavImageBlack" style="height: 12px;" class="downward-triangle-black" alt="dragable-triangles" src="assets/white_down_arrow.png" />
                            <img class="downward-triangle-orange" alt="dragable-triangles" src="assets/orange_icon.png" />
                        </div>
                    </li> -->


                    <li (mouseenter)="leavingNavbar()" class="nav-item nav-dropdown" role="tab">
                        <span [ngClass]="getNavParentClass(['about-us', 'meet-our-team', 'ency-advantage', 'career'])"
                            alt="dragable-triangles" class="nav-link">About Us</span>
                        <div class="about-us-triangle">
                            <img *ngIf="!isNavImageBlack" class="dragable-triangles"
                                [ngClass]="getImageClass(['about-us', 'meet-our-team', 'career', 'ency-advantage'])"
                                alt="dragable-triangles" src="assets/black_icon.png" />
                            <img *ngIf="isNavImageBlack" style="height: 12px;" class="dragable-triangles"
                                [ngClass]="getImageClass(['about-us', 'meet-our-team', 'career', 'ency-advantage'])"
                                alt="dragable-triangles" src="assets/white_down_arrow.png" />
                        </div>
                        <div class="nav-dropdown-menu">
                            <div class="nav-dropdown-menu-item" [routerLink]="['/about-us']"
                                [ngClass]="getSelectedRoute('about-us')">
                                About ency
                            </div>
                            <div class="nav-dropdown-menu-item" [routerLink]="['/meet-our-team']"
                                [ngClass]="getSelectedRoute('meet-our-team')">
                                Meet Our Team
                            </div>
                            <div class="nav-dropdown-menu-item" [routerLink]="['/career']"
                                [ngClass]="getSelectedRoute('career')">
                                Careers
                            </div>
                            <div class="nav-dropdown-menu-item" [routerLink]="['/ency-advantage']"
                                [ngClass]="getSelectedRoute('ency-advantage')">
                                ency Advantage
                            </div>

                        </div>
                    </li>

                    <li (mouseenter)="leavingNavbar()" class="nav-item" role="tab">
                        <a [ngClass]="getSelectedRoute('business-consulting')" alt="dragable-triangles"
                            class="nav-link " [routerLink]="['/business-consulting']">Business Consulting</a>
                    </li>

                    <li (mouseenter)="leavingNavbar()" class="nav-item nav-dropdown" role="tab">
                        <span
                            [ngClass]="getNavParentClass(['technology-consulting', 'software-services', 'tech-stack'])"
                            alt="dragable-triangles" class="nav-link ">Technical Consulting</span>
                        <div class="about-us-triangle">
                            <img *ngIf="!isNavImageBlack" class="dragable-triangles"
                                [ngClass]="getImageClass(['technology-consulting', 'software-services', 'tech-stack'])"
                                alt="dragable-triangles" src="assets/black_icon.png" />
                            <img *ngIf="isNavImageBlack" style="height: 12px;" class="dragable-triangles"
                                [ngClass]="getImageClass(['technology-consulting', 'software-services', 'tech-stack'])"
                                alt="dragable-triangles" src="assets/white_down_arrow.png" />
                        </div>
                        <div class="nav-dropdown-menu">
                            <div class="nav-dropdown-menu-item" [routerLink]="['/technology-consulting']"
                                [ngClass]="getSelectedRoute('technology-consulting')">
                                Technology Consulting
                            </div>
                            <div class="nav-dropdown-menu-item" [routerLink]="['/software-services']"
                                [ngClass]="getSelectedRoute('software-services')">
                                Software Services
                            </div>
                            <div class="nav-dropdown-menu-item" [routerLink]="['/tech-stack']"
                                [ngClass]="getSelectedRoute('tech-stack')">
                                Technology Stack
                            </div>
                        </div>
                    </li>

                    <li (mouseenter)="leavingNavbar()" class="nav-item" role="tab">
                        <a [ngClass]="getSelectedRoute('resourcing')" alt="dragable-triangles" class="nav-link "
                            [routerLink]="['/resourcing']">Resourcing</a>
                    </li>
                    <li (mouseenter)="leavingNavbar()" class="nav-item" role="tab">
                        <a [ngClass]="getSelectedRoute('domain-solutions')" [routerLink]="['/domain-solutions']"
                            alt="dragable-triangles" class="nav-link ">Solutions</a>
                    </li>
                    <!-- <li (mouseenter)="leavingNavbar()" class="nav-item" role="tab">
                        <a href="https://www.lucency.ai/" target="_blank" alt="dragable-triangles" class="nav-link f-18 fw-700">Products</a>
                    </li> -->
                    <!-- <li class="nav-item" (mouseenter)="leavingNavbar()" role="tab">
                        <a class="nav-link f-18 fw-700">Products</a>
                        <img class="downward-triangle" alt="dragable-triangles" src="assets/navbar/triangle.webp" />
                    </li> -->
                    <li (mouseenter)="leavingNavbar()" class="nav-item" role="tab">
                        <a [ngClass]="getSelectedRoute('engagement-model')" [routerLink]="['/engagement-model']"
                            alt="dragable-triangles" class="nav-link ">Engagement
                            Model</a>
                    </li>

                    <li (mouseenter)="leavingNavbar()" class="nav-item nav-dropdown" role="tab" [ngClass]="getSelectedRoute('product')" [routerLink]="['/product']" >
                        <span [ngClass]="getNavParentClass([ 'ezdesk', 'la','traveho'])"
                            alt="dragable-triangles" class="nav-link">Products</span>
                        <div class="about-us-triangle">
                            <img *ngIf="!isNavImageBlack" class="dragable-triangles"
                                [ngClass]="getImageClass([ 'ezdesk', 'la','traveho'])"
                                alt="dragable-triangles" src="assets/black_icon.png" />
                            <img *ngIf="isNavImageBlack" style="height: 12px;" class="dragable-triangles"
                                [ngClass]="getImageClass([ 'ezdesk', 'la','traveho'])"
                                alt="dragable-triangles" src="assets/white_down_arrow.png" />
                        </div>
                        <div class="nav-dropdown-menu" style="padding-top:10px">
                            <a style="text-decoration: none;margin: 0 5px;"class="nav-dropdown-menu-item" href="https://driveezpro.ca/" target="_blank" >
                                DriveEZ
                            </a>
                            <div class="nav-dropdown-menu-item"  style="margin-top: 10px;" [ngClass]="getSelectedRoute('ezdesk')"  [routerLink]="['/ezdesk']" >
                                ezDesk 
                            </div>
                            <div class="nav-dropdown-menu-item" [ngClass]="getSelectedRoute('la')"  [routerLink]="['/la']">
                                Learn Anywhere
                            </div>
                            <!-- <div class="nav-dropdown-menu-item" [ngClass]="getSelectedRoute('product')"  [routerLink]="['/product']">
                                Product
                            </div> -->
                            <!-- <div class="nav-dropdown-menu-item" [ngClass]="getSelectedRoute('meet-our-team')" href="http://lucency-site.s3-website.us-east-2.amazonaws.com/">
                                Lucency
                            </div>
                            <div class="nav-dropdown-menu-item" [ngClass]="getSelectedRoute('career')">
                                Monitoring System
                            </div> -->
                          
                        

                            <div class="nav-dropdown-menu-item" [ngClass]="getSelectedRoute('traveho')" [routerLink]="['/traveho']">
                            
                                Traveho
                            </div>
                         
                          
                         



                        </div>
                    </li>

                </ul>
                <form class="d-flex">
                    <!-- <img src="assets/serach_icon.png" class="search" /> -->

                    <button class="btn btn-success contact_us_button" routerLink="/contact-us"><span>
                            Contact
                            Us</span></button>
                </form>
            </div>
        </div>
    </nav>
</section>
<!-- ----------------------------------------------------------------------------------------------->
<!-- ------------------------------------ NAVBAR ENDS HERE ----------------------------------------->
<!-- ----------------------------------------------------------------------------------------------->

<section class="navbar_wrapper mobile_screen mobile-nav" id="navbar_wrapper_section2">
    <nav class="navbar navbar-expand-lg  bg-body-tertiary" (mouseleave)="leavingNavbar()">
        <!-- ABOUT US DROPDOWN STARTS -->
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img style="padding-bottom: 20px " id="ency_logo" alt="ency logo" src="assets/ency_ca_logo.png" />
                <img id="ency_badge" alt="ency logo" src="assets/badge.png" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0" role="tablist">
                    <div id="mouse-leave-tab" data-bs-toggle="tab" data-bs-target="#mouse-leave" role="tab"
                        aria-controls="mouse-leave" aria-selected="true">

                    </div>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-800" aria-current="page" routerLinkActive="active"
                            routerLink="/about-us">About Us</a>
                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/meet-our-team">Meet Our Team</a>
                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/career">Careers</a>
                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/ency-advantage">ency
                            Advantage</a>
                    </li>

                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/business-consulting">Business
                            Consulting</a>
                    </li>

                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/technology-consulting">Technology Consulting</a>

                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/software-services">Software
                            Services</a>
                    </li>

                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/tech-stack">Tech Stack</a>
                    </li>

                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/resourcing">Resourcing</a>
                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/domain-solutions">
                            Solutions</a>
                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                            routerLink="/engagement-model">Engagement
                            Model</a>

                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                        [routerLink]="['/product']">Products</a>

                    </li> 
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" style="text-decoration: none;"  href="https://driveezpro.ca/" routerLinkActive="active" target="_blank"
                            >  DriveEZ</a>
                           
                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                        [routerLink]="['/ezdesk']" >ezDesk</a>

                    </li>
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                        [routerLink]="['/la']">Learn Anywhere</a>

                    </li>
                
                
                    <li class="nav-item" role="tab">
                        <a class="nav-link active f-18 fw-500" aria-current="page" routerLinkActive="active"
                        [routerLink]="['/traveho']">Traveho</a>

                    </li>
                

                </ul>
                <form class="d-flex">
                    <button class="btn btn-success contact_us_button" routerLink="/contact-us"><span
                            routerLinkActive="active">Contact
                            Us</span></button>
                </form>
            </div>
        </div>
    </nav>
</section>