import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engagement-model',
  templateUrl: './engagement-model.component.html',
  styleUrls: ['./engagement-model.component.scss']
})
export class EngagementModelComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor() { }

  ngOnInit(): void {
    this.scrollToTop()
  }
  scrollToTop(){
    const element = document.querySelector('.engagement-model-main-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  onLinkConsulation() {
    const element = document.querySelector('.consulation')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onLinkProject() {
    const element = document.querySelector('.project')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  onsiteProject() {
    const element = document.querySelector('.onsite')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
    // will scroll to 4th h3 element
  }
  offshoreProject(){
    const element = document.querySelector('.offshore')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  Captive(){
    const element = document.querySelector('.captive')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
  Expertise(){
    const element = document.querySelector('.expertise')
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
