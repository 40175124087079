import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ResourcingComponent } from './pages/resourcing/resourcing.component'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomepageComponent } from './pages/homepage/homepage.component'
import { CareerpageComponent } from './pages/careerpage/careerpage.component'
import { AboutUsComponent } from './pages/about-us/about-us.component'
import { EncyAdvantageComponent } from './pages/ency-advantage/ency-advantage.component'
import { BusinessConsultingComponent } from './pages/business-consulting/business-consulting.component'
import { TechnologyConsultingComponent } from './pages/technology-consulting/technology-consulting.component'
import { ContactUsComponent } from './pages/contact-us/contact-us.component'
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { SoftwareServicesComponent } from './pages/software-services/software-services.component'
import { TechStackComponent } from './pages/tech-stack/tech-stack.component'
import { DomainSolutionsComponent } from './pages/domain-solutions/domain-solutions.component'
import { EngagementModelComponent } from './pages/engagement-model/engagement-model.component'
import { MeetOurTeamComponent } from './pages/meet-our-team/meet-our-team.component'
import { HttpClientModule } from '@angular/common/http'

import { NavbarComponent } from './pages/navbar/navbar.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { FooterComponent } from './pages/footer/footer.component';
import { TravehoComponent } from './pages/traveho/traveho.component';
import { LearnanywhereComponent } from './pages/learnanywhere/learnanywhere.component';
import { EzdeskComponent } from './pages/ezdesk/ezdesk.component';
import { MainProductpageComponent } from './pages/main-productpage/main-productpage.component'

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    CareerpageComponent,
    AboutUsComponent,
    EncyAdvantageComponent,
    BusinessConsultingComponent,
    TechnologyConsultingComponent,
    ContactUsComponent,
    SoftwareServicesComponent,
    TechStackComponent,
    DomainSolutionsComponent,
    EngagementModelComponent,
    MeetOurTeamComponent,
    NavbarComponent,
    ResourcingComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    CookiesComponent,
    DisclaimerComponent,
    FooterComponent,
    TravehoComponent,
    LearnanywhereComponent,
    EzdeskComponent,
    MainProductpageComponent
    // MeetOurTeamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
