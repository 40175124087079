import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technology-consulting',
  templateUrl: './technology-consulting.component.html',
  styleUrls: ['./technology-consulting.component.scss']
})
export class TechnologyConsultingComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor() { }

  ngOnInit(): void {
    this.scrollToTop()
  }

  scrollToTop(){
    const element = document.querySelector('.technology-consulting-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
