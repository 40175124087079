import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ezdesk',
  templateUrl: './ezdesk.component.html',
  styleUrls: ['./ezdesk.component.scss']
})
export class EzdeskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
