import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  current_year = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void { this.scrollToTop()
  }
  scrollToTop(){
    const element = document.querySelector('.cookies-main');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}
