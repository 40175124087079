import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meet-our-team',
  templateUrl: './meet-our-team.component.html',
  styleUrls: ['./meet-our-team.component.scss']
})
export class MeetOurTeamComponent implements OnInit {

  current_year = new Date().getFullYear()
  constructor() { }


  ngOnInit (): void {
    this.scrollToTop();
    this.team_mates_list_one = this.team_mates_list_one.sort((a, b) => a.name < b.name ? -1 : 1);
   }
 
   scrollToTop(){
    const element = document.querySelector('.meet-our-team-main-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
   
  team_mates_current: any = {}
  previousItemIndex = 0;
  nextItemIndex = 0;
  prev_member: any = null;
  next_member: any = null;
  team_mate_active = 0;
  team_mates_list_one = [
    {
      sno: 1,
      name: 'Robin F',
      role: 'Software Architect',
      image: 'assets/RobinF.png',
      desc: `Robin is a veteran cloud architect with 20+ years of experience in the sectors of Finance, Banking, Education, Media, Hospitality, Engineering, Logistics & Shipping. He brings vast technical knowledge and real-world experience to ency, along with technically sound vision and direction. He has been foundational in ency's solutions and has been one of the earliest members at ency. His passion is leading the world using innovative and robust technology. He believes in leading by example, and is not one to shy away from challenges, regardless of their complexity or domain. He enjoys breaking new ground and discovering new solutions to the constantly evolving technological landscape. `,
    },
    {
      sno: 2,
      name: 'Theeba KT',
      role: 'Senior Test Engineer',
      image: 'assets/Theeba.png',
    
      desc: `An optimistic person, extremely motivated to constantly develop skills and grow exponentially. Highly skilled and dedicated Quality Test Engineer with a superb work ethic and customer satisfaction record. Adapt at working well independently or as a member of a professional engineering team. Strongly mindful of and knowledgeable about all relevant standards pertaining to software engineering and procedures `,
    },
    {
      sno: 3,
      name: 'Mudita Pokra',
      role: 'HR Recruiter',
      image: 'assets/Mudita.png',
      desc: `Mudita is an enthusiastic person who has completed an MBA from RTU. She is highly skilled in Human resource department form last 3 years, she is well versed in Recruitment and staffing, Employee Benefit, Employee Compensation, Human Resource Compliance, Employee training and Development, Performance Management. Working with ency has helped her polish her capabilities daily and gain insights each day. `
    },
    {
      sno: 4,
      name: 'Rajat Upadhyay',
      role: 'Senior Software Engineer',
      image: 'assets/Rajat.png',
      desc: `Expertise in full stack framework and passion for solutions that emerge out of deep data analysis. Actively involved in day-to-day operations of building smart, scalable, and reliable solutions. Highly empathetic towards team members, believes in moving forward as a team to provide accurate solutions to modern world problems `,
    },
    {
      sno: 5,
      name: 'Sachin Kumar',
      role: 'Senior Software Engineer',
      image: 'assets/Sachin.png',
      desc: `As a software engineer with four years of experience, Sachin is proficient in various programming languages, frameworks, and tools such as Web Development, AWS, Python, and Machine Learning. Sachin is a quick learner and stays up-to-date with the latest technologies and industry trends. He is able to work well under pressure and able to work collaboratively with other members of his team and stakeholders to deliver software projects that meet business requirements and user needs. his dedication, skillset, and experience make him a valuable asset to any software development organization. `,
    },
    {
      sno: 6,
      name: 'Shaily Parikh',
      role: 'Project Manager',
      image: 'assets/Shaily.png',
      desc: `A passionate project manager who strives for project outcomes through strategic planning, data analysis, and effective communication. She excels at identifying and solving complex problems, collaborating with team members and stakeholders, and motivating her team to deliver high-quality work. With a focus on continuous improvement, she optimizes project processes to achieve exceptional results.  

      Working with ency has helped me grow out of my comfort zone. The company culture fosters collaboration, creativity, and growth, making it a great place to work and thrive. `,
    },
    {
      sno: 7,
      name: 'Robin Richard',
      role: 'Jr. DevOps Engineer',
      image: 'assets/RobinR.png',
      desc: 'As a DevOps engineer and as an individual, Robin takes amuse in creating something from scratch and remaining abreast with the latest technologies and security practices. His unquenchable curiosity motivates him to acquire knowledge on various topics, not just within his scope, but beyond. Exploring new horizons and expanding his skill set continuously. His insatiable drive for knowledge enables him to adapt to new challenges and approach problem-solving with innovation in both his personal and professional life. He is Serendipitous to work in ency with a supportive and encouraging work culture.   ',
    },
    {
      sno: 8,
      name: 'Piyush Kuhikar',
      role: 'Software Engineer',
      image: 'assets/Piyush.png',
      desc: `Piyush Kuhikar is a highly skilled Software Developer with 3.5 years of experience in writing top-notch code in Python and C#. With his knowledge ranging from creating web applications and console applications using variety of frameworks in frontend, backend and cloud technologies he is an expert in developing software applications from scratch, bringing innovative and efficient solutions to complex problems. 
       He is proficient in designing, coding, testing, and debugging software applications using modern tools and technologies. Piyush is always looking for ways to improve processes, optimize workflows, and enhance the overall quality of software development. `,
    },
    {
      sno: 9,
      name: 'Joel Andrew',
      role: 'Account and Admin Intern',
      image: 'assets/Joel.png',
      desc: `As an intern accountant and administrator, I have appreciated Ency's focus on professional development and mentorship. Despite remote work, I have been able to connect with my colleagues and receive guidance and support when needed. This has allowed me to continue to develop my skills as an accountant and feel like a valued member of the team. Overall, Ency's leadership and commitment to communication, collaboration, technology, and professional development have allowed the company to maintain a high level of productivity and customer service while also ensuring that employees feel supported and valued. `,
    },
    {
      sno: 10,
      name: 'Harshita Rai',
      role: 'UI/UX Designer',
      image: 'assets/Harshita.png',
      desc: 'Harshita is an empathetic and skilled UX/UI designer with a positive and optimistic mindset which allows her to design visually appealing and user-friendly interfaces. She is also highly technology-driven and stays up to date with the latest UX trends and tools. With her skills and passion for design, Harshita is committed to delivering exceptional user experiences that enhance user satisfaction and drive business success. ',
    },
    // {
    //   sno: 11,
    //   name: 'Darshan Bhosale',
    //   role: 'Test Engineer',
    //   image: 'assets/Darshan.png',
    //   desc: `I have worked as a software quality engineer for 1.5 years, I can say that attention to detail, spotting inconsistencies and defects and analytical ability are crucial qualities for success. 

    //   I have been fortunate enough to work in 'Ency' as a software Quality Analyst, and it is an incredible experience. The company's work culture is top-notch, and I always felt supported and valued by my colleagues and superiors. But what I value most about 'Ency' Company is its commitment to its employees' professional development. The company invested in my growth and provided me with ample opportunities for training and learning, which allowed me to build my skills and progress in my career. `,
    // },
    {
      sno: 11,
      name: 'Shashank Tavarkhed',
      role: 'Software Engineer',
      image: 'assets/Shashank.png',
      desc: `Shashank is a passionate software developer with Strong intuitive problem-solving skills and passionate for interactive technologies. He is proficient in popular technologies like JavaScript, NodeJs, MongoDB, Expressjs, SQL and GIT. 
      At ency, he has gained immense technical knowledge and elite interpersonal intelligence with freedom to own a problem and brainstorm its root cause while translating the business requirements into scalable technical solutions. `,
    },
    {
      sno: 12,
      name: 'Swati Tushar Zol',
      role: 'Frontend Developer',
      image: 'assets/Swati.png',
      desc: `An experienced Front-End Developer, Swati has been working with different web Technologies like Angular, React, Html, CSS , She is passionate about developing responsive web-apps using trending web technologies. 
      she feels ency is a best place to work. It helps their employees to maintain the work life balance in a much better way .People here are helpful, technical and always ready to try out new approaches which is a good thing for employees. It's good company and Good motivation for new native technologies. Company has employee-friendly policies and challenging work in new technology. `,
    },
    {
      sno: 13,
      name: 'Sajid Sajad Khan',
      role: 'Software Engineer',
      image: 'assets/Sajid.png',
      desc: `I'm a software engineer who constantly seeks out innovative solutions to everyday problems. In my two years in this field, I've honed my analytical thinking and collaboration skills, and I love working with a team. And with ency since last year, I get a lot of opportunities to learn new things that I was not aware of before joining them. 

      ency is a company that inspires me in which I get an opportunity to work on things that I feel passionate about, and I get a position that challenges me and stretches me into different and new areas and it’s management encourages and empowers me to do my best and great work environment and team spirit. `,
    }

  ]
  team_mate_set(index: number) {
  
    this.team_mate_active = index;
    //......find out previous index.........
    this.previousItemIndex = this.team_mate_active - 1
    this.prev_member = this.previousItemIndex < 0 ? null : this.team_mates_list_one[this.previousItemIndex];
    // this.prev_member =  this.software_services_active>= 0 ? this.team_mates_list_one[ this.software_services_active - 1] : null;
    //......find out next index..........
    this.nextItemIndex = this.team_mate_active + 1
    this.next_member = this.nextItemIndex < 0 ? null : this.team_mates_list_one[this.nextItemIndex];
    // this.next_member = this.software_services_active >= 0 ? this.team_mates_list_one[ this.software_services_active + 1] : null
  }
}
