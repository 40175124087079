import { Component, OnInit, AfterViewInit, } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-software-services',
  templateUrl: './software-services.component.html',
  styleUrls: ['./software-services.component.scss']
})
export class SoftwareServicesComponent implements OnInit {
  current_year = new Date().getFullYear()
  constructor (private route: ActivatedRoute) {}

  ngOnInit (): void {
    if(!this.route.snapshot.fragment){
      this.scrollToTop()
    }else {
      this.scrollToElement()
    }
  }
  // ngAfterViewInit() {
  //   this.scrollToTop();
  // }

  scrollToElement(){
    const element = document.querySelector(`#${this.route.snapshot.fragment}`)
      if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      }
  }

  scrollToTop(){
    const element = document.querySelector('.software-services-wrapper');
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }
}